import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styles/theme'
import { ApiService } from 'Services'
import { useDispatch } from 'store'
import { ActionType, Selectors } from 'Reducers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import { ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'
import invariant from 'invariant'
import { DateTime } from 'luxon'
import Loading from 'Components/Loading.component'
import { SubscriptionStatusOption } from 'Containers/manageSubscriptionSteps/SubscriptionStatus'
import { getSubscriptionStatus } from 'Lib/StripeUtils'
import { Spacer } from 'Components/Spacer'
import { shouldAttemptToCloseInAppBrowser } from 'Lib/AppUtils'

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xLarge};
  align-items: center;
  text-align: center;
  margin: auto;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
`

const Title1 = styled.h1<{ color?: string }>`
  font-size: ${props => props.theme.fontSizes.large};
  font-weight: 700;
  width: 100%;
  text-align: center;
  color: ${(props) => props.color ?? theme.colors.text};
`

const Title2 = styled.h1<{ color?: string }>`
  font-size: ${props => props.theme.fontSizes.medium};
  line-height: 32px;
  font-weight: 700;
  width: 100%;
  text-align: start;
  color: ${(props) => props.color ?? theme.colors.text};
`

const BorderContainer = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.large};
`

const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  margin: ${({ theme }) => theme.spacing.medium} 0;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.small} 0;
`

const Label = styled.text`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.primary};
`

const StatusText = styled.text`
  font-size: ${({ theme }) => theme.fontSizes.medium};
`

const Paragraph = styled.p`
  text-align: left;
`

const mapSubscriptionStatusToStatusText = (subscriptionStatus: ISubscriptionStatus, t: Function) => {
  switch (subscriptionStatus) {
    case 'active':
      return t('active')
    case 'expired':
      return t('expired')
    case 'canceled':
      return t('cancelled')
    case 'onHold':
      return t('paused')
    default:
      throw Error('Unknown subscription status')
  }
}

const mapSubscriptionStatusToDateText = (subscriptionStatus: ISubscriptionStatus, t: Function) => {
  switch (subscriptionStatus) {
    case 'active':
      return t('renewalDate')
    case 'expired':
      return t('expiredOn')
    case 'canceled':
      return t('endDate')
    case 'onHold':
      return t('pausedUntil')
    default:
      throw Error('Unknown subscription status')
  }
}

const mapSubscriptionStatusToDateMillis = (subscriptionStatus: ISubscriptionStatus, stripeSubscription: IStripeSubscription): number | null => {
  switch (subscriptionStatus) {
    case 'active':
      return stripeSubscription.current_period_end ? stripeSubscription.current_period_end * 1000 : null
    case 'expired':
      return stripeSubscription.current_period_end ? stripeSubscription.current_period_end * 1000 : null
    case 'canceled':
      return stripeSubscription.current_period_end ? stripeSubscription.current_period_end * 1000 : null
    case 'onHold':
      return stripeSubscription?.pause_collection?.resumes_at ? stripeSubscription.pause_collection.resumes_at * 1000 : null
    default:
      throw Error('Unknown subscription status')
  }
}

const mapSubscriptionStatusToButtonText = (subscriptionStatus: ISubscriptionStatus, t: Function) => {
  switch (subscriptionStatus) {
    case 'active':
      return t('manageSubscription')
    case 'expired':
      return t('activateSubscription')
    case 'canceled':
      return t('restoreSubscription')
    case 'onHold':
      return t('unpauseSubscription')
    default:
      throw Error('Unknown subscription status')
  }
}

const mapSubscriptionStatusToDescriptionText = (subscriptionStatus: ISubscriptionStatus, t: Function) => {
  switch (subscriptionStatus) {
    case 'active':
      return t(`${ManageSubscriptionStepId.SubscriptionStatus}.descriptionActive`)
    case 'expired':
      return t(`${ManageSubscriptionStepId.SubscriptionStatus}.descriptionExpired`)
    case 'canceled':
      return t(`${ManageSubscriptionStepId.SubscriptionStatus}.descriptionCancelled`)
    case 'onHold':
      return t(`${ManageSubscriptionStepId.SubscriptionStatus}.descriptionOnHold`)
    default:
      throw Error('Unknown subscription status')
  }
}

const SubscriptionStatus: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useSelector(Selectors.getUser)
  const stripeSubscription = useSelector(Selectors.getSubscriptionDetails)
  const isSpecialOfferActive = !!stripeSubscription?.metadata?.specialOfferStartDate

  useEffect(() => {
    dispatch({ type: ActionType.FETCH_SUBSCRIPTION_DETAILS })
  }, [])

  if (!stripeSubscription) {
    return <Loading />
  }

  const subscriptionStatus = getSubscriptionStatus(stripeSubscription)

  const dateMillis = mapSubscriptionStatusToDateMillis(subscriptionStatus, stripeSubscription)
  const formattedDAte = dateMillis ? DateTime.fromMillis(dateMillis, { zone: 'utc' }).toLocaleString() : '-'

  const subscriptionStatusText = mapSubscriptionStatusToStatusText(subscriptionStatus, t)
  const subscriptionDateText = mapSubscriptionStatusToDateText(subscriptionStatus, t)
  const subscriptionDescriptionText = mapSubscriptionStatusToDescriptionText(subscriptionStatus, t)

  const handlePrimaryButtonAction = async () => {
    switch (subscriptionStatus) {
      case 'active':
        dispatch({ type: ActionType.COMMIT_MANAGE_SUBSCRIPTION_STEP, stepId: ManageSubscriptionStepId.SubscriptionStatus, optionId: SubscriptionStatusOption.CancelSubscription })
        break
      case 'canceled':
        dispatch({ type: ActionType.COMMIT_MANAGE_SUBSCRIPTION_STEP, stepId: ManageSubscriptionStepId.SubscriptionStatus, optionId: SubscriptionStatusOption.RestoreSubscription })
        break
      case 'expired':
        dispatch({ type: ActionType.COMMIT_MANAGE_SUBSCRIPTION_STEP, stepId: ManageSubscriptionStepId.SubscriptionStatus, optionId: SubscriptionStatusOption.ReactivateSubscription })
        break
      case 'onHold':
        dispatch({ type: ActionType.COMMIT_MANAGE_SUBSCRIPTION_STEP, stepId: ManageSubscriptionStepId.SubscriptionStatus, optionId: SubscriptionStatusOption.UnpauseSubscription })
        break
      default:
        invariant(false, 'handlePrimaryButtonAction - Unknown subscription status')
    }
  }

  const handlePauseButtonAction = async () => {
    dispatch({ type: ActionType.COMMIT_MANAGE_SUBSCRIPTION_STEP, stepId: ManageSubscriptionStepId.SubscriptionStatus, optionId: SubscriptionStatusOption.PauseSubscription })
  }

  const handleHaveQuestionButtonAction = async () => {
    dispatch({ type: ActionType.COMMIT_MANAGE_SUBSCRIPTION_STEP, stepId: ManageSubscriptionStepId.SubscriptionStatus, optionId: SubscriptionStatusOption.HaveQuestion })
  }

  const closePage = () => {
    dispatch({ type: ActionType.CLOSE_IN_APP_BROWSER })
  }

  return (
    <Container>
      <Title1>
        {t(`${ManageSubscriptionStepId.SubscriptionStatus}.title`)}
      </Title1>

      <BorderContainer>
        <Title2>
          {t(`${ManageSubscriptionStepId.SubscriptionStatus}.title2`)}
        </Title2>

        <Line />

        <Row>
          <Label>{t('forms.email')}</Label>
          {user!.providerData.email}
        </Row>

        <Row>
          <Label>{t('status')}</Label>
          <StatusText>{subscriptionStatusText}</StatusText>
        </Row>

        <Row>
          <Label>{subscriptionDateText}</Label>
          <StatusText>{formattedDAte}</StatusText>
        </Row>

        {subscriptionStatus === 'active' && (
          <>
            <Button margin='20px 0 20px auto' primaryColor={theme.colors.primary} onClick={handleHaveQuestionButtonAction}>
              {t(`${ManageSubscriptionStepId.SubscriptionStatus}.haveQuestion`)}
            </Button>
          </>
        )}

      </BorderContainer>

      <BorderContainer>
        <Paragraph>{subscriptionDescriptionText}</Paragraph>

        <Button margin='20px 0 20px auto' primaryColor={theme.colors.primary} theme='outline' onClick={handlePrimaryButtonAction}>
          {mapSubscriptionStatusToButtonText(subscriptionStatus, t)}
        </Button>
      </BorderContainer>

      {shouldAttemptToCloseInAppBrowser() && <Button margin='20px 0 20px auto' onClick={closePage}>
        {t('goBack')}
      </Button>}
    </Container>
  )
}

export default SubscriptionStatus
