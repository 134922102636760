import { options, title } from 'Containers/steps/Consts'
import { t } from 'i18next'
import { ManageSubscriptionStep, ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'

export enum CancellationReasonAppNotHelpingOption {
  LearnMore = 'learnMore',
  CancelAnyway = 'cancelAnyway'
}

export function getCancellationReasonAppNotHelpingStep(): ManageSubscriptionStep {
  return {
    id: ManageSubscriptionStepId.CancellationReasonAppNotHelping,
    type: 'single-select',
    wordings: {
      title: t(`${ManageSubscriptionStepId.CancellationReasonAppNotHelping}.${title}`)
    },
    options: [
      {
        id: CancellationReasonAppNotHelpingOption.LearnMore,
        label: t(`${ManageSubscriptionStepId.CancellationReasonAppNotHelping}.${options}.${CancellationReasonAppNotHelpingOption.LearnMore}`)
      },
      {
        id: CancellationReasonAppNotHelpingOption.CancelAnyway,
        label: t(`${ManageSubscriptionStepId.CancellationReasonAppNotHelping}.${options}.${CancellationReasonAppNotHelpingOption.CancelAnyway}`)
      }
    ]
  }
}
