import {
  ActionType,
  Selectors
} from 'Reducers'
import { select, take } from 'typed-redux-saga'

export function * waitForStartup() {
  const appStarted: ReturnType<typeof Selectors.isAppStarted> = yield select(Selectors.isAppStarted)

  if (!appStarted) {
    yield take(ActionType.START_UP_SUCCESS)
  }
}

export function * waitForLogin() {
  const isLoggedIn: ReturnType<typeof Selectors.isLoggedIn> = yield select(Selectors.isLoggedIn)
  if (!isLoggedIn) {
    yield take(ActionType.LOGIN_SUCCESS)
  }
}

export function * waitForFirebaseInitialization() {
  const isFirebaseInitialized: ReturnType<typeof Selectors.isFirebaseInitialized> = yield select(Selectors.isFirebaseInitialized)
  if (!isFirebaseInitialized) {
    yield take(ActionType.FIREBASE_INITIALIZED)
  }
}
