import React from 'react'
import styled from 'styled-components'

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 10;

  p {
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text};
    margin: 0;
    text-align: center;
  }
`

const StepCircle = styled.div<{ completed: boolean, currentStep: boolean }>`
  width: ${({ theme }) => theme.spacing.medium};
  height: ${({ theme }) => theme.spacing.medium};
  border-radius: 50%;
  margin: ${({ theme }) => theme.spacing.small};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ currentStep, completed, theme }) => {
    if (currentStep) {
      return theme.colors.primary
    }
    return (completed ? theme.colors.white : theme.colors.lightText)
  }};
  background-color: ${({ completed, theme }) => (completed ? theme.colors.primary : theme.colors.white)};
  border: 2px solid ${({ currentStep, completed, theme }) => ((currentStep || completed) ? theme.colors.primary : theme.colors.lightText)};
`

const InvisibleTitle = styled.p`
  visibility: hidden;
`

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.text};
`

interface StepProps {
  stepNumber: number
  completed: boolean
  currentStep: number
  title: string
  children: React.ReactNode
}

const Step: React.FC<StepProps> = ({ stepNumber, completed, currentStep, title }) => {
  return (
    <>
      <StepWrapper>
        <InvisibleTitle>{title}</InvisibleTitle>
        <StepCircle completed={completed} currentStep={currentStep === stepNumber}>
          {completed ? '✓' : stepNumber}
        </StepCircle>
        <Title>{title}</Title>
      </StepWrapper>
    </>
  )
}

export default Step
