import { getUserLanguage, openEmailApp, openUrlInNewTab, mapCancellationReasonUndecidedLink, mapCancellationReasonExpensiveLink, mapCancellationReasonAppComplicatedLink, mapCancellationReasonAppNotHelpingLink, mapCancellationReasonGoalAchievedLink, hasInAppBrowserAsUtmSource, mapCancellationReasonFoundBetterAlternativeLink, mapHelpdeskSubscriptionLink } from 'Lib'
import {
  ActionType,
  Selectors
} from 'Reducers'
import { MANAGE_SUBSCRIPTION_STEPS_BY_ID, RouteNames } from 'RouteNames'
import { all, call, put, select, takeLatest } from 'typed-redux-saga'
import { IAcceptSpecialOffer, ICommitManageSubscriptionStep } from 'Reducers/ManageSubscriptionRedux'
import { push } from 'connected-react-router'
import { HaveQuestionsOption } from 'Containers/manageSubscriptionSteps/HaveQuestions'
import { ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'
import { cancelStripeSubscriptionHandler, pauseStripeSubscriptionHandler, restoreStripeSubscriptionHandler, unpauseStripeSubscriptionHandler, updateSubscriptionWithSpecialOfferHandler } from 'Services'
import { CancellationReasonOption } from 'Containers/manageSubscriptionSteps/CancellationReason'
import { CancellationReasonExpensiveOption } from 'Containers/manageSubscriptionSteps/CancellationReasonExpensive'
import { CancellationReasonUndecidedOption } from 'Containers/manageSubscriptionSteps/CancellationReasonUndecided'
import { CancellationReasonAppComplicatedOption } from 'Containers/manageSubscriptionSteps/CancellationReasonAppComplicated'
import { CancellationReasonAppNotHelpingOption } from 'Containers/manageSubscriptionSteps/CancellationReasonAppNotHelping'
import { CancellationReasonGoalAchievedOption } from 'Containers/manageSubscriptionSteps/CancellationReasonGoalAchieved'
import { SubscriptionStatusOption } from 'Containers/manageSubscriptionSteps/SubscriptionStatus'
import { PauseOption } from 'Containers/manageSubscriptionSteps/PauseSubscription'
import { PauseOfferOption } from 'Containers/manageSubscriptionSteps/PauseOffer'
import { t } from 'i18next'
import { showToast } from 'Components/Toast'
import { DateTime } from 'luxon'

function * handleCancelAnywayPressSaga (): Saga {
  const subscriptionDetails: ReturnType<typeof Selectors.getSubscriptionDetails> = yield select(Selectors.getSubscriptionDetails)
  const isSpecialOfferActive = !!subscriptionDetails?.metadata?.specialOfferStartDate
  const inAppBrowser: ReturnType<typeof hasInAppBrowserAsUtmSource> = yield call(hasInAppBrowserAsUtmSource)

  const isTrialing = subscriptionDetails?.trial_end ? DateTime.fromMillis(subscriptionDetails.trial_end * 1000, { zone: 'utc' }).diffNow().as('seconds') > 0 : false

  if (isSpecialOfferActive || inAppBrowser || isTrialing) {
    // Don't show the special offer if the special offer is active or if the user is came via the in-app browser (because of apple and google policies).
    // Also, we can not create "schedule" for the trialing subscriptions because it needs to be a recurring price to create a schedule.
    // Therefore, special offer is skipped for the subscriptions in trialing status.
    // yield call(handlePauseOfferShowAttemptSaga)

    // Pause offer will be added back in the follow up task
    yield put({ type: ActionType.CANCEL_SUBSCRIPTION })
  } else {
    yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.special_offer))
  }
}

function * handlePauseOfferShowAttemptSaga (): Saga {
  const subscriptionDetails: ReturnType<typeof Selectors.getSubscriptionDetails> = yield select(Selectors.getSubscriptionDetails)
  const isSpecialOfferActive = !!subscriptionDetails?.metadata?.specialOfferStartDate

  if (!isSpecialOfferActive) {
    // Subscription can not be paused if there is an associated schedule with the subscription. We are creating the schedules for the special offer subscriptions.
    // Therefore, we are skipping the pause offer step for the subscriptions with special offer.
    // We can check for a workaround for this in the future. E.g, we can postpone all the phases in the schedule, or we can release the schedule and re-create it after the unpause.
    yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.pause_offer))
  } else {
    yield put({ type: ActionType.CANCEL_SUBSCRIPTION })
  }
}

function * commitManageSubscriptionStepSaga ({ stepId, optionId }: ICommitManageSubscriptionStep) {
  yield put({ type: ActionType.SET_APP_BLOCKED })

  yield call(logEvent, 'web_manageSubscriptionCommit', { stepId, optionId })

  try {
    switch (stepId) {
      case ManageSubscriptionStepId.Intro: {
        yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.subscription_status))
        break
      }

      case ManageSubscriptionStepId.SubscriptionStatus: {
        if (optionId === SubscriptionStatusOption.CancelSubscription) {
          yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.cancellation_reason))
        } else if (optionId === SubscriptionStatusOption.RestoreSubscription) {
          yield call(restoreStripeSubscriptionHandler)
          yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.subscription_restoration_success))
        } else if (optionId === SubscriptionStatusOption.ReactivateSubscription) {
          // leaving the manage_subscription flow
          yield put(push(RouteNames.MANAGE_SUBSCRIPTION_PAYMENT))
        } else if (optionId === SubscriptionStatusOption.UnpauseSubscription) {
          yield call(unpauseStripeSubscriptionHandler)
          yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.unpause_subscription_success))
        } else if (optionId === SubscriptionStatusOption.PauseSubscription) {
          yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.pause_subscription))
        } else if (optionId === SubscriptionStatusOption.HaveQuestion) {
          yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.have_questions))
        }

        break
      }

      case ManageSubscriptionStepId.HaveQuestions: {
        if (optionId === HaveQuestionsOption.GoToFAQ) {
          const lang: ReturnType<typeof getUserLanguage> = yield call(getUserLanguage)
          const faqLink: ReturnType<typeof mapHelpdeskSubscriptionLink> = yield call(mapHelpdeskSubscriptionLink, lang)

          yield call(openUrlInNewTab, faqLink)
        } else if (optionId === HaveQuestionsOption.ContactSupport) {
          yield call(openEmailApp, 'support@bodyfast.app')
        }

        break
      }

      case ManageSubscriptionStepId.PauseOffer: {
        if (optionId === PauseOfferOption.Pause) {
          yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.pause_subscription))
        } else if (optionId === PauseOfferOption.NoThanks) {
          yield put({ type: ActionType.CANCEL_SUBSCRIPTION })
        }

        break
      }

      case ManageSubscriptionStepId.PauseSubscription: {
        let durationMonth

        if (optionId === PauseOption.oneMonth) {
          durationMonth = 1
        } else if (optionId === PauseOption.twoMonths) {
          durationMonth = 2
        } else if (optionId === PauseOption.threeMonths) {
          durationMonth = 3
        } else {
          throw new Error('Invalid pause duration')
        }

        // TODO: handle the response and wrap it with try..catch
        const res: ReturnType<typeof pauseStripeSubscriptionHandler> = yield call(pauseStripeSubscriptionHandler, durationMonth)

        yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.pause_subscription_success))

        break
      }

      case ManageSubscriptionStepId.CancellationReason: {
        if (optionId === CancellationReasonOption.Expensive) {
          yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.cancellation_reason_expensive))
        } else if (optionId === CancellationReasonOption.Undecided) {
          yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.cancellation_reason_undecided))
        } else if (optionId === CancellationReasonOption.AppComplicated) {
          yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.cancellation_reason_app_complicated))
        } else if (optionId === CancellationReasonOption.AppNotHelping) {
          yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.cancellation_reason_app_not_helping))
        } else if (optionId === CancellationReasonOption.GoalAchieved) {
          yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.cancellation_reason_goal_achieved))
        } else if (optionId === CancellationReasonOption.FoundBetterAlternatives) {
          yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.cancellation_reason_found_better_alternative))
        } else if (optionId === CancellationReasonOption.NotSpecified) {
          yield call(handleCancelAnywayPressSaga)
        }

        break
      }

      case ManageSubscriptionStepId.CancellationReasonExpensive: {
        if (optionId === CancellationReasonExpensiveOption.LearnMore) {
          const lang: ReturnType<typeof getUserLanguage> = yield call(getUserLanguage)
          const cancellationReasonExpensiveLink: ReturnType<typeof mapCancellationReasonExpensiveLink> = yield call(mapCancellationReasonExpensiveLink, lang)

          yield call(openUrlInNewTab, cancellationReasonExpensiveLink)
        } else if (optionId === CancellationReasonExpensiveOption.CancelAnyway) {
          yield call(handleCancelAnywayPressSaga)
        }

        break
      }

      case ManageSubscriptionStepId.CancellationReasonUndecided: {
        if (optionId === CancellationReasonUndecidedOption.LearnMore) {
          const lang: ReturnType<typeof getUserLanguage> = yield call(getUserLanguage)
          const cancellationReasonUndecidedLink: ReturnType<typeof mapCancellationReasonUndecidedLink> = yield call(mapCancellationReasonUndecidedLink, lang)

          yield call(openUrlInNewTab, cancellationReasonUndecidedLink)
        } else if (optionId === CancellationReasonUndecidedOption.CancelAnyway) {
          yield call(handleCancelAnywayPressSaga)
        }

        break
      }

      case ManageSubscriptionStepId.CancellationReasonAppComplicated: {
        if (optionId === CancellationReasonAppComplicatedOption.LearnMore) {
          const lang: ReturnType<typeof getUserLanguage> = yield call(getUserLanguage)
          const cancellationReasonAppComplicatedLink: ReturnType<typeof mapCancellationReasonAppComplicatedLink> = yield call(mapCancellationReasonAppComplicatedLink, lang)

          yield call(openUrlInNewTab, cancellationReasonAppComplicatedLink)
        } else if (optionId === CancellationReasonAppComplicatedOption.CancelAnyway) {
          yield call(handleCancelAnywayPressSaga)
        }

        break
      }

      case ManageSubscriptionStepId.CancellationReasonAppNotHelping: {
        if (optionId === CancellationReasonAppNotHelpingOption.LearnMore) {
          const lang: ReturnType<typeof getUserLanguage> = yield call(getUserLanguage)
          const cancellationReasonAppNotHelpingLink: ReturnType<typeof mapCancellationReasonAppNotHelpingLink> = yield call(mapCancellationReasonAppNotHelpingLink, lang)

          yield call(openUrlInNewTab, cancellationReasonAppNotHelpingLink)
        } else if (optionId === CancellationReasonAppNotHelpingOption.CancelAnyway) {
          yield call(handleCancelAnywayPressSaga)
        }

        break
      }

      case ManageSubscriptionStepId.CancellationReasonGoalAchieved: {
        if (optionId === CancellationReasonGoalAchievedOption.LearnMore) {
          const lang: ReturnType<typeof getUserLanguage> = yield call(getUserLanguage)
          const cancellationReasonGoalAchievedLink: ReturnType<typeof mapCancellationReasonGoalAchievedLink> = yield call(mapCancellationReasonGoalAchievedLink, lang)

          yield call(openUrlInNewTab, cancellationReasonGoalAchievedLink)
        } else if (optionId === CancellationReasonGoalAchievedOption.CancelAnyway) {
          yield call(handleCancelAnywayPressSaga)
        }

        break
      }

      case ManageSubscriptionStepId.CancellationReasonFoundBetterAlternative: {
        if (optionId === CancellationReasonGoalAchievedOption.LearnMore) {
          const lang: ReturnType<typeof getUserLanguage> = yield call(getUserLanguage)
          const cancellationReasonFoundBetterAlternative: ReturnType<typeof mapCancellationReasonFoundBetterAlternativeLink> = yield call(mapCancellationReasonFoundBetterAlternativeLink, lang)

          yield call(openUrlInNewTab, cancellationReasonFoundBetterAlternative)
        } else if (optionId === CancellationReasonGoalAchievedOption.CancelAnyway) {
          yield call(handleCancelAnywayPressSaga)
        }

        break
      }
    }
  } catch (error) {
    showToast('error', t('errors.unknownError')) // @matti is this message ok? Or should we show a message based on the error coming from API response?
    yield call(logError, 'web_manageSubscriptionCommitError', error)
  }

  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

function * acceptSpecialOfferSaga ({ specialOfferDuration }: IAcceptSpecialOffer) {
  yield put({ type: ActionType.SET_APP_BLOCKED })

  yield call(logEvent, 'web_acceptSpecialOffer')

  try {
    yield call(updateSubscriptionWithSpecialOfferHandler, specialOfferDuration)
    yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.special_offer_accepted))
  } catch (error) {
    showToast('error', t('errors.unknownError')) // @matti is this message ok? Or should we show a message based on the error coming from API response?
    yield call(logError, 'web_acceptSpecialOfferError', error)
  }

  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

function * declineSpecialOfferSaga () {
  yield put({ type: ActionType.CANCEL_SUBSCRIPTION })
  yield call(logEvent, 'web_declineSpecialOffer')
}

function * cancelSubscriptionSaga () {
  yield put({ type: ActionType.SET_APP_BLOCKED })

  yield call(logEvent, 'web_cancelSubscription')

  try {
    yield call(cancelStripeSubscriptionHandler)
    yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.cancel_subscription_success))
  } catch (error) {
    showToast('error', t('errors.unknownError')) // @matti is this message ok? Or should we show a message based on the error coming from API response?
    yield call(logError, 'web_cancelSubscriptionError', error)
  }

  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

function * handleLoginSuccessSaga () {
  yield put(push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.subscription_status))
}

function * handleInAppBrowserCloseSaga () {
  window.open('bodyfast://close-in-app-browser') // This link must match with the one in LinkingService.js -> openInAppBrowser -> handleClose
  yield call(logEvent, 'web_closeInAppBrowser')
}

export function * ManageSubscriptionSagas() {
  yield all([
    takeLatest(ActionType.COMMIT_MANAGE_SUBSCRIPTION_STEP, commitManageSubscriptionStepSaga),
    takeLatest(ActionType.ACCEPT_SPECIAL_OFFER, acceptSpecialOfferSaga),
    takeLatest(ActionType.DECLINE_SPECIAL_OFFER, declineSpecialOfferSaga),
    takeLatest(ActionType.CANCEL_SUBSCRIPTION, cancelSubscriptionSaga),
    takeLatest(ActionType.HANDLE_LOGIN_SUCCESS, handleLoginSuccessSaga),
    takeLatest(ActionType.CLOSE_IN_APP_BROWSER, handleInAppBrowserCloseSaga)
  ])
}
