import { options, title } from 'Containers/steps/Consts'
import { t } from 'i18next'
import { ManageSubscriptionStep, ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'

export enum SubscriptionStatusOption {
  CancelSubscription = 'cancelSubscription',
  RestoreSubscription = 'restoreSubscription',
  ReactivateSubscription = 'reactivateSubscription',
  UnpauseSubscription = 'unpauseSubscription',
  PauseSubscription = 'pauseSubscription',
  HaveQuestion = 'haveQuestion'
}

export function getSubscriptionStatusStep(): ManageSubscriptionStep {
  return {
    id: ManageSubscriptionStepId.SubscriptionStatus,
    type: 'custom',
    allowNext: true,
    wordings: {
      title: t(`${ManageSubscriptionStepId.SubscriptionStatus}.${title}`)
    }
  }
}
