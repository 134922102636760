import { StripePricesType } from 'Services'
import { t } from 'i18next'
import invariant from 'invariant'
import { CustomPackage, PackageTagId, PromotionLabelStyle } from 'types/onboardingTypes'

const daysPerIntervals = new Map<number, number>([
  [1, 30.4], // Average month is 30.4 days
  [12, 365],
  [3, 92],
  [6, 180]
])

const mapSubscriptionModelToGroupId = (subscriptionModel: SubscriptionModel): SubscriptionGroupId => {
  switch (subscriptionModel) {
    case 'oneMonthV13':
      return 'v13'
    case 'threeMonthsV13':
      return 'v13'
  }
}

const mapSubscriptionModelToRecurringInfo = (subscriptionModel: SubscriptionModel): Pick<IStripeRecurring, 'interval' | 'interval_count'> => {
  switch (subscriptionModel) {
    case 'oneMonthV13':
      return { interval: 'month', interval_count: 1 }
    case 'threeMonthsV13':
      return { interval: 'month', interval_count: 3 }
  }
}

export const formatPricePackage = (item: IStripePrice, promotionLabelStyle: PromotionLabelStyle = 'original', discountAvailable?: boolean): CustomPackage => {
  const price = item.unit_amount / 100
  const interval = item.recurring.interval

  const discountPercentage = Number(item.metadata.baseDiscountPercentage) / 100
  const discountedItemPrice = discountAvailable && !isNaN(discountPercentage) ? (item.unit_amount / 100) * (1 - discountPercentage) : undefined

  let tag
  let tagId!: PackageTagId | undefined
  let daysPerInterval = 1

  const useOriginalPackageLabels = promotionLabelStyle === 'original' || item.metadata.group !== 'v8'
  const popularTag = useOriginalPackageLabels ? t('package.tags.popular') : t('package.tags.mostPopular')
  const fastTrackOrBestPriceTag = useOriginalPackageLabels ? t('package.tags.bestPrice') : t('package.tags.fastTrack')

  const popularTagId = PackageTagId.MostPopular
  const fastTrackOrBestPriceTagId = useOriginalPackageLabels ? PackageTagId.BestValue : PackageTagId.FastTrack

  if (interval === 'month') {
    daysPerInterval = daysPerIntervals.get(item.recurring.interval_count)!

    if (item.recurring.interval_count === 3) {
      if (useOriginalPackageLabels) {
        tag = item.metadata.group === 'v8' ? fastTrackOrBestPriceTag : popularTag
        tagId = item.metadata.group === 'v8' ? fastTrackOrBestPriceTagId : popularTagId
      } else {
        tag = item.metadata.group === 'v8' ? popularTag : fastTrackOrBestPriceTag
        tagId = item.metadata.group === 'v8' ? popularTagId : fastTrackOrBestPriceTagId
      }
    } else {
      if (useOriginalPackageLabels) {
        tag = item.metadata.group === 'v8' ? popularTag : undefined
        tagId = item.metadata.group === 'v8' ? popularTagId : undefined
      } else {
        tag = item.metadata.group === 'v8' ? fastTrackOrBestPriceTag : undefined
        tagId = item.metadata.group === 'v8' ? fastTrackOrBestPriceTagId : undefined
      }
    }
  } else if (interval === 'year') {
    if (useOriginalPackageLabels) {
      tag = fastTrackOrBestPriceTag
      tagId = fastTrackOrBestPriceTagId
      daysPerInterval = daysPerIntervals.get(12)!
    } else {
      tag = popularTag
      tagId = popularTagId
      daysPerInterval = daysPerIntervals.get(12)!
    }
  }

  const pricePerDay = (price / daysPerInterval)
  const discountedPricePerDay = discountedItemPrice ? (discountedItemPrice / daysPerInterval) : undefined
  const intervalCount = interval === 'year' ? item.recurring.interval_count * 12 : item.recurring.interval_count

  let name = ''
  if (interval === 'year') {
    name = t(intervalCount === 12 ? 'package.yearly' : 'package.yearly_plural', { intervalCount: item.recurring.interval_count })
  } else if (interval === 'month') {
    name = t(intervalCount === 1 ? 'package.monthly' : 'package.monthly_plural', { intervalCount })
  }

  return ({
    id: item.id,
    name: name,
    price: price,
    priceInCents: item.unit_amount,
    pricePerDay: pricePerDay,
    numberOfPeriods: intervalCount,
    tag: tag,
    tagId: tagId,
    discountedPrice: discountedItemPrice,
    discountedPriceInCents: discountedItemPrice ? Math.round(discountedItemPrice * 100) : undefined,
    discountedPricePerDay: discountedPricePerDay,
    interval: item.recurring.interval,
    currency: item.currency.toUpperCase()
  })
}

export const formatTrialPackage = (mainItem: IStripePrice, trialItem: IStripePrice, discountAvailable?: boolean): CustomPackage => {
  const price = trialItem.unit_amount / 100
  const discountPercentage = Number(trialItem.metadata.baseDiscountPercentage) / 100
  const discountedItemPrice = trialItem?.unit_amount && discountAvailable ? (trialItem?.unit_amount / 100) * (1 - discountPercentage) : undefined

  const tagId = PackageTagId.Trial
  const daysPerInterval = 7

  const pricePerDay = (price / daysPerInterval)
  const discountedPricePerDay = discountedItemPrice ? (discountedItemPrice / daysPerInterval) : undefined

  return ({
    id: trialItem.id,
    name: t('package.trial'),
    price: price,
    pricePerDay: pricePerDay,
    numberOfPeriods: 1,
    tagId: tagId,
    discountedPrice: discountedItemPrice,
    discountedPricePerDay: discountedPricePerDay,
    priceInCents: trialItem.unit_amount,
    discountedPriceInCents: discountedItemPrice ? Math.round(discountedItemPrice * 100) : undefined,
    interval: 'week',
    currency: trialItem.currency.toUpperCase()
  })
}

export const getFormattedPackage = (products: Products, subscriptionModel: SubscriptionModel, promotionLabelStyle?: PromotionLabelStyle, discountAvailable?: boolean): CustomPackage | null => {
  const groupId = mapSubscriptionModelToGroupId(subscriptionModel)
  const rawPrices = products[groupId]

  if (!rawPrices) {
    return null
  }

  const recurringInfoOfSelectedModel = mapSubscriptionModelToRecurringInfo(subscriptionModel)

  const price = rawPrices
    .filter((item: IStripePrice) => item.active)
    .find((item: IStripePrice) => item.recurring.interval === recurringInfoOfSelectedModel.interval && item.recurring.interval_count === recurringInfoOfSelectedModel.interval_count)

  invariant(price, `No price found for subscription model ${subscriptionModel}`)

  if (price.metadata.group === 'v8' && !price.recurring) { // Trial
    const prices = rawPrices
      .filter((item: IStripePrice) => item.active)
      .filter((item: IStripePrice) => (item.type === 'recurring' && item.recurring.interval_count !== 6) || item.metadata.group === 'v8')

    const oneMonthRecurringPackage = prices?.find((filteredItem) => filteredItem.type === 'recurring' && filteredItem.recurring.interval_count === 1)

    return formatTrialPackage(oneMonthRecurringPackage!, price, discountAvailable)
  }

  return formatPricePackage(price, promotionLabelStyle, discountAvailable)
}
