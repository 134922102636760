export const mapHelpdeskSubscriptionLink = (lang: string) => {
  switch (lang) {
    case 'de':
      return 'https://help.bodyfast.app/de/support/solutions/folders/36000176244'
    case 'en':
      return 'https://help.bodyfast.app/en/support/solutions/folders/36000176244'
    case 'fr':
      return 'https://help.bodyfast.app/fr/support/solutions/folders/36000176244'
    case 'es':
      return 'https://help.bodyfast.app/es/support/solutions/folders/36000176244'
    case 'pt':
      return 'https://help.bodyfast.app/pt/support/solutions/folders/36000176244'
    case 'it':
      return 'https://help.bodyfast.app/it/support/solutions/folders/36000176244'
    default:
      throw new Error(`FAQ link not found for language ${lang}`)
  }
}

export const mapCancellationReasonExpensiveLink = (lang: string) => {
  switch (lang) {
    case 'de':
      return 'https://www.bodyfast.app/de/coachonomics-101/'
    case 'en':
      return 'https://www.bodyfast.app/en/coachonomics-101/'
    case 'fr':
      return 'https://www.bodyfast.app/fr/coachonomics-101/'
    case 'es':
      return 'https://www.bodyfast.app/es/coachonomics-101/'
    case 'pt':
      return 'https://www.bodyfast.app/pt/coachonomics-101/'
    case 'it':
      return 'https://www.bodyfast.app/it/coachonomics-101/'
    default:
      throw new Error(`Cancelation reason expensive - link not found for language ${lang}`)
  }
}

export const mapCancellationReasonFoundBetterAlternativeLink = (lang: string) => {
  switch (lang) {
    case 'de':
      return 'https://www.bodyfast.app/de/only-the-best/'
    case 'en':
      return 'https://www.bodyfast.app/en/only-the-best/'
    case 'fr':
      return 'https://www.bodyfast.app/fr/only-the-best/'
    case 'es':
      return 'https://www.bodyfast.app/es/only-the-best/'
    case 'pt':
      return 'https://www.bodyfast.app/pt/only-the-best/'
    case 'it':
      return 'https://www.bodyfast.app/it/only-the-best/'
    default:
      throw new Error(`Cancelation reason foundBetterAlternative - link not found for language ${lang}`)
  }
}

export const mapCancellationReasonUndecidedLink = (lang: string) => {
  switch (lang) {
    case 'de':
      return 'https://www.bodyfast.app/de/testimonial-linda/'
    case 'en':
      return 'https://www.bodyfast.app/en/testimonial-linda/'
    case 'fr':
      return 'https://www.bodyfast.app/fr/testimonial-linda/'
    case 'es':
      return 'https://www.bodyfast.app/es/testimonial-linda/'
    case 'pt':
      return 'https://www.bodyfast.app/pt/testimonial-linda/'
    case 'it':
      return 'https://www.bodyfast.app/it/testimonial-linda/'
    default:
      throw new Error(`Cancelation reason undecided - link not found for language ${lang}`)
  }
}

export const mapCancellationReasonAppComplicatedLink = (lang: string) => {
  switch (lang) {
    case 'de':
      return 'https://www.bodyfast.app/de/hang-in-there/'
    case 'en':
      return 'https://www.bodyfast.app/en/hang-in-there/'
    case 'fr':
      return 'https://www.bodyfast.app/fr/hang-in-there/'
    case 'es':
      return 'https://www.bodyfast.app/es/hang-in-there/'
    case 'pt':
      return 'https://www.bodyfast.app/pt/hang-in-there/'
    case 'it':
      return 'https://www.bodyfast.app/it/hang-in-there/'
    default:
      throw new Error(`Cancelation reason appComplicated - link not found for language ${lang}`)
  }
}

export const mapCancellationReasonAppNotHelpingLink = (lang: string) => {
  switch (lang) {
    case 'de':
      return 'https://www.bodyfast.app/de/recipe-for-success/'
    case 'en':
      return 'https://www.bodyfast.app/en/recipe-for-success/'
    case 'fr':
      return 'https://www.bodyfast.app/fr/recipe-for-success/'
    case 'es':
      return 'https://www.bodyfast.app/es/recipe-for-success/'
    case 'pt':
      return 'https://www.bodyfast.app/pt/recipe-for-success/'
    case 'it':
      return 'https://www.bodyfast.app/it/recipe-for-success/'
    default:
      throw new Error(`Cancelation reason appNotHelping - link not found for language ${lang}`)
  }
}

export const mapCancellationReasonGoalAchievedLink = (lang: string) => {
  switch (lang) {
    case 'de':
      return 'https://www.bodyfast.app/de/maintenance-strategy/'
    case 'en':
      return 'https://www.bodyfast.app/en/maintenance-strategy/'
    case 'fr':
      return 'https://www.bodyfast.app/fr/maintenance-strategy/'
    case 'es':
      return 'https://www.bodyfast.app/es/maintenance-strategy/'
    case 'pt':
      return 'https://www.bodyfast.app/pt/maintenance-strategy/'
    case 'it':
      return 'https://www.bodyfast.app/it/maintenance-strategy/'
    default:
      throw new Error(`Cancelation reason goalAchieved - link not found for language ${lang}`)
  }
}

export const mapCancellationReasonOtherLink = (lang: string) => {
  switch (lang) {
    case 'de':
      return 'https://www.bodyfast.app/de/hang-in-there/'
    case 'en':
      return 'https://www.bodyfast.app/en/hang-in-there/'
    case 'fr':
      return 'https://www.bodyfast.app/fr/hang-in-there/'
    case 'es':
      return 'https://www.bodyfast.app/es/hang-in-there/'
    case 'pt':
      return 'https://www.bodyfast.app/pt/hang-in-there/'
    case 'it':
      return 'https://www.bodyfast.app/it/hang-in-there/'
    default:
      throw new Error(`Cancelation reason other - link not found for language ${lang}`)
  }
}

export const mapHelpdeskLink = (lang: string) => {
  switch (lang) {
    case 'de':
      return 'https://help.bodyfast.app/de/support/solutions'
    case 'en':
      return 'https://help.bodyfast.app/en/support/solutions'
    case 'fr':
      return 'https://help.bodyfast.app/fr/support/solutions'
    case 'es':
      return 'https://help.bodyfast.app/es/support/solutions'
    case 'pt':
      return 'https://help.bodyfast.app/pt-PT/support/solutions' // pt-PT is the correct language code for Portuguese
    case 'it':
      return 'https://help.bodyfast.app/it/support/solutions'
    default:
      throw new Error(`Helpdesk link not found for language ${lang}`)
  }
}

export const mapPrivacyPolicyLink = (lang: string) => {
  const baseUrl = location.hostname === 'localhost' ? 'http://localhost:3000' : `https://${location.hostname}`
  return `${baseUrl}/privacy`
}

export const mapCookiePolicyLink = (lang: string) => {
  const baseUrl = location.hostname === 'localhost' ? 'http://localhost:3000' : `https://${location.hostname}`
  return `${baseUrl}/cookie-policy`
}

export const mapTermsOfUseLink = (lang: string) => {
  const baseUrl = location.hostname === 'localhost' ? 'http://localhost:3000' : `https://${location.hostname}`
  return `${baseUrl}/terms`
}

export const mapRefundPolicyLink = (lang: string) => {
  switch (lang) {
    case 'de':
      return 'https://www.bodyfast.app/de/Refund'
    case 'en':
      return 'https://www.bodyfast.app/en/Refund'
    case 'fr':
      return 'https://www.bodyfast.app/fr/Refund'
    case 'es':
      return 'https://www.bodyfast.app/es/Refund'
    case 'pt':
      return 'https://www.bodyfast.app/pt/Refund'
    case 'it':
      return 'https://www.bodyfast.app/it/Refund'
    default:
      throw new Error(`Refund Policy not found for language ${lang}`)
  }
}
