import { CustomPackage, PackageTagId } from 'types/onboardingTypes'

export function getRecurringAndTrialId(selectedPackage: CustomPackage, recurringPackageId: string, packages: CustomPackage[]) {
  let trialId

  if (selectedPackage.tagId === PackageTagId.Trial) {
    // invert package id and trial package id, so the main ID is the 1 month value, and the trialId is not empty
    recurringPackageId = packages.find((price) => price.interval === 'month' && price.numberOfPeriods === 1)!.id

    trialId = selectedPackage.id
  }

  return { recurringPackageId, trialId }
}
