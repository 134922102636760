import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'

export function getProgressStep(t: TFunction): OnboardingStep {
  return {
    id: 'progress',
    type: 'custom',
    wordings: {
      title: t('progress_step.title')
    }
  }
}
