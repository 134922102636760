// library is just a simple wrapper against the facebook script
// allows to more easily call the even tracking methods
// https://github.com/zsajjad/react-facebook-pixel
import FacebookPixel from 'react-facebook-pixel'
import { facebookService } from 'store'

function handlePixelTrack(stepId: string) {
  const originalSearchParams = new URLSearchParams(localStorage.getItem('originalSearchParams') ?? '')
  const fbc = originalSearchParams.get('fbc')
  const fbp = originalSearchParams.get('fbp')

  if (__DEV__) {
    return
  }

  if (stepId === 'gender') {
    facebookService.trackCustom('commitGender', {
      fbc,
      fbp
    })
  } else if (stepId === 'reinforcement-scientific') {
    facebookService.trackCustom('commitReinforcementScientific', {
      fbc,
      fbp
    })
  } else if (stepId === 'bmi') {
    facebookService.trackCustom('commitBmi', {
      fbc,
      fbp
    })
  } else if (stepId === 'email') {
    facebookService.trackCustom('commitNewsletter', {
      fbc,
      fbp
    })
  } else if (stepId === 'receive-email') {
    facebookService.trackCustom('commitReceiveNewsletter', {
      fbc,
      fbp
    })
  }
}

export function createFacebookService() {
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false // enable logs
  }

  // Do not pollute production data from other domains
  if (!__DEV__) {
    FacebookPixel.init('1794832324097079', undefined, options)
    FacebookPixel.revokeConsent()
  }

  // FacebookPixel.pageView(); // For tracking page view
  // FacebookPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
  // FacebookPixel.trackSingle('PixelId', event, data); // For tracking default events.
  // FacebookPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
  // FacebookPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.
  return {
    track: FacebookPixel.track,
    trackCustom: FacebookPixel.trackCustom,
    pageView: FacebookPixel.pageView,
    handlePixelTrack
  }
}

export type IFacebookService = ReturnType<typeof createFacebookService>
