import { onboarding } from 'Containers/steps/Consts'
import { Trans, useTranslation } from 'react-i18next'
import 'react-multi-date-picker/styles/colors/teal.css'
import styled from 'styled-components'
import Button from './Button'
import Logo from './Logo'
import { useFeatureValue } from '@growthbook/growthbook-react'

const Container = styled.div`
  margin: auto;
  margin-top: ${({ theme }) => theme.spacing.medium};
  align-items: center;
  width: 100%;
  max-width: 500px;
  text-align: center;
`

const TextWrapper = styled.div`
  font-size:  ${({ theme }) => theme.spacing.large};

  h1 {
    font-size: ${({ theme }) => theme.spacing.medium};
    font-weight: 600;
    margin:  ${({ theme }) => theme.spacing.medium};
  }
`

const H1WithColor = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: ${({ theme }) => theme.fontSizes.small};
    margin: ${({ theme }) => theme.spacing.small};
    font-weight: 600;
    cursor: pointer;
  }
`

const Disclaimer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.xLarge};
  p {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
    color: ${({ theme }) => theme.colors.lightText};
  }
}`

interface ReceiveEmailsProps {
  onNext: (accept: boolean) => void
}

const ReceiveEmails: React.FC<ReceiveEmailsProps> = ({ onNext }) => {
  const newsletterDisclaimerVersion = useFeatureValue('newsletter-disclaimer', 'v1')

  const { t } = useTranslation(onboarding)
  let title: string
  let yesButton: string
  let noButton: string
  switch (newsletterDisclaimerVersion) {
    case 'v4':
    case 'v5':
    {
      title = 'receive-email-v4-v5.title'
      yesButton = t('receive-email-v4-v5.yesButton')
      noButton = t('receive-email-v4-v5.noButton')
      break
    }
    default: {
      title = 'receive-email.title'
      yesButton = t('receive-email.yesButton')
      noButton = t('receive-email.noButton')
    }
  }

  return (
    <>
      <Logo />
      <Container>
        <Wrapper>
          <TextWrapper>
            <h1>
              <Trans
                i18nKey= {title}
                components={[<H1WithColor/>]}
              />
            </h1>
          </TextWrapper>
          <Button onClick={() => onNext(true)} margin='0px'>
            {yesButton}
          </Button>
          <p onClick={() => onNext(false)}>{noButton}</p>

        </Wrapper>
        {(newsletterDisclaimerVersion === 'v2' ||
        newsletterDisclaimerVersion === 'v3' ||
        newsletterDisclaimerVersion === 'v4' ||
        newsletterDisclaimerVersion === 'v5'
        ) && (
          <Disclaimer1>
            <p>{t('receive-email.disclaimer')}</p>
          </Disclaimer1>
        )}
      </Container >
    </>
  )
}

export default ReceiveEmails
