import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const PricePerDayWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing.medium};
  border-radius: 8px;
  padding: ${props => props.theme.spacing.medium};
  background-color: #f5faf7;
`

const PricePerDayDescription = styled.div`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: bold;
  color:${props => props.theme.colors.text};
`

const PricePerDayValue = styled.div`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: bold;
  color: #7fb89d;
`

interface PricePerDayProps {
  price: string
}

const PricePerDay: React.FC<PricePerDayProps> = ({ price }) => {
  const { t } = useTranslation()

  return (
    <PricePerDayWrapper>
      <PricePerDayDescription>{t('modal.pricePerDay')}</PricePerDayDescription>
      <PricePerDayValue>{price}</PricePerDayValue>
    </PricePerDayWrapper>
  )
}

export default PricePerDay
