import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'

export function getReinforcementScientificMethod(t: TFunction): OnboardingStep {
  return {
    id: 'reinforcement_scientific',
    type: 'information',
    allowNext: true,
    wordings: {
      title: ''
    }
  }
}
