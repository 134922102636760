import { images } from 'images'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const UserTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: bold;
`

const Date = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.lightText};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const BeforeAfterImage = styled.img`
  width: 100%;
  max-width: 400px;
  object-fit: cover;
  align-self: center;
  margin-top: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  height: 400px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: 250px;
    width: auto;
  }
`
const Testimony = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-top: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  font-style: italic;
`

interface ReinforcementBeforeAfterProps {
  gender: IGender
}

const ReinforcementBeforeAfter: React.FC<ReinforcementBeforeAfterProps> = ({ gender }) => {
  const { t } = useTranslation()

  const image = gender === 'f' ? images.beforeAfterFemale : images.beforeAfterMale
  const userTitle = gender === 'f' ? t('reinforcement-before-after.femaleTitle') : t('reinforcement-before-after.maleTitle')
  const date = t('reinforcement-before-after.date')
  const testimony = gender === 'f' ? t('reinforcement-before-after.femaleTestimony') : t('reinforcement-before-after.maleTestimony')

  return (
    <>
      <BeforeAfterImage src={image} />
      <Row>
        <UserTitle>{userTitle}</UserTitle>
        <Date>{date}</Date>
      </Row>
      <Testimony>{testimony}</Testimony>
    </>
  )
}

export default ReinforcementBeforeAfter
