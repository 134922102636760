import { options, title } from 'Containers/steps/Consts'
import { t } from 'i18next'
import { ManageSubscriptionStep, ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'

export function getSpecialOfferAcceptedStep(): ManageSubscriptionStep {
  return {
    id: ManageSubscriptionStepId.SpecialOfferAccepted,
    type: 'custom',
    allowNext: true,
    wordings: {
      title: t(`${ManageSubscriptionStepId.SpecialOfferAccepted}.${title}`)
    }
  }
}
