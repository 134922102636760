import styled from 'styled-components'

const StepTitle = styled.h1`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  width: 100%;
  text-align: center;
`

export default StepTitle
