import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const SkipWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.large};
  margin-top: ${({ theme }) => theme.spacing.small};
  
  p {
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`

const SkipComponent: React.FC<{ onClick: () => void, text?: string }> = ({ onClick, text }) => {
  const { t } = useTranslation()

  return (
    <SkipWrapper>
      <p onClick={onClick}>{text ? t(text) : t('skip_question')}</p>
    </SkipWrapper>
  )
}

export default SkipComponent
