import 'Services/Sentry.service' // Don't move this. Sentry must be initialized before anything else
import './globals'
import React from 'react'
import ReactDOM from 'react-dom'
import 'typeface-fira-sans'
import App from './App'
import './i18n'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
