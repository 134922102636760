import { BodyFastAction } from 'Reducers'
import { ActionType } from './ActionTypes'

export interface PaymentState {
  products: Products
}

export interface ILoadProducts { type: ActionType.LOAD_PRODUCTS, groupId: SubscriptionGroupId, isRetry?: boolean }
export interface IMergeProducts { type: ActionType.MERGE_PRODUCTS, groupId: SubscriptionGroupId, prices: IStripePrice[] }

export type PaymentAction =
  | ILoadProducts
  | IMergeProducts

const EMPTY_PRODUCTS = {}

const INITIAL_STATE: PaymentState = {
  products: EMPTY_PRODUCTS
}

export const PaymentReducer = (state: PaymentState = INITIAL_STATE, action: BodyFastAction): PaymentState => {
  switch (action.type) {
    case ActionType.MERGE_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          [action.groupId]: action.prices
        }
      }

    default:
      return state
  }
}
