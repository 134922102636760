import React, { useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styles/theme'
import { useDispatch } from 'store'
import { ActionType } from 'Reducers'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import StepSubtitle from './StepSubtitle.component'
import StepTitle from './StepTitle.component'
import OptionWrapper from './OptionWrapper'
import { ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'
import { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import { PauseOption } from 'Containers/manageSubscriptionSteps/PauseSubscription'
import { useHistory } from 'react-router-dom'
import { Spacer } from 'Components/Spacer'

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xLarge};
  align-items: center;
  text-align: center;
  margin: auto;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
`

const OptionsSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing.medium};
`

const CheckboxIcon = styled.svg<{ selected: boolean }>`
  fill: ${({ selected }) => (selected ? theme.colors.white : theme.colors.transparent)};
  stroke: ${({ selected, theme }) => (selected ? theme.colors.primary : '#999')};
  stroke-width: 2px;
  margin-right: ${({ theme }) => theme.spacing.small};
`

const OptionLabel = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const getItems = (t: TFunction) => {
  const lDateNow = DateTime.now()

  return [
    {
      text: t(`${ManageSubscriptionStepId.PauseSubscription}.oneMonth`, { date: lDateNow.plus({ months: 1 }).toLocaleString(), interpolation: { escapeValue: false } }),
      option: PauseOption.oneMonth
    },
    {
      text: t(`${ManageSubscriptionStepId.PauseSubscription}.twoMonths`, { date: lDateNow.plus({ months: 2 }).toLocaleString(), interpolation: { escapeValue: false } }),
      option: PauseOption.twoMonths
    },
    {
      text: t(`${ManageSubscriptionStepId.PauseSubscription}.threeMonths`, { date: lDateNow.plus({ months: 3 }).toLocaleString(), interpolation: { escapeValue: false } }),
      option: PauseOption.threeMonths
    }
  ]
}

const PauseSubscriptionStep: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const items = getItems(t)

  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0)

  const onConfirmPress = () => {
    dispatch({ type: ActionType.COMMIT_MANAGE_SUBSCRIPTION_STEP, stepId: ManageSubscriptionStepId.PauseSubscription, optionId: items[selectedItemIndex].option })
  }
  const onGoBackPress = () => {
    logEvent('web_pauseSubscriptionGoBack')
    history.goBack()
  }

  return (
    <Container>
      <StepTitle>{t(`${ManageSubscriptionStepId.PauseSubscription}.title`)}</StepTitle>
      <StepSubtitle>{t(`${ManageSubscriptionStepId.PauseSubscription}.subtitle`)}</StepSubtitle>
      <OptionsSection>
        {items.map((item, index) => {
          const isSelected = index === selectedItemIndex

          return (
            <OptionWrapper key={index} onClick={() => setSelectedItemIndex(index)}>
              <CheckboxIcon
                width="24"
                height="24"
                viewBox="0 0 24 24"
                selected={isSelected}
              >
                <rect x="5" y="5" width="14" height="14" />
                {isSelected && <path d="M9 12l2 2l4 -4" />}
              </CheckboxIcon>
              <OptionLabel>
                <span>{item.text}</span>
              </OptionLabel>
            </OptionWrapper>
          )
        })}
      </OptionsSection>

      <Spacer />

      <ButtonContainer>
        <Button margin='0 10px 0 0' theme='outline' onClick={onGoBackPress}>
          {t('goBack')}
        </Button>
        <Button margin='0 0 0 10px' onClick={onConfirmPress}>
          {t('confirm')}
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default PauseSubscriptionStep
