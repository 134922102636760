import { loadScript } from '@paypal/paypal-js'
import { navigateToPaymentSuccess } from 'Lib'
import { DefaultConfig } from 'Lib/Constants'
import StripePaypalMapping from 'Lib/StripePaypalMapping.json'
import StripePaypalTestMapping from 'Lib/StripePaypalTestMapping.json'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { firebaseServicePromise } from 'store'
import { CustomPackage } from 'types/onboardingTypes'

const PAYPAL_PRODUCTION_CLIENT_ID = 'AUi-z5CCKeV9pweBJHXOE13DM63yYnIF6wi-pCY-qC10S15-RcZuMQf-JXQUcQYtmbfjW0Y6DWVdJG14'
const PAYPAL_DEVELOPMENT_CLIENT_ID = 'AcayHJcfrmi7YnLlIrXv4Fq97wj4F3sItK_Omz1Q767V0dLMsLTXFpWPsw_PvdU0HpjgcraS2XzE2m8P'

interface IProps {
  onError: (err: Error) => void
  selectedPackage: CustomPackage
}

export const PaypalButton = ({ selectedPackage, onError }: IProps) => {
  const paypalRef = useRef<any>()
  const history = useHistory()

  useEffect(() => {
    const initPaypalButton = async () => {
      if (!selectedPackage.id) {
        return
      }

      // paypal does not allow dynamic prices, so we need to have 1 subscription plan with discount, and one without. both referring to the same stripe packageId on the mappings
      const subscriptionGroup = localStorage.getItem('sgroup')?.toLowerCase() ?? DefaultConfig.SUBSCRIPTION_GROUP
      const packageId = (subscriptionGroup === 'v8' && !selectedPackage.discountedPrice) ? `${selectedPackage.id}_standard` : selectedPackage.id

      const paypalPlanId = __DEV__ ? (StripePaypalTestMapping as any)[packageId] : (StripePaypalMapping as any)[packageId]

      if (!paypalPlanId) {
        return
      }

      const clientId = __DEV__ ? PAYPAL_DEVELOPMENT_CLIENT_ID : PAYPAL_PRODUCTION_CLIENT_ID
      const paypal = await loadScript({ 'client-id': clientId, vault: true, intent: 'subscription', 'disable-funding': 'credit,card' })

      if (paypal == null) {
        // TODO log error
        return
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      paypal.Buttons?.({
        createSubscription: async (data, actions) => {
          const firebase = await firebaseServicePromise
          const userId = firebase.getUserID()

          return (await actions.subscription.create({
            plan_id: paypalPlanId,
            custom_id: userId
          }))
        },
        onClick: () => {
          logEvent('web_startCheckout', { channel: 'paypal', ...selectedPackage })
        },
        onApprove: async (data) => {
          if (!data.subscriptionID) {
            onError(new Error('Could not process paypal subscription'))
            return
          }

          const firebase = await firebaseServicePromise
          const userTokenId = await firebase.getIdToken()
          await navigateToPaymentSuccess(history, userTokenId, data.subscriptionID)
        },
        onError: (err: any) => {
          onError(new Error(JSON.stringify(err.message)))
        },
        style: {
          shape: 'rect',
          color: 'white'
        }
      }).render(paypalRef.current)
    }

    void initPaypalButton()
  }, [selectedPackage.id])

  return (
    <div key={selectedPackage.id} ref={paypalRef} style={{ width: '100%', height: '50px' }} />
  )
}
