import React from 'react'
import styled from 'styled-components'
import { theme } from 'styles/theme'
import { useDispatch } from 'store'
import { ActionType } from 'Reducers'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import { ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'
import { shouldAttemptToCloseInAppBrowser } from 'Lib/AppUtils'
import { MANAGE_SUBSCRIPTION_STEPS_BY_ID } from 'RouteNames'
import { useHistory } from 'react-router-dom'

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xLarge};
  align-items: center;
  text-align: center;
  margin: auto;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
`

const TextBold = styled.h1<{ color?: string }>`
  font-size: ${props => props.theme.fontSizes.large};
  line-height: 32px;
  font-weight: 700;
  width: 100%;
  text-align: start;
  color: ${(props) => props.color ?? theme.colors.text};
`

const Paragraph = styled.p`
  text-align: left;
`

const SubscriptionRestorationSuccess: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const history = useHistory()

  const handleSubmit = async () => {
    if (shouldAttemptToCloseInAppBrowser()) {
      dispatch({ type: ActionType.CLOSE_IN_APP_BROWSER })
    } else {
      history.push(MANAGE_SUBSCRIPTION_STEPS_BY_ID.subscription_status)
    }
  }

  return (
    <Container>
      <TextBold>
        {t(`${ManageSubscriptionStepId.SubscriptionRestorationSuccess}.title`)}
      </TextBold>

      <Paragraph>
        {t(`${ManageSubscriptionStepId.SubscriptionRestorationSuccess}.body`)}
      </Paragraph>

      <Button margin='20px 0 0 0' onClick={handleSubmit}>
        {t('gotIt')}
      </Button>
    </Container>
  )
}

export default SubscriptionRestorationSuccess
