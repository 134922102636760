import { produce } from 'immer'
import { BodyFastAction } from '.'
import { ActionType } from './ActionTypes'

export interface StartupState {
  isAppStarted: boolean
  isFirebaseInitialized: boolean
  isAuthInitialized: boolean
  isStripeInitialized: boolean
  isSentryInitialized: boolean
}

export interface IFirebaseInitializedAction { type: ActionType.FIREBASE_INITIALIZED }
export interface IAuthInitializedAction { type: ActionType.AUTH_INITIALIZED }
export interface IStripeInitializedAction { type: ActionType.STRIPE_INITIALIZED }
export interface IStartUpSuccessAction { type: ActionType.START_UP_SUCCESS }
export interface ILoadingFinishedAction { type: ActionType.LOADING_FINISHED }

export type StartupAction =
  | IFirebaseInitializedAction
  | IAuthInitializedAction
  | IStripeInitializedAction
  | IStartUpSuccessAction
  | ILoadingFinishedAction

const INITIAL_STATE: StartupState = {
  isAppStarted: false,
  isFirebaseInitialized: false,
  isAuthInitialized: false,
  isStripeInitialized: false,
  isSentryInitialized: false
}

export const StartupReducer = (state: StartupState = INITIAL_STATE, action: BodyFastAction): StartupState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionType.FIREBASE_INITIALIZED:
        draft.isFirebaseInitialized = true
        break

      case ActionType.AUTH_INITIALIZED: {
        draft.isAuthInitialized = true
        break
      }

      case ActionType.STRIPE_INITIALIZED: {
        draft.isStripeInitialized = true
        break
      }

      case ActionType.START_UP_SUCCESS:
        draft.isAppStarted = true
        break

      case ActionType.LOADING_FINISHED:
        // On the / and /loading paths, we are not showing the splash screen. We are triggering this event just in case loading finishes before startup success.
        // See https://bodyfastworkspace.slack.com/archives/CDAJ9JY6T/p1703249402726239?thread_ts=1703244537.988859&cid=CDAJ9JY6T
        // Also see https://bodyfastworkspace.slack.com/archives/C04J5V1PN8G/p1703246673066269
        draft.isAppStarted = true
        break

      default:
        break
    }
  })
}
