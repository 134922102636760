import Button from 'Components/Button'
import IconInput from 'Components/IconInput'
import { isValidEmail, validateEmail, validatePassword } from 'Lib'
import { ActionType } from 'Reducers'
import { RouteNames } from 'RouteNames'
import React, { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaLock, FaUser } from 'react-icons/fa'
import { useDispatch } from 'store'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import ResetPasswordModal from './ResetPassword'
import { showToast } from 'Components/Toast'

const Form = styled.form`
  border-radius: ${({ theme }) => theme.spacing.xLarge};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  width: 100%;
`

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.medium};
  flex-direction: column;
  width: 100%;
`

const FormLabel = styled.label`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`

const Error = styled.span`
  color: red;
  margin-left: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSizes.small};
`

const ResetPasswordText = styled.p`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.primary};
  padding-top: ${({ theme }) => theme.spacing.zero};
`

const RegisterTextWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding-top: ${({ theme }) => theme.spacing.medium};
  padding-bottom: ${({ theme }) => theme.spacing.medium};
  text-align: center;
`
const RegisterLink = styled.a`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.colors.primary};
`

interface FormProps {
  handleLoginWithEmailAndPassword: (formData: { email: string, password: string }) => void
  email?: string
}

const LoginWithEmailAndPassword: React.FC<FormProps> = ({ handleLoginWithEmailAndPassword, email }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('')
  const [emailBlurred, setEmailBlurred] = useState(false)
  const [passwordBlurred, setPasswordBlurred] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [showResetPasswordModal, setShowResetPasswordModal] = useState<boolean>()
  const isOnboardingFlow = window.location.pathname.includes('onboarding')

  const [formData, setFormData] = useState({
    email: email ?? '',
    password: ''
  })

  const handleChange = (event: any) => {
    setSubmitted(false)

    const value = event.target.value.trim()
    const name = event.target.name

    setFormData({ ...formData, [name]: value })

    if (name === 'email') {
      const emailError = validateEmail(value)
      setEmailErrorMessage(emailError ?? '')
    } else if (name === 'password') {
      const passwordError = validatePassword(value)
      setPasswordErrorMessage(passwordError ?? '')
    }
  }

  const formHasError = () => {
    return emailErrorMessage || passwordErrorMessage
  }

  const handleSubmit = async (event: FormEvent<Element>) => {
    setSubmitted(true)
    event.preventDefault()
    let hasError = false
    if (!formData.email || formData.email === '') {
      const error = t('errors.emailRequired')
      setEmailErrorMessage(error)
      hasError = true
    }
    if (!formData.password || formData.password === '') {
      const error = t('errors.passwordRequired')
      setPasswordErrorMessage(error)
      hasError = true
    }

    if (!hasError && !formHasError()) {
      handleLoginWithEmailAndPassword(formData)
    } else {
      return false
    }
  }

  const handleRegisterPage = () => {
    // Are we sure we want to navigate to the registration page?
    logEvent('web_signup_button_pressed')
    history.push(RouteNames.LOGIN_CREATE_ACCOUNT)
  }

  useEffect(() => {
    if (showResetPasswordModal === undefined) return

    logEvent(
      showResetPasswordModal
        ? 'web_openResetPasswordModal'
        : 'web_closeResetPasswordModal'
    )
  }, [showResetPasswordModal])

  const shouldShowEmailError = emailBlurred || submitted
  const shouldShowPasswordError = passwordBlurred || submitted

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormRow>
          <FormLabel>{t('forms.email')}</FormLabel>
          <IconInput
            icon={<FaUser />}
            name='email'
            value={formData.email}
            onChange={handleChange}
            placeholder={t('forms.email')!}
            onBlur={() => setEmailBlurred(true)}
            onFocus={() => setEmailBlurred(false)}
            hasError={shouldShowEmailError && !!emailErrorMessage}
            isInputValid={isValidEmail(formData.email)}
          />
          {shouldShowEmailError && emailErrorMessage && <Error>{emailErrorMessage}</Error>}
        </FormRow>
        <FormRow>
          <FormLabel>{t('forms.password')}</FormLabel>
          <IconInput
            icon={<FaLock />}
            type="password"
            name='password'
            placeholder={t('forms.password')!}
            value={formData.password}
            onChange={handleChange}
            onBlur={() => setPasswordBlurred(true)}
            onFocus={() => setPasswordBlurred(false)}
            hasError={shouldShowPasswordError && !!passwordErrorMessage}
            isInputValid={!validatePassword(formData.password)}
          />
          {shouldShowPasswordError && passwordErrorMessage && <Error>{passwordErrorMessage}</Error>}
        </FormRow>
        <ResetPasswordText onClick={() => setShowResetPasswordModal(true)}>
          {t('forms.resetPassword')}
        </ResetPasswordText>
        <Button
          type='submit'
          margin='10px 0 0 0'
          onClick={handleSubmit}>
          {(t('loginScreen.nextButtonLabel'))}
        </Button>
      </Form>
      {/*
      we don't want to show the register link on the onboarding flow
       */}
      {!isOnboardingFlow && <RegisterTextWrapper>
        {t('noAccountQuestion')}
        <RegisterLink onClick={handleRegisterPage}>
          {t('loginScreen.registerNow')}
        </RegisterLink>
      </RegisterTextWrapper>}
      {
        showResetPasswordModal &&
        <ResetPasswordModal
          closeModal={() => setShowResetPasswordModal(false)}
          sendResetPasswordEmail={(email: string) => {
            dispatch({
              type: ActionType.RESET_PASSWORD_REQUEST,
              email,
              onError: (errorMessage: string) => {
                showToast('error', errorMessage)
              },
              onSuccess: () => {
                showToast('success', t('resetPasswordModal.successMessage'))
              }
            })
          }}
          email={formData.email}
        />
      }
    </>
  )
}

export default LoginWithEmailAndPassword
