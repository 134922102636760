import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const LoadingBarWrapper = styled.div`
  position: relative;
  height: 30px;
  width: 100%;
  border: 2px solid #b6d7ce;
  background-color: #b6d7ce;
  border-radius: 5px;
`

const Bar = styled.div<{ percentage: number, backgroundColor?: string }>`
  position: absolute;
  height: 100%;
  width: ${props => props.percentage}%;
  background-color: ${props => props.backgroundColor ?? props.theme.colors.primary};
  border-radius: 5px;
  transition: width 0.5s ease-out;
`

const Percentage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`

interface InitialLoadingProps {
  onCompleted: () => void
  duration?: number // in ms
  backgroundColor?: string
}

const LoadingBar: React.FC<InitialLoadingProps> = ({ onCompleted, duration = 5000, backgroundColor }) => {
  const [percentage, setPercentage] = useState(0)

  if (__DEV__) {
    duration = duration * 0.2
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setPercentage((prevPercentage) => {
        let coefficient = (100 - prevPercentage) / (Math.random() * 100 + 1)

        if (coefficient > 5) {
          coefficient = 5
        }

        const nextPercentage = prevPercentage + coefficient
        return (nextPercentage < 100 ? nextPercentage : 100)
      })
    }, (duration) / 100)
    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    if (Math.round(percentage) === 100) {
      onCompleted()
    }
  }, [percentage])

  return (
    <LoadingBarWrapper>
      <Bar percentage={percentage} backgroundColor={backgroundColor} />
      <Percentage>{`${Math.round(percentage)}%`}</Percentage>
    </LoadingBarWrapper>
  )
}

export default LoadingBar
