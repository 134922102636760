import styled from 'styled-components'

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.fontSizes.large};
  height: ${({ theme }) => theme.fontSizes.large};
  border-radius: 50%;
  background-color: #e8eff9;
  color: ${({ theme }) => theme.colors.text};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`

interface AvatarProps {
  fullName: string
}

const Avatar: React.FC<AvatarProps> = ({ fullName }) => {
  const initials = fullName
    .split(' ')
    .slice(0, 2)
    .map((namePart) => namePart.charAt(0))
    .join('')

  return <AvatarWrapper>{initials.toUpperCase()}</AvatarWrapper>
}

export default Avatar
