import { options, title } from 'Containers/steps/Consts'
import { t } from 'i18next'
import { ManageSubscriptionStep, ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'

export enum CancellationReasonUndecidedOption {
  LearnMore = 'learnMore',
  CancelAnyway = 'cancelAnyway'
}

export function getCancellationReasonUndecidedStep(): ManageSubscriptionStep {
  return {
    id: ManageSubscriptionStepId.CancellationReasonUndecided,
    type: 'single-select',
    wordings: {
      title: t(`${ManageSubscriptionStepId.CancellationReasonUndecided}.${title}`)
    },
    options: [
      {
        id: CancellationReasonUndecidedOption.LearnMore,
        label: t(`${ManageSubscriptionStepId.CancellationReasonUndecided}.${options}.${CancellationReasonUndecidedOption.LearnMore}`)
      },
      {
        id: CancellationReasonUndecidedOption.CancelAnyway,
        label: t(`${ManageSubscriptionStepId.CancellationReasonUndecided}.${options}.${CancellationReasonUndecidedOption.CancelAnyway}`)
      }
    ]
  }
}
