import { useStripe } from '@stripe/react-stripe-js'
import { useAppState } from 'AppContextProvider'
import { ActionType, Selectors } from 'Reducers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaCreditCard } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useDispatch } from 'store'
import { BeatLoader } from 'react-spinners'
import styled from 'styled-components'
import { CustomPackage } from 'types/onboardingTypes'
import invariant from 'invariant'

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SubmitButton = styled.button`
  background-color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.medium};
  color: #fff;
  border: none;
  height: 50px;
  border-radius: ${props => props.theme.spacing.xsmall};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.primaryDark};
    border: 1px solid ${({ theme }) => theme.colors.primaryDark};
  }
`
const Icon = styled(FaCreditCard)`
  width: ${props => props.theme.spacing.medium};
  height: ${props => props.theme.spacing.medium};
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.spacing.xsmall};
`

interface CreditCardButtonProps {
  onError: (err: any) => void
  selectedPackage: CustomPackage
}

const CreditCardButton: React.FC<CreditCardButtonProps> = ({ selectedPackage, onError }) => {
  const isAppBlocked = useSelector(Selectors.getAppBlocked)
  const stripe = useStripe()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { appState } = useAppState()

  const packages = appState.stripePrices
  invariant(packages, 'Stripe prices are not loaded')

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    dispatch({
      type: ActionType.CREATE_STRIPE_SESSION_REQUEST,
      packages,
      selectedPackage,
      onSuccess: (sessionId: string) => {
        void stripe?.redirectToCheckout({ sessionId: sessionId })
      },
      onError: (error: string) => {
        onError(error)
      }
    })
  }

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <SubmitButton disabled={isAppBlocked} type="submit">
        {isAppBlocked ? <BeatLoader size={8} color="#ffffff" />
          : <ButtonWrapper>
            <Icon />
            {t('modal.creditCardButton')}
          </ButtonWrapper>
        }
      </SubmitButton>
    </FormWrapper>
  )
}

export default CreditCardButton
