import { getUserLanguage } from 'Lib'
import { mapScientificMethodImage } from 'Lib/ImageUtils'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: bold;
  text-align: center;
`

const Subtitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.colors.lightText};
`

const HighlightedTitlePart = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  align-self: center;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  height: 400px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: 250px;
    width: auto;
  }
`

const Row = styled.div`
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.medium};
`

const YourWeight = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: bold;
`

const StrictDiet = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
`

const ReinforcementScientificMethod: React.FC = () => {
  const { t } = useTranslation()
  const lang = getUserLanguage()

  const normalTitlePart1 = t('reinforcement-scientific-method.normalTitlePart1')
  const normalTitlePart2 = t('reinforcement-scientific-method.normalTitlePart2')
  const highlightedTitlePart = t('reinforcement-scientific-method.highlightedTitlePart')

  const normalSubtitlePart1 = t('reinforcement-scientific-method.normalSubtitlePart1')
  const normalSubtitlePart2 = t('reinforcement-scientific-method.normalSubtitlePart2')
  const highlightedSubtitle = t('reinforcement-scientific-method.highlightedSubtitle')

  const image = mapScientificMethodImage(lang)

  return (
    <Center>
      <Title>
        {normalTitlePart1} <HighlightedTitlePart>{highlightedTitlePart}</HighlightedTitlePart> {normalTitlePart2}
      </Title>
      <Image src={image} />
      <Subtitle>
        {normalSubtitlePart1} <HighlightedTitlePart>{highlightedSubtitle}</HighlightedTitlePart> {normalSubtitlePart2}
      </Subtitle>
    </Center>
  )
}

export default ReinforcementScientificMethod
