import * as Sentry from '@sentry/react'
import { getEnvironment } from 'Lib/AppUtils' // don't import from Lib. It will take longer to import. We are tying to reduce sentry initialization time.

const environment = getEnvironment()

Sentry.init({
  dsn: 'https://e83ee7692b444b40848cf4778b49155c@o569702.ingest.sentry.io/5715779',
  environment,
  release: process.env.REACT_APP_VERSION
})
