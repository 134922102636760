import React from 'react'
import styled from 'styled-components'
import ProgressBar from './ProgressBar'
import Step from './Step'

const StepIndicatorWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
`

const ProgressBarWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 90%;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  z-index: -1;
`

interface StepProgressProps {
  steps: Array<{ title: string, completed: boolean }>
  currentStep: number
}

const StepProgressIndicator: React.FC<StepProgressProps> = ({
  steps,
  currentStep
}) => {
  return (
    <StepIndicatorWrapper>
      <ProgressBarWrapper>
        <ProgressBar progress={(100 / (steps.length - 1)) * (currentStep - 1)} />
      </ProgressBarWrapper>
      {steps.map((step, index) => (
        <Step
          key={index}
          stepNumber={index + 1}
          title={step.title}
          completed={index < currentStep - 1}
          currentStep={currentStep}
        >
          {step.title}
        </Step>
      ))}
    </StepIndicatorWrapper>
  )
}

export default StepProgressIndicator
