import { onboarding } from 'Containers/steps/Consts'
import { getUserLanguage } from 'Lib'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ChartWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: ${props => props.theme.maxWidth};
  height: 100px;
`

const GradientDiv = styled.div`
  position: absolute;
  margin: 0 ${({ theme }) => theme.spacing.medium};
  margin-top: 50px;
  height: 10px;
  width: calc(100% - 30px);
  background: linear-gradient(to right, #dfd261 5%, #9fce65 30%, #dfd261 50%, #dfd261 60%, #d73224 100%);
  border-radius: 10px;
  z-index: 1;
`

const BmiLabels = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: ${({ theme }) => theme.colors.lightText};
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-left: ${({ theme }) => theme.spacing.medium};
  margin-right: ${({ theme }) => theme.spacing.medium};
  margin-top: 70px;
  z-index: 2;
`

const SvgWrapper = styled.svg`
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 3;
`

interface BmiChartProps {
  bmi: number
}

const MIN_BMI = 10
const MAX_BMI = 40

const BmiChart: React.FC<BmiChartProps> = ({ bmi }) => {
  const { t } = useTranslation(onboarding)
  const lang = getUserLanguage()
  const [textWidth, setTextWidth] = useState(0)
  const [svgWidth, setSvgWidth] = useState(0)

  const roundedBmi = Math.round(bmi * 10) / 10
  const formattedBmi = roundedBmi.toLocaleString(lang)

  const barWidthPercentage = 85
  const cxPercentage = useMemo(() => {
    const percentage = ((bmi - MIN_BMI) / (MAX_BMI - MIN_BMI)) * barWidthPercentage
    return percentage < 5 ? 5 : percentage > 90 ? 90 : percentage
  }, [bmi])

  const dotColor = useMemo(() => {
    const ratio = (bmi - MIN_BMI) / (MAX_BMI - MIN_BMI)

    if (ratio < 0.2) {
      return '#ced172'
    }
    if (ratio < 0.5) {
      return '#9fce65'
    }
    if (ratio < 0.65) {
      return '#ddd372'
    }
    if (ratio < 0.85) {
      return '#d49b59'
    }
    return '#d73224'
  }, [bmi])

  // we need to wait for the DOM to be rendered to get the width of the elements
  // otherwise the width will be wrong
  // see https://stackoverflow.com/a/34999925/6549174
  setTimeout(() => window.requestAnimationFrame(function () {
    // svg does not support calc(x, y) functions. we need the numeric values to proper position the elements
    setTextWidth(document.querySelector('#bmi-text')?.getBoundingClientRect().width ?? 1)
    setSvgWidth(document.querySelector('#bmi-svg')?.getBoundingClientRect().width ?? 1)
  }))

  return (
    <ChartWrapper>
      <GradientDiv></GradientDiv>
      <SvgWrapper width="100%" height="100%" id='bmi-svg'>
        <svg width="100%" height="100%">
          <circle
            cx={`${cxPercentage}%`}
            cy={56}
            r={10}
            stroke={dotColor}
            strokeWidth={2}
            fill="white"
          />
          <circle
            cx={`${cxPercentage}%`}
            cy={56}
            r={6}
            stroke="none"
            fill={dotColor}
          />
          <g>
            <rect
              x={`${Math.max(svgWidth * (cxPercentage / 100) - (textWidth / 2) - 10, 0)}`}
              y={11}
              width={textWidth + 20}
              height={30}
              fill="#252d48"
              stroke="none"
              rx={6}
              ry={6}
              style={{ transform: 'translate: (-50%, -50%)' }}
            />
            <text
              id='bmi-text'
              x={`${Math.max(svgWidth * (cxPercentage / 100), textWidth / 2 + 10)}`}
              y={26}
              fill="white"
              fontSize={12}
              textAnchor='middle'
              dominantBaseline="middle"
            >
              {t('bmi.yourBmi', { bmi: formattedBmi })}
            </text>
          </g>
        </svg>
      </SvgWrapper>
      <BmiLabels>
        <p>{t('bmi.levels.normal')}</p>
        <p>{t('bmi.levels.overweight')}</p>
        <p>{t('bmi.levels.obese')}</p>
      </BmiLabels>
    </ChartWrapper>
  )
}

export default BmiChart
