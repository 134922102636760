import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'
import { title } from './Consts'

export function getEmail(t: TFunction): OnboardingStep {
  return {
    id: 'email',
    type: 'email',
    wordings: {
      title: t(`email.${title}`),
      placeholder: t(`email.${title}`)
    }
  }
}
