import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { theme } from 'styles/theme'

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Loading: React.FC = () => {
  return (
    <Center>
      <ClipLoader
        color={theme.colors.primary}
        size={40}
        speedMultiplier={1}
      />
    </Center>
  )
}

export default Loading
