import { ActionType, Selectors } from 'Reducers'
import { IAuthStateChangedAction, ISignInWithCustomTokenAction } from 'Reducers/LoginRedux'
import { waitForLogin, waitForStartup } from 'Sagas/HelperSagas'
import { ApiService, IFirebaseService } from 'Services'
import { firebaseServicePromise } from 'store'
import { all, call, put, race, select, take, takeLatest } from 'typed-redux-saga'
import * as MoEngageService from 'Services/MoEngage.service'

function * signInWithCustomTokenSaga({ ctoken, onSuccess, onError }: ISignInWithCustomTokenAction) {
  yield call(logEvent, 'web_customTokenSignInRequest')

  const firebaseService: IFirebaseService = yield firebaseServicePromise
  const isLoggedIn: ReturnType<typeof Selectors.isLoggedIn> = yield select(Selectors.isLoggedIn)

  // log out current user to avoid funny errors and broken states
  if (isLoggedIn) {
    yield put({ type: ActionType.LOG_OUT_REQUEST })

    // if something goes wrong with the logout we log it
    // but the user still can perform a login
    yield race({
      logoutSuccess: take(ActionType.LOGOUT_SUCCESS),
      logoutError: take(ActionType.LOGOUT_FAIL)
    })
  }

  try {
    yield call(firebaseService.signInWithToken, ctoken)
  } catch (error) {
    yield call(logEvent, 'web_customTokenSignInFail', error)
    yield call(onError, error)
    return
  }

  // Wait until user callback from firebase and we have the user in our store
  yield take(ActionType.UPDATE_USER_SUCCESS)

  yield call(onSuccess)

  yield call(logEvent, 'web_customTokenSignInSuccess')
}

function * handleAuhStateChangeSaga({ authData }: IAuthStateChangedAction) {
  if (authData) {
    yield call(finalizeLoginSaga)
  } else {
    yield call(finalizeLogoutSaga)
  }
}

export function * finalizeLoginSaga () {
  // new firebase user ref becomes active and sets user
  // const action : UpdateUserSuccessAction = yield take(LoginTypes.UPDATE_USER_SUCCESS)
  // const { newUserCreated } = action

  yield * call(waitForStartup)

  // if (newUserCreated) {
  //   // yield * put(SettingsActions.updateSetting('onBoardingInProgress', true))
  //   yield * put(OnboardingActions.startOnboarding())
  //   yield * put(LoginActions.updateUserChangesetRequest({ onboardingActive: true }))
  // } else if ((yield * select(Selectors.isOnboardingInProgress))) {
  //   // If a user is using 2 devices, ActiveOnboardingStep can be null when user.onboardingActive is true for that user.
  //   // If this happens, we should direct that users to the intro screen.
  //   const activeOnboardingStep = (yield * select(Selectors.getActiveOnboardingStep)) ?? 'intro'
  //   yield * put(OnboardingActions.goToOnboardingStep(nullthrows(activeOnboardingStep)))
  // } else if (yield * select(Selectors.shouldShowPostPurchaseScreen)) {
  //   yield * put(NavigationActions.navigate({ routeName: RouteNames.Main }))
  //   yield * put(NavigationActions.navigate({ routeName: RouteNames.ThankYouScreen }))
  // } else {
  //   yield * put(NavigationActions.navigate({ routeName: RouteNames.Main }))
  // }

  const firebaseService: IFirebaseService = yield firebaseServicePromise
  const uid = firebaseService.getUserID()
  yield call(MoEngageService.login, uid)

  yield put({ type: ActionType.LOGIN_SUCCESS })
}

export function * finalizeLogoutSaga () {
  yield * call(waitForStartup)
  yield put({ type: ActionType.LOGOUT_SUCCESS })

  // yield * put(NavigationActions.navigate({ routeName: RouteNames.welcomeMain }))
}

export function * fetchSubscriptionDetailsSaga () {
  yield call(waitForLogin)

  try {
    const { subscription }: Awaited<ReturnType<typeof ApiService.fetchSubscription>> = yield call(ApiService.fetchSubscription)
    yield put({ type: ActionType.SET_SUBSCRIPTION_DETAILS, subscriptionDetails: subscription })
  } catch (error) {
    logError('web_fetchSubscriptionError', error)
  }
}

export function * LoginSagas() {
  yield all([
    takeLatest(ActionType.SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST, signInWithCustomTokenSaga),
    takeLatest(ActionType.AUTH_STATE_CHANGED, handleAuhStateChangeSaga),
    takeLatest(ActionType.FETCH_SUBSCRIPTION_DETAILS, fetchSubscriptionDetailsSaga)
  ])
}
