import Loading from 'Components/Loading.component'
import { ActionType } from 'Reducers'
import { useEffect } from 'react'
import { useDispatch } from 'store'

const PaymentSuccessContainer: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: ActionType.RESOLVE_PURCHASE })
  }, [])

  return (
    <Loading />
  )
}

export default PaymentSuccessContainer
