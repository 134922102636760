import Logo from 'Components/Logo'
import MarkdownWrapper from 'Components/MarkdownWrapper'
import { getUserLanguage } from 'Lib'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Center = styled.div`
  display: flex;
  justify-content: center;
`
const MaxWidth = styled.div`
  padding: ${({ theme }) => theme.spacing.xLarge};
`

const TermsOfUseContainer: React.FC = () => {
  const language = getUserLanguage()
  const filePath = `assets/legal/TermsOfUse.${language}.md`
  const { t } = useTranslation()

  return (
    <Center>
      <MaxWidth>
        <Logo/>
        <MarkdownWrapper filePath = {filePath} />
      </MaxWidth>
    </Center>
  )
}

export default TermsOfUseContainer
