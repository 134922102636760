import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  align-items: flex-start;
  margin: ${({ theme }) => theme.spacing.medium};
  background-color: ${({ theme }) => theme.colors.secondary}99;
  border-radius: ${({ theme }) => theme.spacing.small};
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Icon = styled.span`
  font-size: 48px;
  margin-right: ${({ theme }) => theme.spacing.small};
  margin-left: ${({ theme }) => theme.spacing.medium};
`
const TextsContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.medium};
  padding-left: ${({ theme }) => theme.spacing.medium};
  font-weight: bold;
`

const ActiveSubscriptionDisclaimer: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Icon>🚀</Icon>
      <TextsContainer >
        <div>
          {t('alreadyPremiumDownload.disclaimer')}
        </div>

      </TextsContainer>
    </Container>
  )
}

export default ActiveSubscriptionDisclaimer
