import toast, { ToastOptions, Toaster } from 'react-hot-toast'

type ToastType = 'success' | 'info' | 'error'

const toastTypeMap = {
  success: toast.success,
  info: toast,
  error: (message: string, options: ToastOptions) => toast.error(message, { ...options, icon: '⚠️' })
}

type ShowToast = (type: ToastType, message: string, opts?: ToastOptions) => void

const showToast: ShowToast = (type, message, opts) => {
  const toastFn = toastTypeMap[type]
  toastFn(message, { duration: 5000, ...opts })
}

export { showToast, Toaster as ToastProvider }
