export enum ManageSubscriptionStepId {
  Login = 'login',
  Intro = 'intro',
  SubscriptionStatus = 'subscription_status',
  HaveQuestions = 'have_questions',
  CancellationReason = 'cancellation_reason',
  PauseOffer = 'pause_offer',
  PauseSubscription = 'pause_subscription',
  PauseSubscriptionSuccess = 'pause_subscription_success',
  UnpauseSubscriptionSuccess = 'unpause_subscription_success',
  CancellationReasonExpensive = 'cancellation_reason_expensive',
  CancellationReasonUndecided = 'cancellation_reason_undecided',
  CancellationReasonAppComplicated = 'cancellation_reason_app_complicated',
  CancellationReasonAppNotHelping = 'cancellation_reason_app_not_helping',
  CancellationReasonGoalAchieved = 'cancellation_reason_goal_achieved',
  CancellationReasonFoundBetterAlternative = 'cancellation_reason_found_better_alternative',
  ContactUsAboutExperience = 'contact_us_about_experience',
  SpecialOffer = 'special_offer',
  SpecialOfferAccepted = 'special_offer_accepted',
  CancelSubscriptionSuccess = 'cancel_subscription_success',
  SubscriptionRestorationSuccess = 'subscription_restoration_success'
}

export interface SingleSelectOption {
  id: string
  label: string
  maleImage?: string
  femaleImage?: string
  emoji?: string
  buttonType?: 'card' | 'clear'
}

export interface MultipleSelectOption {
  id: string
  label: string
  subtitle?: string
  maleImage?: string
  femaleImage?: string
  emoji?: string
  buttonType?: 'card' | 'clear'
}

export interface ManageSubscriptionStep {
  id: ManageSubscriptionStepId
  type: 'single-select' | 'multiple-select' | 'custom'
  allowNext?: boolean
  allowSkip?: boolean
  stickyButton?: boolean
  options?: Array<SingleSelectOption | MultipleSelectOption>
  wordings: {
    title: string
    subtitle?: string | null
    placeholder?: string | null
    info?: {
      title: string
      content: string
      color?: string
      icon?: any
    }
  }
}
