import { images } from 'images'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`
const BurgerImage = styled.img`
  width: 100%;
  @media (max-height: 760px) {
    width: 80%;
  }
  max-width: 400px;
  opacity: 0.3;
  margin-top: -15%;
`

const SplashScreen: React.FC = () => {
  return (
    <Container>
      <BurgerImage src={images.LogoBlack} />
    </Container>
  )
}

export default SplashScreen
