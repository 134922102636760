import SocialLogin from 'Components/Login/SocialLogin'
import StepProgressIndicator from 'Components/UpgradeAccount/StepProgressionIndicator'
import { checkIfIsEmbeddedBrowser } from 'Lib'
import { Selectors, ActionType } from 'Reducers'
import OrLineDivider from 'Components/OrLineDivider'
import NextStep from 'Components/UpgradeAccount/NextSteps'
import { RouteNames } from 'RouteNames'
import 'firebase/auth'
import { useEffect, useState } from 'react'
// import { useGoogleOneTapLogin } from 'react-google-one-tap-login'
import { FirebaseError } from '@firebase/util'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useDispatch, firebaseServicePromise } from 'store'
import styled from 'styled-components'
import RegistrationForm from 'Components/RegistrationForm'
import { getDownloadUrl } from 'Lib/EventsUtils'
import { isPurchaseAvailable } from 'Lib/StripeUtils'
import { showToast } from 'Components/Toast'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  max-width: 400px;
  padding: ${({ theme }) => theme.spacing.small};
  margin: auto;
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: 500;
  text-align: left;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: ${({ theme }) => theme.spacing.zero};
`
const ContentWrapper = styled.div`
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
`

// const CLIENT_ID = getGoogleClientID(getEnvironment())

const UpgradeAccountContainer: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const user = useSelector(Selectors.getUser)
  const email = useSelector(Selectors.getEmail) ?? ''

  const [loading, setLoading] = useState(!user)

  const isEmbeddedBrowser = checkIfIsEmbeddedBrowser()

  useEffect(() => {
    return history.listen((_location, action) => {
      if (action === 'POP') {
        history.push(RouteNames.UPGRADE_ACCOUNT)
        return false
      }
    })
  }, [history])

  useEffect(() => {
    if (user) {
      setLoading(false)
    }
  }, [user])

  const navigateToDownload = () => {
    const downloadUrl = getDownloadUrl()
    history.push(downloadUrl)
  }

  const steps = isPurchaseAvailable() ? [
    { title: t('steps.purchase'), completed: true },
    { title: t('steps.register'), completed: false },
    { title: t('steps.download'), completed: false },
    { title: t('steps.login'), completed: false }
  ] : [
    { title: t('steps.register'), completed: false },
    { title: t('steps.download'), completed: false },
    { title: t('steps.login'), completed: false }
  ]

  const handleNextStep = async (formData: any) => {
    const firebase = await firebaseServicePromise
    const { email, password, firstName } = formData

    try {
      await firebase.linkUserWithEmailAndPassword(email, password)
      await firebase.updateUserName(firstName)
    } catch (error) {
      handleFirebaseError(error)
      return
    }

    onLoginSuccess()
  }

  const onLoginSuccess = () => {
    logEvent('web_signUpSuccess')
    navigateToDownload()
  }

  const handleFirebaseError = (error: FirebaseError) => {
    logEvent('web_signUpFail', { error: error.code })

    const errorMessage = t(`FirebaseError.${error.code}`, { ns: 'translation', defaultValue: t('FirebaseError.auth/unknown', { ns: 'translation' }) })
    showToast('error', errorMessage)
  }

  const handleRegisterWithGooglePress = () => {
    dispatch({
      type: ActionType.UPGRADE_USER_WITH_GOOGLE_REQUEST,
      onSuccess: onLoginSuccess,
      onError: handleFirebaseError
    })
  }

  const handleRegisterWithApplePress = () => {
    dispatch({
      type: ActionType.UPGRADE_USER_WITH_APPLE_REQUEST,
      onSuccess: onLoginSuccess,
      onError: handleFirebaseError
    })
  }

  return loading ? (
    <div />
  ) : (
    <Container>
      <StepProgressIndicator
        steps={steps}
        currentStep={2}
      />
      <Title>{t('title')}</Title>
      <p>{t('forms.title')}</p>
      <ContentWrapper>
        <RegistrationForm onRegisterTap={handleNextStep} email={email} />
        {!isEmbeddedBrowser && (
          <>
            <OrLineDivider />
            <SocialLogin
              onGooglePress={handleRegisterWithGooglePress}
              onApplePress={handleRegisterWithApplePress}
            />
          </>
        )}
        <NextStep />
      </ContentWrapper>
    </Container>
  )
}

export default UpgradeAccountContainer
