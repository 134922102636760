import { getUserLanguageAndCountry } from 'Lib/Localization'
import { convertToFirebaseFormat, removeUndefined } from 'Lib/SelectionsUtils'
import { getLocalId } from 'Lib/AppUtils'
import { ActionType } from 'Reducers'
import { setGrowthbookAttributes } from 'Services/GrowthBook.service'
import axios from 'axios'
import { initializeApp, FirebaseApp } from 'firebase/app'
import { Analytics, setUserId, setUserProperties, getAnalytics, setAnalyticsCollectionEnabled, logEvent as logEventAnalytics } from 'firebase/analytics'
import { Database, off, onValue, ref, update, getDatabase, DatabaseReference, connectDatabaseEmulator } from 'firebase/database'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
// import { RemoteConfig, getRemoteConfig, Value, getValue, fetchAndActivate } from 'firebase/remote-config'
import {
  Auth,
  getAuth,
  onAuthStateChanged,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  linkWithCredential,
  signInWithPopup,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signInAnonymously,
  linkWithPopup,
  useDeviceLanguage,
  AuthProvider,
  OAuthProvider,
  GoogleAuthProvider,
  EmailAuthProvider,
  UserCredential,
  User as AuthUser
} from 'firebase/auth'
import { isEqual, pickBy } from 'lodash'
import { Store } from 'redux'
import { gTagService } from 'store'
import * as Sentry from '@sentry/react'
import invariant from 'invariant'

//  __    __  .___________. __   __          _______.
// |  |  |  | |           ||  | |  |        /       |
// |  |  |  | `---|  |----`|  | |  |       |   (----`
// |  |  |  |     |  |     |  | |  |        \   \
// |  `--'  |     |  |     |  | |  `----.----)   |
//  \______/      |__|     |__| |_______|_______/

let app: FirebaseApp
let uid: string | null = null
let _userRef: DatabaseReference | null = null
let _authUser: AuthUser | null = null
let _idToken: string | null = null

let firebaseConfig = null
let analyticsInstance: Analytics | null = null
let databaseInstance: Database | null = null
// let remoteConfigInstance: RemoteConfig | null = null
let authInstance: Auth | null = null

function sanitizeFirebaseUser(firebaseUser: IFirebaseUser): IUser {
  // const fastingPlan = firebaseUser.fastingPlan == null ? firebaseUser.fastingPlan : mapFastingPlan(firebaseUser.fastingPlan)
  // const planHistory: $ReadOnlyArray<PlanHistoryData> | void = mapPlanHistory(firebaseUser.planHistory)

  return {
    ...(firebaseUser as any),
    // not used on web for now
    fastingPlan: null,
    // not used on web for now
    planHistory: null
  }
}

/**
 * Used to map auth user provider data to an internal provider data, same same but different
 *
 * The Firebase Auth User instance keeps track of every provider linked to the user.
 * https://firebase.google.com/docs/auth/users
 * @param authUser A firebase auth user that has a firebase provider data
 * @param firebaseUser Our final internal user object
 */
export default function sanitizeAuthProviderData(authUser: AuthUser, firebaseUser?: IFirebaseUser): IProviderData {
  const authProviderData = authUser.providerData?.[0]

  const sanitizedProviderData = {
    // Values should never be null, firebase db will ignore them and not respond to update requests
    // pickBy (without second param) filters out null values in the object
    ...pickBy(authProviderData),
    // firebase doesn't accept undefined. firebaseUser?.providerData?.email and authProviderData?.email gonna be undefined at the beginning because we are setting the email on a later phase
    isAnonymous: !!authUser.isAnonymous,
    emailVerified: !!authUser.emailVerified
  }

  if (authProviderData?.email || firebaseUser?.providerData?.email) {
    sanitizedProviderData.email = authProviderData?.email ?? firebaseUser?.providerData?.email
  }

  return sanitizedProviderData
}

export const initUserObject = (providerData: IProviderData, lang: string, country: string | null): IUser => (
  {
    providerData,
    created: new Date().toISOString(),
    lang,
    country,
    appVersion: 'web',
    isWebUser: true,
    rv: 5
  }
)

function determinePrimaryAuthMethod(authUser: AuthUser): IAuthMethod {
  const method: any = authUser.providerData[0]?.providerId

  if (method != null) return method
  if (authUser.isAnonymous) return 'anonymous'
  return 'undetected'
}

const DEV_MODE_FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDybeG9qwP38606GVwrWUQrA7qIQyh2GJU',
  authDomain: 'bodyfast-development.firebaseapp.com',
  databaseURL: 'https://bodyfast-development.firebaseio.com',
  projectId: 'bodyfast-development',
  storageBucket: 'bodyfast-development.appspot.com',
  messagingSenderId: '722166950474',
  appId: '1:722166950474:web:7fe159e64ce3339ef114b3',
  measurementId: 'G-1K7HPVPLWB'
}

export async function getFirebaseConfig() {
  if ((location.hostname === 'localhost' || location.hostname.includes('192.168')) && location.port !== '5000') {
    return DEV_MODE_FIREBASE_CONFIG
  }
  const res = await axios.get('/__/firebase/init.json')
  return res.data
}

export const getUid = () => {
  return uid
}

//      _______. _______ .______     ____    ____  __    ______  _______
//     /       ||   ____||   _  \    \   \  /   / |  |  /      ||   ____|
//    |   (----`|  |__   |  |_)  |    \   \/   /  |  | |  ,----'|  |__
//     \   \    |   __|  |      /      \      /   |  | |  |     |   __|
// .----)   |   |  |____ |  |\  \----.  \    /    |  | |  `----.|  |____
// |_______/    |_______|| _| `._____|   \__/     |__|  \______||_______|

export async function createFirebaseService(store: Store) {
  firebaseConfig = await getFirebaseConfig()

  app = initializeApp(firebaseConfig)

  analyticsInstance = getAnalytics(app)
  databaseInstance = getDatabase()
  // remoteConfigInstance = getRemoteConfig(app)
  authInstance = getAuth(app)

  if (__LOCAL_DEV__) {
    const functionsInstance = getFunctions(app)

    connectDatabaseEmulator(databaseInstance, 'localhost', 9000)
    connectAuthEmulator(authInstance, 'http://localhost:9099/')
    connectFunctionsEmulator(functionsInstance, 'localhost', 5001)
  }

  invariant(analyticsInstance && databaseInstance && authInstance, 'Firebase instances must be initialized')

  const handleAuthStateChanged = (authUser: AuthUser | null) => {
    invariant(analyticsInstance && databaseInstance, 'Firebase instances must be initialized')

    uid = authUser?.uid ?? null

    if (authUser) {
      let freshAuth = true
      let newUserCreated = false
      if (_userRef) {
        // "handleAuthStateChanged" is for some unknown reasons called twice.
        off(_userRef, 'value')
      }

      _userRef = ref(databaseInstance, `users/${authUser.uid}`)

      const [lang, country] = getUserLanguageAndCountry()

      setUserId(analyticsInstance, authUser.uid)
      gTagService.setParams({ user_id: authUser.uid })
      Sentry.configureScope((scope) => {
        // Don't use Sentry.setUser() because it will remove the params previously added.
        scope.setUser({
          ...scope.getUser(),
          id: authUser.uid
        })
      })

      setGrowthbookAttributes({
        id: authUser.uid,
        lang: lang ?? 'unknown',
        country: country ?? 'unknown'
      })

      if (lang) {
        setUserProperties(analyticsInstance, {
          lang,
          auth_method: determinePrimaryAuthMethod(authUser)
        }, { global: true })
      }

      // set user id into apps flyer (with optional chaining in case the SDK failed to load)
      AF?.('pba', 'setCustomerUserId', authUser.uid)

      invariant(_userRef, 'userRef must exist when creating onValue listener')

      onValue(_userRef, async (snapshot) => {
        invariant(_userRef, 'userRef must exist when creating a new user')
        invariant(analyticsInstance, 'Firebase instances must be initialized')

        const firebaseUser: IUser = snapshot.val()

        // Create a brand new user in our database (not an auth user)
        if (firebaseUser == null) {
          invariant(authUser, 'authUser must exist when creating a new user')

          logEvent('web_createNewUser', { uid: authUser.uid })
          freshAuth = false
          newUserCreated = true
          const newProviderData = sanitizeAuthProviderData(authUser)
          const userObj = initUserObject(newProviderData, lang, country)

          void update(_userRef, userObj)

          return
        }

        if (freshAuth) {
          freshAuth = false

          // There might have been some change in the our auth user since last login
          // We have replicated the providerData in our db user object, so this step is
          // necessary to keep both objects synced
          const newProviderData = sanitizeAuthProviderData(authUser, firebaseUser)
          if (!isEqual(newProviderData, firebaseUser.providerData)) {
            // update data and wait for next 'value' event
            void update(_userRef, { providerData: newProviderData })
            return
          }
        }

        const sanitizedUser = sanitizeFirebaseUser(firebaseUser)
        // wait until next tick to avoid sync clash of potential async updateUser()
        await Promise.resolve()

        store.dispatch({
          type: ActionType.UPDATE_USER_SUCCESS,
          user: sanitizedUser,
          newUserCreated
        })

        if (!store.getState().startup.isAuthInitialized) {
          store.dispatch({ type: ActionType.AUTH_INITIALIZED })
          logEvent('web_authInitialized')
        }
      })
    } else {
      if (_userRef) {
        off(_userRef, 'value')
      }

      _userRef = null
      _authUser = null
      _idToken = null
    }

    _authUser = authUser
    store.dispatch({ type: ActionType.AUTH_STATE_CHANGED, authData: authUser })

    if (!authUser && !store.getState().startup.isAuthInitialized) {
      store.dispatch({ type: ActionType.AUTH_INITIALIZED })
      logEvent('web_authInitialized')
    }
  }

  onAuthStateChanged(authInstance, handleAuthStateChanged)
  setAnalyticsCollectionEnabled(analyticsInstance, true)

  if (!app) {
    throw new Error('Could not initialize firebase app')
  }

  // getRemoteConfig().defaultConfig = REMOTE_CONFIG_DEFAULT
  // getRemoteConfig().settings = {
  //   minimumFetchIntervalMillis: getEnvironment() === 'production' ? 3600000 : 20000, // 1 hour caching, adjust as necessary
  //   fetchTimeoutMillis: 30000 // 30 seconds
  // }

  const firebaseService = {
    logEvent: (eventName: string, params?: Record<string, any>) => {
      invariant(analyticsInstance, 'logEvent - firebase instances must be initialized')

      if (eventName.length > 40) {
        if (__DEV__) {
          throw new Error('Firebase event names must be shorter than 40 characters')
        } else {
          console.error('WARNING! You are sending a firebase event with more than 40 characters, it will be truncated!')
        }
      }

      logEventAnalytics(analyticsInstance, eventName, { ...params, localId: getLocalId() })
    },
    setCurrentScreen: (path: string): void => {
      invariant(analyticsInstance, 'Firebase instances must be initialized')

      logEventAnalytics(analyticsInstance, 'screen_view', { firebase_screen: path, firebase_screen_class: path, localId: getLocalId() })
      logEventAnalytics(analyticsInstance, 'page_view', { page_path: path, page_title: path, localId: getLocalId() }) // TODO: check if we need both screen_view and page_view events
    },
    createUserWithEmailAndPassword: async (email: string, password: string) => {
      invariant(authInstance, 'Firebase instances must be initialized')

      return await createUserWithEmailAndPassword(authInstance, email, password)
    },
    createUserWithProvider: async (provider: AuthProvider): Promise<void> => {
      invariant(authInstance, 'Firebase instances must be initialized')

      const userCredential = await signInWithPopup(authInstance, provider)
    },
    updateUser: async (user: Partial<IUser>) => {
      invariant(_userRef, 'Cannot update user if not logged in')

      return await update(_userRef, user)
    },
    updateAnalyticsProperty: (key: string, value: any) => {
      invariant(authInstance, 'Firebase instances must be initialized')

      const normalizedValue = value == null ? null : String(value)

      setUserProperties(authInstance, { [key]: normalizedValue }, { global: true })
    },
    getIdToken: async () => {
      if (_authUser) {
        if (!_idToken) {
          _idToken = await _authUser.getIdToken()
        }
        return _idToken
      }

      _idToken = null

      throw new Error('Cannot generate Firebase ID token if logged out')
    },
    getUserID: () => {
      return _authUser?.uid
    },
    logout: async () => {
      invariant(authInstance, 'Firebase instances must be initialized')
      await signOut(authInstance)
    },
    linkUserWithEmailAndPassword: async (email: string, password: string): Promise<void> => {
      const currentUser = getAuth().currentUser
      invariant(currentUser, 'linkUserWithEmailAndPassword - user must be logged in')

      const credential = EmailAuthProvider.credential(email, password)
      const userCredential = await linkWithCredential(currentUser, credential)

      // When linking a new provider, the user is automatically signed in
      // so we need to manually call handleAuthStateChanged
      handleAuthStateChanged(userCredential?.user ?? null)
    },
    linkUserWithProvider: async (provider: AuthProvider): Promise<void> => {
      const currentUser = getAuth().currentUser

      invariant(currentUser, 'linkUserWithProvider - user must be logged in')
      invariant(authInstance, 'Firebase instances must be initialized')

      const userCredential = await linkWithPopup(currentUser, provider)
      handleAuthStateChanged(userCredential?.user ?? null)
    },
    loginWithEmailAndPassword: async (email: string, password: string): Promise<void> => {
      invariant(authInstance, 'Firebase instances must be initialized')

      await signInWithEmailAndPassword(authInstance, email, password)
    },
    signInWithGoogleOneTap: async (idToken: string) => {
      // Attention! Don't use this method without doing changes. We should separate it for login and registration like we are doing for e.g linkUserWithProvider and signInWithProvider
      // Use signInWithCredential for login and linkWithCredential for registration. If you use signInWithCredential when there is no anonymous user, it will silently create a second user connected to the first one.
      // See https://github.com/matttti/BodyFast/pull/4098#discussion_r1424286055
      invariant(false, 'signInWithGoogleOneTap - not implemented properly')

      // invariant(authInstance, 'Firebase instances must be initialized')

      // try {
      //   const credential = GoogleAuthProvider.credential(idToken)

      //   if (!credential) {
      //     throw new Error('Could not parse credentials')
      //   }

      //   const currentUser = getAuth().currentUser

      //   if (currentUser) {
      //     // If there's a current user, attempt to link the Google account.
      //     const userCredential = await linkWithCredential(currentUser, credential)

      //     // When linking a new provider, the user is automatically signed in.
      //     // Therefore, we need to manually call handleAuthStateChanged.
      //     handleAuthStateChanged(userCredential?.user ?? null)
      //   } else {
      //     // If there isn't a current user, attempt to sign in or create a new account with the provided credentials.
      //     await signInWithCredential(authInstance, credential)
      //   }
      // } catch (error) {
      //   if (error.code !== 'auth/credential-already-in-use') {
      //     // We are showing "Already have an account" dialog when 'auth/credential-already-in-use'
      //     // See https://github.com/matttti/BodyFast/pull/3930#discussion_r1394369723
      //     throw error
      //   }
      // }
    },
    signInWithToken: async (ctoken: string): Promise<void> => {
      invariant(authInstance, 'Firebase instances must be initialized')

      await signInWithCustomToken(authInstance, ctoken)
    },
    signInWithProvider: async (provider: any): Promise<void> => {
      invariant(authInstance, 'Firebase instances must be initialized')

      await signInWithPopup(authInstance, provider)
    },
    linkWithPopup: async (provider: OAuthProvider | GoogleAuthProvider): Promise<{
      userCredential?: UserCredential
    }> => {
      const currentUser = getAuth().currentUser
      invariant(currentUser, 'Cannot link user if not logged in')

      const userCredential = await linkWithPopup(currentUser, provider)
      // When linking a new provider, the user is automatically signed in
      // so we need to manually call handleAuthStateChanged
      handleAuthStateChanged(userCredential?.user ?? null)

      return { userCredential }
    },
    // checkSignInCredential: async (): Promise<{ isLoggedIn: boolean }> => {
    // Commented out since it's not use. If we need this in the feature, see: https://firebase.google.com/docs/web/modular-upgrade#redirect-update
    //   const result = await getRedirectResult(authInstance)
    //   if (result === null || provider.credentialFromResult(result) === null) {
    //     return { isLoggedIn: false }
    //   } else {
    //     return { isLoggedIn: true }
    //   }
    // },
    // forceUserSync: async () => {
    //   await _userRef?.get()
    // },
    anonymousSignIn: async (): Promise<{ isLoggedIn: boolean, tokenId?: string }> => {
      invariant(authInstance, 'Firebase instances must be initialized')

      const result = await signInAnonymously(authInstance)
      if (result.user) {
        const tokenId = await result.user.getIdToken()
        return { isLoggedIn: true, tokenId }
      } else {
        return { isLoggedIn: false }
      }
    },
    saveInitialPersonalData: async (data: any) => {
      invariant(databaseInstance, 'Firebase instances must be initialized')

      const userId = getAuth().currentUser?.uid
      const cleanedData = removeUndefined(data)
      const convertedData = convertToFirebaseFormat(cleanedData)
      const userPersonalRef = await ref(databaseInstance, `/users/${userId}/personal`)
      await update(userPersonalRef, convertedData)
    },
    updateUserName: async (name: string) => {
      invariant(databaseInstance, 'Firebase instances must be initialized')

      const userId = getAuth().currentUser?.uid
      const userPersonalRef = await ref(databaseInstance, `/users/${userId}/personal`)
      await update(userPersonalRef, { name })
    },
    // getRemoteConfigValue: (key: string): Value => {
    //   invariant(remoteConfigInstance, 'Firebase instances must be initialized')

    //   return getValue(remoteConfigInstance, key)
    // },
    resetPassword: async (email: string) => {
      invariant(authInstance, 'Firebase instances must be initialized')

      await sendPasswordResetEmail(authInstance, email)
    },
    // initializeRemoteConfig: async () => {
    //   invariant(remoteConfigInstance, 'Firebase instances must be initialized')

    //   await fetchAndActivate(remoteConfigInstance)
    // },
    useDeviceLanguage: () => {
      invariant(authInstance, 'Firebase instances must be initialized')

      useDeviceLanguage(authInstance)
    }
  }

  logEvent('web_firebaseInitialized')
  store.dispatch({ type: ActionType.FIREBASE_INITIALIZED })

  return firebaseService
}

// because generators do not have types, this is a utility type to get some type safety into sagas
export type IFirebaseService = Awaited<ReturnType<typeof createFirebaseService>>
