import { options, title } from 'Containers/steps/Consts'
import { t } from 'i18next'
import { ManageSubscriptionStep, ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'

export enum HaveQuestionsOption {
  ContactSupport = 'contactSupport',
  GoToFAQ = 'goToFAQ'
}

export function getHaveQuestionsStep(): ManageSubscriptionStep {
  return {
    id: ManageSubscriptionStepId.HaveQuestions,
    type: 'single-select',
    wordings: {
      title: t(`${ManageSubscriptionStepId.HaveQuestions}.${title}`)
    },
    options: [
      {
        id: HaveQuestionsOption.GoToFAQ,
        label: t(`${ManageSubscriptionStepId.HaveQuestions}.${options}.${HaveQuestionsOption.GoToFAQ}`)
      },
      {
        id: HaveQuestionsOption.ContactSupport,
        label: t(`${ManageSubscriptionStepId.HaveQuestions}.${options}.${HaveQuestionsOption.ContactSupport}`)
      },
    ]
  }
}
