import { images } from 'images'
import styled from 'styled-components'

const Image = styled.img<{
  height?: string
}>`
  margin: 0;
  display: inline;
  position: absolute;
  top: ${props => props.theme.spacing.medium};
  height: ${props => props.height ?? '20px'};
`

interface LogoProps {
  useWhite?: boolean
  height?: string
}

const Logo: React.FC<LogoProps> = ({ useWhite, height }) => {
  return (
    <Image
      height={height}
      src={useWhite ? images.LogoWhite : images.Logo}
    />
  )
}

export default Logo
