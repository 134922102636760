import { getCancellationReasonStep } from 'Containers/manageSubscriptionSteps/CancellationReason'
import { getCancellationReasonAppComplicatedStep } from 'Containers/manageSubscriptionSteps/CancellationReasonAppComplicated'
import { getCancellationReasonAppNotHelpingStep } from 'Containers/manageSubscriptionSteps/CancellationReasonAppNotHelping'
import { getCancellationReasonExpensiveStep } from 'Containers/manageSubscriptionSteps/CancellationReasonExpensive'
import { getCancellationReasonGoalAchievedStep } from 'Containers/manageSubscriptionSteps/CancellationReasonGoalAchieved'
import { getCancellationReasonUndecidedStep } from 'Containers/manageSubscriptionSteps/CancellationReasonUndecided'
import { getContactUsAboutExperienceStep } from 'Containers/manageSubscriptionSteps/ContactUsAboutExperience'
import { getHaveQuestionsStep } from 'Containers/manageSubscriptionSteps/HaveQuestions'
import { getLoginStep } from 'Containers/manageSubscriptionSteps/ManageSubscriptionLogin'
import { getPauseOfferStep } from 'Containers/manageSubscriptionSteps/PauseOffer'
import { getPauseSubscriptionStep } from 'Containers/manageSubscriptionSteps/PauseSubscription'
import { getPauseSubscriptionSuccessStep } from 'Containers/manageSubscriptionSteps/PauseSubscriptionSuccess'
import { getSpecialOfferStep } from 'Containers/manageSubscriptionSteps/SpecialOffer'
import { getSpecialOfferAcceptedStep } from 'Containers/manageSubscriptionSteps/SpecialOfferAccepted'
import { getCancelSubscriptionSuccessStep } from 'Containers/manageSubscriptionSteps/CancelSubscriptionSuccess'
import { getSubscriptionRestorationSuccessStep } from 'Containers/manageSubscriptionSteps/SubscriptionRestorationSuccess'
import { getSubscriptionStatusStep } from 'Containers/manageSubscriptionSteps/SubscriptionStatus'
import { getUnpauseSubscriptionSuccessStep } from 'Containers/manageSubscriptionSteps/UnpauseSubscriptionSuccess'
import { ManageSubscriptionStep, ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'
import { getCancellationReasonFoundBetterAlternativeStep } from 'Containers/manageSubscriptionSteps/CancellationReasonFoundBetterAlternatives'

export const getManageSubscriptionSteps = (): Partial<{ [key in ManageSubscriptionStepId]: ManageSubscriptionStep }> => ({
  [ManageSubscriptionStepId.Login]: getLoginStep(),
  [ManageSubscriptionStepId.SubscriptionStatus]: getSubscriptionStatusStep(),
  [ManageSubscriptionStepId.HaveQuestions]: getHaveQuestionsStep(),
  [ManageSubscriptionStepId.PauseOffer]: getPauseOfferStep(),
  [ManageSubscriptionStepId.CancellationReason]: getCancellationReasonStep(),
  [ManageSubscriptionStepId.PauseSubscription]: getPauseSubscriptionStep(),
  [ManageSubscriptionStepId.UnpauseSubscriptionSuccess]: getUnpauseSubscriptionSuccessStep(),
  [ManageSubscriptionStepId.PauseSubscriptionSuccess]: getPauseSubscriptionSuccessStep(),
  [ManageSubscriptionStepId.CancellationReasonExpensive]: getCancellationReasonExpensiveStep(),
  [ManageSubscriptionStepId.CancellationReasonUndecided]: getCancellationReasonUndecidedStep(),
  [ManageSubscriptionStepId.CancellationReasonAppComplicated]: getCancellationReasonAppComplicatedStep(),
  [ManageSubscriptionStepId.CancellationReasonAppNotHelping]: getCancellationReasonAppNotHelpingStep(),
  [ManageSubscriptionStepId.CancellationReasonGoalAchieved]: getCancellationReasonGoalAchievedStep(),
  [ManageSubscriptionStepId.CancellationReasonFoundBetterAlternative]: getCancellationReasonFoundBetterAlternativeStep(),
  [ManageSubscriptionStepId.ContactUsAboutExperience]: getContactUsAboutExperienceStep(),
  [ManageSubscriptionStepId.SpecialOffer]: getSpecialOfferStep(),
  [ManageSubscriptionStepId.SpecialOfferAccepted]: getSpecialOfferAcceptedStep(),
  [ManageSubscriptionStepId.CancelSubscriptionSuccess]: getCancelSubscriptionSuccessStep(),
  [ManageSubscriptionStepId.SubscriptionRestorationSuccess]: getSubscriptionRestorationSuccessStep()
})
