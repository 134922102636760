import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getDesiredBodyType(t: TFunction, gender?: IGender): OnboardingStep {
  const desiredBodyType = 'desired_body_type'

  const average = 'average'
  const lean = 'lean'
  const athletic = 'athletic'
  const ripped = 'ripped'
  const toned = 'toned'
  const curvy = 'curvy'

  if (gender === 'm') {
    return {
      id: desiredBodyType,
      type: 'single-select',
      wordings: {
        title: t(`${desiredBodyType}.${title}`)
      },
      options: [
        {
          id: average,
          label: t(`${desiredBodyType}.${options}.${average}`),
          emoji: '🧍‍♂️'
        },
        {
          id: lean,
          label: t(`${desiredBodyType}.${options}.${lean}`),
          emoji: '🏃‍♂️'
        },
        {
          id: athletic,
          label: t(`${desiredBodyType}.${options}.${athletic}`),
          emoji: '💪'
        },
        {
          id: ripped,
          label: t(`${desiredBodyType}.${options}.${ripped}`),
          emoji: '🏋️‍♂️'
        }
      ]
    }
  } else {
    return {
      id: 'desired_body_type',
      type: 'single-select',
      wordings: {
        title: t(`${desiredBodyType}.${title}`)
      },
      options: [
        {
          id: average,
          label: t(`${desiredBodyType}.${options}.${average}`),
          emoji: '🧍‍♀️'
        },
        {
          id: lean,
          label: t(`${desiredBodyType}.${options}.${lean}`),
          emoji: '🏃🏻‍♀️'
        },
        {
          id: toned,
          label: t(`${desiredBodyType}.${options}.${toned}`),
          emoji: '💪🏻'
        },
        {
          id: curvy,
          label: t(`${desiredBodyType}.${options}.${curvy}`),
          emoji: '💃🏿'
        }
      ]
    }
  }
}
