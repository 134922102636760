import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'
import { options, title } from './Consts'

export enum FastingMotivation {
  justCurious = 'just_curious',
  iWantToTry = 'i_want_to_try',
  imSerious = 'im_serious'
}

export function getFastingMotivation(t: TFunction): OnboardingStep {
  const fastingMotivation = 'fasting_motivation'

  return {
    id: fastingMotivation,
    type: 'single-select',
    wordings: {
      title: t(`${fastingMotivation}.${title}`)
    },
    options: [
      {
        id: FastingMotivation.justCurious,
        label: t(`${fastingMotivation}.${options}.${FastingMotivation.justCurious}`),
        emoji: '🙇'
      },
      {
        id: FastingMotivation.iWantToTry,
        label: t(`${fastingMotivation}.${options}.${FastingMotivation.iWantToTry}`),
        emoji: '🙌'
      },
      {
        id: FastingMotivation.imSerious,
        label: t(`${fastingMotivation}.${options}.${FastingMotivation.imSerious}`),
        emoji: '🚀'
      }
    ]
  }
}
