import React from 'react'
import { OnboardingStep } from '../types/onboardingTypes'
import AgeInput from './AgeInput'
import StepSubtitle from './StepSubtitle.component'
import StepTitle from './StepTitle.component'

interface AgeInputStepProps {
  stepConfig: OnboardingStep
  onAgeChange: (age: number | undefined) => void
  initialValue?: number
  setErrorMessage: (message: string) => void
}

const AgeInputStep: React.FC<AgeInputStepProps> = ({ stepConfig, onAgeChange, setErrorMessage, initialValue }) => {
  return (
    <div>
      <StepTitle>{stepConfig.wordings.title}</StepTitle>
      <StepSubtitle>{stepConfig.wordings.subtitle}</StepSubtitle>
      <AgeInput
        onAgeChange={onAgeChange}
        placeholder={stepConfig.wordings.placeholder ?? ''}
        setErrorMessage={setErrorMessage}
        initialValue={initialValue}
      />
    </div >
  )
}

export default AgeInputStep
