import { createAnalytics } from 'Lib'
import { BodyFastAction, IRootState, history, rootReducer } from 'Reducers'
import { rootSaga } from 'Sagas'
import { createFacebookService, createFirebaseService, createGTagService, initStripeService, createTwitterService } from 'Services'
import { routerMiddleware } from 'connected-react-router'
import { useDispatch as _useDispatch } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import { createSentryMiddleware } from 'Reducers/SentryMiddleware'
import { createAppsFlyerService } from 'Services/AppsFlyerService'
import { initializeMoEngage } from 'Services/MoEngage.service'

const REDUX_PERSIST_WHITELIST: Array<keyof IRootState> = ['payment']

const REDUX_PERSIST_CONFIG = {
  key: '__REDUX_ROOT__',
  storage,
  whitelist: REDUX_PERSIST_WHITELIST
}

const persistedReducer = persistReducer(REDUX_PERSIST_CONFIG, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const middlewares = []
middlewares.push(routerMiddleware(history))
middlewares.push(sagaMiddleware)
if (__DEV__) {
  middlewares.push(createLogger({
    collapsed: true,
    diff: true
  }))
}

const sentryMiddleware = createSentryMiddleware()
middlewares.push(sentryMiddleware)

export const store = createStore<any, BodyFastAction, any, any>(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
)
sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)
export const firebaseServicePromise = createFirebaseService(store)
export const facebookService = createFacebookService()
export const gTagService = createGTagService()
export const twitterService = createTwitterService()
// export const pinterestService = createPinterestService()
export const stripeService = initStripeService(store)
export const appsFlyerService = createAppsFlyerService()

createAnalytics(firebaseServicePromise, facebookService, gTagService, twitterService) // Pinterest service is removed temporarily.
initializeMoEngage()

export type StoreDispatch = (action: BodyFastAction) => void

export function useDispatch(): StoreDispatch {
  const dispatch = _useDispatch()
  return (action: BodyFastAction) => {
    dispatch(action)
  }
}
