import * as Sentry from '@sentry/react'
import { getCurrency } from 'Lib'
import {
  ActionType,
  Selectors
} from 'Reducers'
import { ILoadProducts } from 'Reducers/PaymentPersistingRedux'
import { waitForLogin } from 'Sagas/HelperSagas'
import { ApiService, fetchStripePrices } from 'Services'
import { setGrowthbookAttributes } from 'Services/GrowthBook.service'
import { all, call, delay, put, select, takeEvery, takeLatest } from 'typed-redux-saga'

export function * loadProductsSaga ({ groupId, isRetry = false }: ILoadProducts) {
  try {
    yield call(logEvent, 'web_fetchStripePrices', { groupId, isRetry })

    const currency: ReturnType<typeof getCurrency> = yield call(getCurrency)
    const { data } = yield call(fetchStripePrices, groupId, currency)

    if (data.countryCode) {
      localStorage.setItem('countryCode', data.countryCode)

      yield call(setGrowthbookAttributes, {
        country: data.countryCode
      })
    }

    localStorage.setItem('region', data.region ?? 'unknown')

    yield put({ type: ActionType.MERGE_PRODUCTS, groupId, prices: data.prices })
  } catch (error) {
    logEvent('web_fetchStripePricesError', { error, groupId, isRetry })
    Sentry.captureException(error)

    yield delay(3000)
    yield put({ type: ActionType.LOAD_PRODUCTS, groupId, isRetry: true })
  }
}

export function * PaymentSagas() {
  yield all([
    takeEvery(ActionType.LOAD_PRODUCTS, loadProductsSaga) // This takes every because we might want to fetch more than 1 product group at the same time
  ])
}
