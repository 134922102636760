import { Selectors } from 'Reducers'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'
import styled, { css, keyframes } from 'styled-components'
import { theme } from 'styles/theme'

type ButtonThemeTypes =
  | 'primary'
  | 'text'
  | 'outline'

interface ThemeStyles {
  backgroundColor: string
  color: string
  width?: string
  borderColor?: string
  borderWidth?: string
  border?: 'none' | 'solid'
}

interface StyledPulseButtonProps {
  margin?: string
  height: number
  maxWidth?: string
  enableAnimation: boolean
  padding?: string
  appearDisabled?: boolean
}

const pulseAnimation = (theme: any) => {
  const pulse = keyframes`
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px ${theme.colors.white};
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 ${theme.colors.white};
    }
  `

  return css`
    animation: ${pulse} 1.5s infinite;
  `
}

const PulseButton = styled.button<ThemeStyles & StyledPulseButtonProps>`
  overflow: hidden;
  background-color: ${(props) => props.backgroundColor ?? props.theme.colors.primary};
  color: ${(props) => props.color ?? 'white'};
  font-size: ${(props) => props.theme.fontSizes.medium};
  font-weight: bold;
  padding:  ${(props) => props.padding ?? '20px 40px'};
  margin: ${(props) => props.margin ?? theme.spacing.medium};
  max-width: ${(props) => props.maxWidth};
  border: ${(props) => props.border ?? 'none'};
  border-color: ${(props) => props.borderColor ?? 'none'};
  border-width: ${(props) => props.borderWidth ?? 'none'};
  border-radius:  ${({ theme }) => theme.spacing.small};
  cursor: pointer;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  pointer-events: auto;
  width: ${(props) => props.width ?? 'auto'};
  box-shadow: 0 0 0 0 ${({ theme, backgroundColor }) => backgroundColor || theme.colors.primary};
  ${(props) => props.enableAnimation && pulseAnimation(props.theme)}

  ${(props) => props.appearDisabled && `
    background-color: #ccc;
    cursor: not-allowed;
  `}

  &:hover {
    opacity: 0.8;
    transition: none;
  }
  @media(max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding:  ${(props) => props.padding && props.height > 40 ? '8px 15px' : (props.padding ?? '20px 40px')};
  }
`
const LoadingWrapper = styled.div`
  height: 25px;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex: 1;
`

interface Props {
  theme?: ButtonThemeTypes
  margin?: string
  maxWidth?: string
  onClick: any
  className?: any
  disabled?: boolean
  enablePulse?: boolean
  padding?: string
  primaryColor?: string
  type?: 'button' | 'submit' | 'reset'
}

const getThemeStyles = (buttonTheme: ButtonThemeTypes, primaryColor?: string): ThemeStyles => {
  switch (buttonTheme) {
    case 'primary':
      return {
        backgroundColor: primaryColor || theme.colors.primary,
        color: '#fff',
        width: 'inherit'
      }
    case 'outline':
      return {
        backgroundColor: 'transparent',
        color: primaryColor || theme.colors.primary,
        borderColor: primaryColor || theme.colors.primary,
        borderWidth: '2px',
        border: 'solid',
        width: 'inherit'
      }
    case 'text':
      return {
        backgroundColor: 'transparent',
        color: primaryColor || theme.colors.primary
      }
    default:
      return {
        backgroundColor: primaryColor || theme.colors.primary,
        color: '#fff'
      }
  }
}

const Button: React.FC<Props> = ({
  theme: themeProps = 'primary',
  margin,
  padding,
  maxWidth,
  onClick,
  disabled,
  enablePulse,
  children,
  primaryColor,
  type
}) => {
  const isAppBlocked = useSelector(Selectors.getAppBlocked)
  const themeStyles = getThemeStyles(themeProps, primaryColor)
  const [height, setHeight] = useState(0)
  const ref = useRef<any>(null)

  useEffect(() => {
    setHeight(ref.current?.clientHeight)
  }, [ref.current])

  return (
    <PulseButton
      ref={ref}
      margin={margin}
      padding={padding}
      height={height}
      maxWidth={maxWidth}
      disabled={disabled || isAppBlocked}
      onClick={onClick}
      appearDisabled={disabled || isAppBlocked}
      enableAnimation={enablePulse ?? false}
      type={type}
      color={themeStyles.color}
      backgroundColor={themeStyles.backgroundColor}
      width={themeStyles.width}
      border={themeStyles.border}
      borderColor={themeStyles.borderColor}
      borderWidth={themeStyles.borderWidth}
    >
      {isAppBlocked ? (<LoadingWrapper>
        <ClipLoader
          color={theme.colors.white}
          size={25}
          speedMultiplier={1}
        />
      </LoadingWrapper>)
        : (children)
      }
    </PulseButton>
  )
}

export default Button
