import * as Sentry from '@sentry/react'
import { getUserLanguageAndCountry } from 'Lib/Localization'
import { getLocalId } from 'Lib/AppUtils'
import {
  ActionType,
  Selectors
} from 'Reducers'
import { AUTH_REQUIRING_ROUTES } from 'RouteNames'

import { delay, race } from 'redux-saga/effects'
import { all, call, put, select, take } from 'typed-redux-saga'

const STARTUP_TIMEOUT = 5000

// On a slow internet connection, it can take more time to start up firebase auth. We need auth for some certain routes. Using this timeout for those routes.
// See https://bodyfastworkspace.slack.com/archives/C04J5V1PN8G/p1703174354094949
const STARTUP_TIMEOUT_LONG = 120000

// process STARTUP actions
export function * waitForStartupEvents () {
  const startupEvents = []

  // startupEvents.push(take('persist/REHYDRATE'))
  const isFirebaseInitialized: ReturnType<typeof Selectors.isFirebaseInitialized> = yield select(Selectors.isFirebaseInitialized)
  if (!isFirebaseInitialized) startupEvents.push(take(ActionType.FIREBASE_INITIALIZED))

  const isAuthInitialized: ReturnType<typeof Selectors.isAuthInitialized> = yield select(Selectors.isAuthInitialized)
  if (!isAuthInitialized) startupEvents.push(take(ActionType.AUTH_INITIALIZED))

  const isStripeInitialized: ReturnType<typeof Selectors.isStripeInitialized> = yield select(Selectors.isStripeInitialized)
  if (!isStripeInitialized) startupEvents.push(take(ActionType.STRIPE_INITIALIZED))

  yield all(startupEvents)
}

function * startUpSaga() {
  var startDate = new Date()
  yield put({ type: ActionType.START_UP })

  const { timeout } = yield race({
    startupComplete: call(waitForStartupEvents),
    timeout: delay(AUTH_REQUIRING_ROUTES.includes((location.pathname as any)) ? STARTUP_TIMEOUT_LONG : STARTUP_TIMEOUT)
  })

  if (timeout) {
    const isFirebaseInitialized: ReturnType<typeof Selectors.isFirebaseInitialized> = yield select(Selectors.isFirebaseInitialized)
    const isAuthInitialized: ReturnType<typeof Selectors.isAuthInitialized> = yield select(Selectors.isAuthInitialized)
    const isStripeInitialized: ReturnType<typeof Selectors.isStripeInitialized> = yield select(Selectors.isStripeInitialized)

    yield call(logEvent, 'web_startupTimeout', { pathname: location.pathname, isFirebaseInitialized, isAuthInitialized, isStripeInitialized })
    Sentry.captureMessage('Startup timeout')
  }

  yield put({ type: ActionType.SET_APP_UNBLOCKED })

  var endDate = new Date()
  yield call(logEvent, 'web_startUpSuccess', { duration: endDate.getTime() - startDate.getTime() })
  yield put({ type: ActionType.START_UP_SUCCESS })
}

function * setSentryUserProperties () {
  const localId: ReturnType<typeof getLocalId> = yield call(getLocalId)
  const [lang, country]: ReturnType<typeof getUserLanguageAndCountry> = yield * call(getUserLanguageAndCountry)

  Sentry.configureScope((scope) => {
    // Don't use Sentry.setUser() because it will remove the params previously added.
    scope.setUser({
      ...scope.getUser(),
      localId,
      lang: lang ?? 'unknown',
      country: country ?? 'unknown'
    })
  })
}

export function * StartupSagas() {
  yield all([
    call(startUpSaga),
    call(setSentryUserProperties)
  ])
}
