import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'
import { title } from './Consts'

export function getPlanIsReady(t: TFunction): OnboardingStep {
  return {
    id: 'plan_is_ready',
    type: 'custom',
    allowNext: true,
    wordings: {
      title: t(`plan-is-ready.${title}`)
    }
  }
}
