import SocialLogin from 'Components/Login/SocialLogin'
import OrLineDivider from 'Components/OrLineDivider'
import { checkIfIsEmbeddedBrowser } from 'Lib/UIUtils'
import 'firebase/auth'
// import { useGoogleOneTapLogin } from 'react-google-one-tap-login'
import { FirebaseError } from '@firebase/util'
import RegistrationForm from 'Components/RegistrationForm'
import Logo from 'Components/Logo'
import { ActionType } from 'Reducers'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'store'
import styled from 'styled-components'
import { showToast } from 'Components/Toast'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  max-width: 400px;
  padding: ${({ theme }) => theme.spacing.small};
  margin: auto;
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: 500;
  text-align: left;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: ${({ theme }) => theme.spacing.zero};
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.xLarge};
`

const CreateAccountContainer: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const isEmbeddedBrowser = checkIfIsEmbeddedBrowser()

  const navigateToPostOnboardingRoute = () => {
    dispatch({ type: ActionType.HANDLE_NAVIGATING_TO_POST_ONBOARDING_ROUTE })
  }

  const handleNextStep = async (formData: {
    email: string
    password: string
    firstName: string
  }) => {
    dispatch({
      type: ActionType.REGISTER_WITH_EMAIL_AND_PASSWORD,
      email: formData.email,
      password: formData.password,
      name: formData.firstName,
      onError: showErrorMessage,
    })
  }

  const handleRegisterWithGooglePress = () => {
    dispatch({
      type: ActionType.REGISTER_WITH_GOOGLE_REQUEST,
      onSuccess: handleSuccess,
      onError: showErrorMessage
    })
  }

  const handleAppleLoginPress = () => {
    dispatch({
      type: ActionType.REGISTER_WITH_APPLE_REQUEST,
      onSuccess: handleSuccess,
      onError: showErrorMessage
    })
  }

  const handleSuccess = () => {
    logEvent('web_signUpSuccess')
    navigateToPostOnboardingRoute()
  }

  const showErrorMessage = (error: FirebaseError) => {
    const errorMessage = t(`FirebaseError.${error.code}`, { ns: 'translation', defaultValue: t('FirebaseError.auth/unknown', { ns: 'translation' }) })
    showToast('error', errorMessage)
  }

  return (
    <Container>
      <Logo />
      <ContentWrapper>
        <Title>{t('createAccountScreen.title')}</Title>
        <p>{t('forms.title')}</p>
        <RegistrationForm onRegisterTap={handleNextStep} />
        {!isEmbeddedBrowser && (
          <>
            <OrLineDivider />
            <SocialLogin
              onGooglePress={handleRegisterWithGooglePress}
              onApplePress={handleAppleLoginPress}
            />
          </>
        )}
      </ContentWrapper>
    </Container>
  )
}

export default CreateAccountContainer
