// @flow
import * as Sentry from '@sentry/react'

const handleAction = (next: any, action: any) => {
  const message = action.type.includes('@@router/LOCATION_CHANGE')
    ? `type: ${action.type} pathname: ${action?.payload?.location?.pathname}`
    : action.type

  Sentry.addBreadcrumb({
    category: 'redux_action',
    message,
    level: 'info'
  })

  return next(action)
}

export function createSentryMiddleware (): ((store: any) => (next: any) => (action: any) => any) {
  return store => next => action => handleAction(next, action)
}
