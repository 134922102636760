import { images } from 'images'

export const mapThirtyFiveMillionDownloadsImage = (lang: string) => {
  switch (lang) {
    case 'de':
      return images.laurel_DE
    case 'en':
      return images.laurel_EN
    case 'fr':
      return images.laurel_FR
    case 'es':
      return images.laurel_ES
    case 'pt':
      return images.laurel_PT
    case 'it':
      return images.laurel_IT
    default:
      throw new Error(
        `ThirtyFiveMillionDownloadsImage not found for language ${lang}`
      )
  }
}

export const mapOnboardingFinishedImage = (lang: string) => {
  switch (lang) {
    case 'de':
      return images.onboardingFinished_DE
    case 'en':
      return images.onboardingFinished_EN
    case 'fr':
      return images.onboardingFinished_FR
    case 'es':
      return images.onboardingFinished_ES
    case 'pt':
      return images.onboardingFinished_PT
    case 'it':
      return images.onboardingFinished_IT
    default:
      throw new Error(
        `OnboardingFinishedImage not found for language ${lang}`
      )
  }
}

export const mapGoogleBadgeImage = (lang: string) => {
  switch (lang) {
    case 'de':
      return images.GoogleBadge_DE
    case 'en':
      return images.GoogleBadge_EN
    case 'fr':
      return images.GoogleBadge_FR
    case 'es':
      return images.GoogleBadge_ES
    case 'pt':
      return images.GoogleBadge_PT
    case 'it':
      return images.GoogleBadge_IT
    default:
      throw new Error(
        `GoogleBadgeImage not found for language ${lang}`
      )
  }
}

export const mapAppleBadgeImage = (lang: string) => {
  switch (lang) {
    case 'de':
      return images.AppleBadge_DE
    case 'en':
      return images.AppleBadge_EN
    case 'fr':
      return images.AppleBadge_FR
    case 'es':
      return images.AppleBadge_ES
    case 'pt':
      return images.AppleBadge_PT
    case 'it':
      return images.AppleBadge_IT
    default:
      throw new Error(
        `AppleBadgeImage not found for language ${lang}`
      )
  }
}

export const mapNumber1AppImage = (lang: string) => {
  switch (lang) {
    case 'de':
      return images.number1App_DE
    case 'en':
      return images.number1App_EN
    case 'fr':
      return images.number1App_FR
    case 'es':
      return images.number1App_ES
    case 'pt':
      return images.number1App_PT
    case 'it':
      return images.number1App_IT
    default:
      throw new Error(`N1AppImage not found for language ${lang}`)
  }
}

export const mapMoneyBackGuaranteeImage = (lang: string) => {
  switch (lang) {
    case 'de':
      return images.moneyBack_DE
    case 'en':
      return images.moneyBack_EN
    case 'fr':
      return images.moneyBack_FR
    case 'es':
      return images.moneyBack_ES
    case 'pt':
      return images.moneyBack_PT
    case 'it':
      return images.moneyBack_IT
    default:
      throw new Error(`MoneyBackGuaranteeImage not found for language ${lang}`)
  }
}

export const mapScientificMethodImage = (lang: string) => {
  switch (lang) {
    case 'de':
      return images.scientificMethod_DE
    case 'en':
      return images.scientificMethod_EN
    case 'fr':
      return images.scientificMethod_FR
    case 'es':
      return images.scientificMethod_ES
    case 'pt':
      return images.scientificMethod_PT
    case 'it':
      return images.scientificMethod_IT
    default:
      throw new Error(`ScientificMethodImage not found for language ${lang}`)
  }
}

export const mapOnboardingFinishedSliderImages = (lang: string) => {
  switch (lang) {
    case 'de':
      return [
        images.screenshot01_DE,
        images.screenshot02_DE,
        images.screenshot03_DE,
        images.screenshot04_DE,
        images.screenshot05_DE,
        images.screenshot06_DE
      ]
    case 'en':
      return [
        images.screenshot01_EN,
        images.screenshot02_EN,
        images.screenshot03_EN,
        images.screenshot04_EN,
        images.screenshot05_EN,
        images.screenshot06_EN
      ]
    case 'fr':
      return [
        images.screenshot01_FR,
        images.screenshot02_FR,
        images.screenshot03_FR,
        images.screenshot04_FR,
        images.screenshot05_FR,
        images.screenshot06_FR
      ]
    case 'es':
      return [
        images.screenshot01_ES,
        images.screenshot02_ES,
        images.screenshot03_ES,
        images.screenshot04_ES,
        images.screenshot05_ES,
        images.screenshot06_ES
      ]
    case 'pt':
      return [
        images.screenshot01_PT,
        images.screenshot02_PT,
        images.screenshot03_PT,
        images.screenshot04_PT,
        images.screenshot05_PT,
        images.screenshot06_PT
      ]
    case 'it':
      return [
        images.screenshot01_IT,
        images.screenshot02_IT,
        images.screenshot03_IT,
        images.screenshot04_IT,
        images.screenshot05_IT,
        images.screenshot06_IT
      ]
    default:
      throw new Error(`SliderImages not found for language ${lang}`)
  }
}
