import { options, title } from 'Containers/steps/Consts'
import { t } from 'i18next'
import { ManageSubscriptionStep, ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'

export enum PauseOfferOption {
  NoThanks = 'noThanks',
  Pause = 'pause'
}

export function getPauseOfferStep(): ManageSubscriptionStep {
  return {
    id: ManageSubscriptionStepId.PauseOffer,
    type: 'custom',
    allowNext: true,
    wordings: {
      title: t(`${ManageSubscriptionStepId.PauseOffer}.${title}`)
    }
  }
}
