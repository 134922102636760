import i18n from 'i18n'

export const MAX_NAME_LENGTH = 32

export const EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const isValidEmail = (email: string): boolean => {
  return EmailRegex.test(email)
}

// Returns undefined if valid, or an error the error message if invalid
export const validateEmail = (email: string): string | undefined => {
  if (!isValidEmail(email)) {
    const error = i18n.t('errors.emailInvalid')
    return error
  }
}

// Returns undefined if valid, or an error the error message if invalid
export const validatePassword = (password: string) => {
  if (password === '') {
    return i18n.t('errors.passwordInvalid')
  } else if (password.length < 6) {
    return i18n.t('errors.passwordTooShort')
  }
}

// Returns undefined if valid, or an error the error message if invalid
export const validateName = (name: string) => {
  if (name === '') {
    return i18n.t('errors.nameInvalid')
  } else if (name.length > MAX_NAME_LENGTH) {
    return i18n.t('errors.nameTooLong')
  }
}

export const validateConfirmPassword = (confirmPassword: string, password: string) => {
  if (confirmPassword === '') {
    return i18n.t('errors.passwordInvalid')
  } else if (confirmPassword !== password) {
    return i18n.t('errors.passwordsDoNotMatch')
  }
}
