import { mdiApple } from '@mdi/js'
import Icon from '@mdi/react'
import { checkIfIsAppleDevice } from 'Lib'
import { images } from 'images'
import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: ${({ theme }) => theme.spacing.xsmall};
  max-width: 50%;
  display: flex;
  flex: 1;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small};
  cursor: pointer;
  justify-content: center;
  color: #000;
  font-family: Fira Sans,sans-serif;
  padding: ${({ theme }) => theme.spacing.xsmall};
`

export const SocialLoginWrapper = styled.div`
  display: flex;
  max-width: 400px;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.medium};
  margin: ${({ theme }) => theme.spacing.large + ' 0'};
`

interface SocialLoginProps {
  onGooglePress: () => void
  onApplePress: () => void
}

const SocialLogin: React.FC<SocialLoginProps> = ({ onGooglePress, onApplePress }) => {
  const isAppleDevice = checkIfIsAppleDevice()

  return (
    <SocialLoginWrapper>
      <Button onClick={onGooglePress}>
        <img width={25} src={images.googleLogo} />
      </Button>
      {isAppleDevice && (
        <Button onClick={onApplePress}>
          <Icon path={mdiApple} size={1.5} />
        </Button>
      )}
    </SocialLoginWrapper>
  )
}

export default SocialLogin
