import React from 'react'
import styled from 'styled-components'

const CardContainer = styled.div< { color?: string } >`
  background-color: ${({ color }) => color ?? '#f0f0f0;'};
  border-radius:  ${({ theme }) => theme.spacing.medium};
  padding: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  margin-top: ${({ theme }) => theme.spacing.medium};
  text-align: start;
  transition: opacity 0.5s ease-in-out;
`

const CardTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  margin-top: 0;
`

const CardContent = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin: 0;
`

interface InfoCardProps {
  title?: string | null
  content?: string | null
  color?: string
  children?: React.ReactNode
}

const InfoCard: React.FC<InfoCardProps> = ({ title, content, children, color }) => {
  return (
    <CardContainer color={color}>
      {title && <CardTitle>{title}</CardTitle>}
      {content && <CardContent>{content}</CardContent>}
      {children && <CardContent>{children}</CardContent>}
    </CardContainer>
  )
}

export default InfoCard
