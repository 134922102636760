import React, { useState } from 'react'
import styled from 'styled-components'
import { FiEye, FiEyeOff } from 'react-icons/fi'

const InputWrapper = styled.div<{ hasError: boolean, isInputValid: boolean }>`
  display: flex;
  align-items: center;
  background-color: #f2f4f6;
  border-radius: ${props => props.theme.spacing.small};
  border: 1px solid;
  border-color: ${props => props.hasError ? 'red' : (props.isInputValid ? props.theme.colors.primary : props.theme.colors.lightGray)};
  width: 100%;
`

const IconWrapper = styled.div`
  margin-right: ${props => props.theme.spacing.small};
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.lightGray};
`

const EyeIconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.lightGray};
  cursor: pointer;
`

const CustomInput = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 4px;
  align-items: center;
  font-size: ${props => props.theme.fontSizes.medium};
  font-family: Fira Sans,sans-serif;

  ::placeholder {
    color: #999;
  }
`

const Padding = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  width: 100%;
`

interface IconInputProps {
  icon?: React.ReactNode
  placeholder?: string
  type?: string
  name: string
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  ignoreSuggestions?: boolean
  autoCapitalize?: boolean
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void
  hasError: boolean
  isInputValid: boolean
  maxLength?: number
}

const IconInput: React.FC<IconInputProps> = ({
  icon,
  placeholder,
  type = 'text',
  name,
  value,
  onChange,
  ignoreSuggestions,
  autoCapitalize = false,
  onBlur,
  onFocus,
  hasError,
  isInputValid,
  maxLength
}) => {
  const [inputType, setInputType] = useState<string>(type)
  const isPassword = type === 'password'

  return (
    <InputWrapper hasError={hasError} isInputValid={isInputValid}>
      <Padding>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <CustomInput
          type={inputType}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          autoCapitalize={autoCapitalize ? 'auto' : 'none'}
          onBlur={onBlur}
          onFocus={onFocus}
          autoComplete={ignoreSuggestions ? 'off' : 'on'}
          maxLength={maxLength}
        />
        {isPassword && (
          <>
            { inputType === 'password' ? (
              <EyeIconWrapper onClick={() => setInputType('text')}>
                <FiEyeOff/>
              </EyeIconWrapper>
            ) : (
              <EyeIconWrapper onClick={() => setInputType('password')}>
                <FiEye/>
              </EyeIconWrapper>
            )}
          </>
        )}
      </Padding>
    </InputWrapper>
  )
}

export default IconInput
