import { options, title } from 'Containers/steps/Consts'
import { t } from 'i18next'
import { ManageSubscriptionStep, ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'

export enum PauseOption {
  oneMonth = 'oneMonth',
  twoMonths = 'twoMonths',
  threeMonths = 'threeMonths'
}

export function getPauseSubscriptionStep(): ManageSubscriptionStep {
  return {
    id: ManageSubscriptionStepId.PauseSubscription,
    type: 'custom',
    allowNext: true,
    wordings: {
      title: t(`${ManageSubscriptionStepId.PauseSubscription}.${title}`)
    }
  }
}
