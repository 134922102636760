import { useAppState } from 'AppContextProvider'
import { images } from 'images'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logo from './Logo'

const HoursTrackedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
`

const Title = styled.h1`
  font-size: ${props => props.theme.fontSizes.xLarge};
  width: 100%;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  padding-top: ${props => props.theme.spacing.xLarge};
  text-align: center;
`

const Subtitle = styled.h2`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin: ${props => props.theme.spacing.zero};
`

const HoursTrackedImage = styled.img`
  width: 100%;
  height: 400px;
  margin: ${props => props.theme.spacing.medium + ' ' + props.theme.spacing.zero};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: 250px;
    width: auto;
  }
`

const HoursTrackedStep: React.FC = () => {
  const { t } = useTranslation()
  const { appState } = useAppState()

  const image = appState.selections?.gender ? appState.selections.gender === 'm' ? images.couple : images.ladies : images.couple

  return (
    <>
      <Logo />
      <HoursTrackedWrapper>
        <Title>{t('tracked_hours.number_of_hours', { ns: 'onboarding' })}</Title>
        <Subtitle>{t('tracked_hours.subtitle', { ns: 'onboarding' })}</Subtitle>
        <HoursTrackedImage src={image} />
      </HoursTrackedWrapper>
    </>
  )
}

export default HoursTrackedStep
