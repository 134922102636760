import styled from 'styled-components'

const OptionWrapper = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  background-color: #f0f0f0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text};

  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.spacing.small};

  font-weight: 700;
  line-height: ${({ theme }) => theme.fontSizes.large};
  outline: 2px solid ${({ selected, theme }) => selected ? theme.colors.primary : theme.colors.transparent};

  @media (hover: hover) {
    &:hover {
      outline: 2px solid ${({ theme }) => theme.colors.primary};
    }
  }
`

export default OptionWrapper
