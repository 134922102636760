import { images } from 'images'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle } from 'react-icons/fa'
import styled from 'styled-components'
import { theme } from 'styles/theme'
import { Title } from './Title'
import UsersRatings from './UsersRatings'
import { mapNumber1AppImage, mapThirtyFiveMillionDownloadsImage } from 'Lib/ImageUtils'
import { getUserLanguage } from 'Lib'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  max-width: 50%;
`

const Subtitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: bold;
  text-align: center;
  margin: ${({ theme }) => theme.spacing.small + ' ' + theme.spacing.zero};
  color: ${({ theme }) => theme.colors.text};
`

const HowToAchieveBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 ${({ theme }) => theme.spacing.small};
`

const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.text};
  margin: ${({ theme }) => theme.spacing.small + ' ' + theme.spacing.zero};
  width: 100%;
`

const ItemList = styled.div`
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: start;
  gap: ${({ theme }) => theme.spacing.small};
  width: 100%;
`
const Icon = styled(FaCheckCircle)`
  width: 25px;
  height: 25px;
`

const Spacer = styled.div<{ height?: string, width?: string }>`
  height: ${props => props.height ?? props.theme.spacing.xLarge};
  width: ${props => props.width ?? props.theme.spacing.xLarge};
`

const ProgressionImage = styled.img`
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing.small};
`

const GoalsDisclaimentText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  color: ${({ theme }) => theme.colors.lightText};
  margin: ${({ theme }) => theme.spacing.small + ' ' + theme.spacing.zero};
`

const Testimonial = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.text};
  margin: ${({ theme }) => theme.spacing.small + ' ' + theme.spacing.zero};
  font-style: italic;
`

interface BenefitsProps {
  isMale: boolean
}

const Benefits: React.FC<BenefitsProps> = ({ isMale }) => {
  const { t } = useTranslation()
  const lang = getUserLanguage()

  // Add images for other languages
  const thirtyFiveMillionDownloadsImage = mapThirtyFiveMillionDownloadsImage(lang)
  const number1AppImage = mapNumber1AppImage(lang)

  const ratings = [
    { userName: t('ratings.first.userName'), rating: 5, text: t('ratings.first.text') },
    { userName: t('ratings.second.userName'), rating: 5, text: t('ratings.second.text') },
    { userName: t('ratings.third.userName'), rating: 5, text: t('ratings.third.text') },
    { userName: t('ratings.fourth.userName'), rating: 5, text: t('ratings.fourth.text') }
  ]

  const testimonial = isMale ? t('progressionTestimonialMale') : t('progressionTestimonialFemale')

  return (
    <Column>
      <Spacer height='20px' />
      <Title>{t('we_care_about_your_success_title')}</Title>
      <Subtitle>{t('we_care_about_your_success_subtitle')}</Subtitle>
      <Row>
        <Image src={thirtyFiveMillionDownloadsImage} />
        <Image src={number1AppImage} />
      </Row>
      <Spacer height='20px' />
      <HowToAchieveBody>
        <Title>{t('whatYouGet.title')}</Title>
        <ItemList>
          <Icon color={theme.colors.primary} />
          <Text>{t('whatYouGet.first')}</Text>
        </ItemList>
        <ItemList>
          <Icon color={theme.colors.primary} />
          <Text>{t('whatYouGet.second')}</Text>
        </ItemList>
        <ItemList>
          <Icon color={theme.colors.primary} />
          <Text>{t('whatYouGet.third')}</Text>
        </ItemList>
        <ItemList>
          <Icon color={theme.colors.primary} />
          <Text>{t('whatYouGet.fourth')}</Text>
        </ItemList>
        <Spacer />
        <Title>{t('successStoriesTitle')}</Title>
        <Subtitle>{t('successStoriesSubtitle')}</Subtitle>
        <UsersRatings ratings={ratings} />

        <Title>{isMale ? t('usersMeetGoalsMaleTitle') : t('usersMeetGoalsFemaleTitle')}</Title>
        <Spacer height='20px' />
        <ProgressionImage src={isMale ? images.progressionMale : images.progressionFemale} />
        <Testimonial>{testimonial}</Testimonial>
        <GoalsDisclaimentText>{t('progressionDisclaimer')}</GoalsDisclaimentText>

        <Spacer />
      </HowToAchieveBody>

    </Column>
  )
}

export default Benefits
