// ProgressBar.tsx
import React from 'react'
import styled from 'styled-components'
import { images } from '../images'
import { useFeatureValue } from '@growthbook/growthbook-react'

const ProgressBarWrapper = styled.div`
  position: sticky;
  top: 0;
  padding-top: ${({ theme }) => theme.spacing.small};
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  z-index: 1;
  background-color: white;
`

const ProgressBarContainer = styled.div<{ withDotPadding?: boolean }>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.lighterGray};
  border-radius: ${({ theme }) => theme.spacing.xsmall};
  height: 6px;
  padding: ${({ withDotPadding }) => withDotPadding ? '0 10px' : '0'};
  margin: ${({ withDotPadding }) => withDotPadding ? '0 15px' : '0'};
`

const CurrentStep = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`
const StepOutOfSeperator = styled.span`
  margin: 0 3px;
`

const ProgressFill = styled.div<{ progress: number }>`
  height: 100%;
  width: ${({ progress }) => progress * 100}%;
  border-radius: ${({ theme }) => theme.spacing.xsmall};
  background-color: ${({ theme }) => theme.colors.primary};
  transition: width 0.5s ease-in-out; // Add this line
`

const InfoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.small};
`

const LogoOuterWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
`

const LogoWrapper = styled.div<{ withMargin?: boolean }>`
  position: relative;
  left: -50%;
  display: inline;

  source {
    height: 20px;
  }

  img {
    height: 20px;
  }
`

const BackArrow = styled.button < { visible?: boolean } >`
  background: none;
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
  border: none;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${props => props.theme.colors.lightText};
  margin-top: -10px;
`

const StepInfo = styled.div<{ visible?: boolean }>`
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 700;
`

const ProgressDotContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
`

const ProgressDot = styled.div<{ active: boolean }>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${({ theme, active }) => active ? theme.colors.primary : theme.colors.lighterGray};
  transition: background-color 0.5s ease-in-out;
`

interface ProgressBarProps {
  currentStep: number
  totalSteps: number
  barrierSteps: number[]
  onBack: () => void
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  currentStep,
  totalSteps,
  barrierSteps,
  onBack
}) => {
  const segmetDots = useFeatureValue('progressbar-segment-dots', false)
  const stepCounter = useFeatureValue('progressbar-step-counter', true)
  const backButton = useFeatureValue('progressbar-back-button', true)
  barrierSteps = barrierSteps.concat([totalSteps]) // simplifies calculation

  let progress = 0

  if (!segmetDots) {
    progress = currentStep / totalSteps
  } else {
    const segmentLength = 1 / barrierSteps.length
    for (let i = 0; i < barrierSteps.length; i++) {
      if (currentStep <= barrierSteps[i]) {
        const stepsInSegment = barrierSteps[i] - (i === 0 ? 0 : barrierSteps[i - 1])
        const segmentProgress = stepsInSegment - (barrierSteps[i] - currentStep)
        progress = i * segmentLength + segmentProgress / stepsInSegment * segmentLength
        break
      }
    }
  }

  return (
    <ProgressBarWrapper>
      <InfoContainer>
        {backButton && <BackArrow visible={currentStep !== 0} onClick={onBack}>&larr;</BackArrow>}
        <LogoOuterWrapper>
          <LogoWrapper>
            <picture>
              <source srcSet={images.LogoWebp} type="image/webp" />
              <img src={images.Logo} />
            </picture>
          </LogoWrapper>
        </LogoOuterWrapper>
        <StepInfo visible={stepCounter}>
          <CurrentStep>{currentStep}</CurrentStep><StepOutOfSeperator>/</StepOutOfSeperator>{totalSteps}
        </StepInfo>
      </InfoContainer>
      <ProgressBarContainer withDotPadding={segmetDots}>
        <ProgressFill progress={progress} />
        {segmetDots && <ProgressDotContainer>
          <ProgressDot active={true} />
          {barrierSteps.map((step, index) => (
            <ProgressDot key={index} active={currentStep >= barrierSteps[index]} />
          ))}
        </ProgressDotContainer>
        }
      </ProgressBarContainer>
    </ProgressBarWrapper>
  )
}

export default ProgressBar
