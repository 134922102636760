import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'
import { options } from './Consts'

export enum SpecialOccasion {
  vacation = 'vacation',
  wedding = 'wedding',
  sportingEvent = 'sporting_event',
  summer = 'summer',
  reunion = 'reunion',
  familyEvent = 'family_event',
  other = 'other',
  noEvent = 'no_event'
}

export function getSpecialOccasion(t: TFunction): OnboardingStep {
  const specialOccasion = 'special-occasion'

  return {
    id: 'special_occasion',
    type: 'single-select',
    wordings: {
      title: t(`${specialOccasion}.title`)
    },
    options: [
      {
        id: SpecialOccasion.vacation,
        label: t(`${specialOccasion}.${options}.${SpecialOccasion.vacation}`),
        emoji: '🏖️'
      },
      {
        id: SpecialOccasion.wedding,

        label: t(`${specialOccasion}.${options}.${SpecialOccasion.wedding}`),
        emoji: '💒'
      },
      {
        id: SpecialOccasion.sportingEvent,
        label: t(`${specialOccasion}.${options}.${SpecialOccasion.sportingEvent}`),
        emoji: '🏀'
      },
      {
        id: SpecialOccasion.summer,
        label: t(`${specialOccasion}.${options}.${SpecialOccasion.summer}`),
        emoji: '☀️'
      },
      {
        id: SpecialOccasion.reunion,
        label: t(`${specialOccasion}.${options}.${SpecialOccasion.reunion}`),
        emoji: '🫂'
      },
      {
        id: SpecialOccasion.familyEvent,
        label: t(`${specialOccasion}.${options}.${SpecialOccasion.familyEvent}`),
        emoji: '👨‍👩‍👧‍👦'
      },
      {
        id: SpecialOccasion.other,
        label: t(`${specialOccasion}.${options}.${SpecialOccasion.other}`),
        emoji: '❓'
      },
      {
        id: SpecialOccasion.noEvent,
        label: t(`${specialOccasion}.${options}.${SpecialOccasion.noEvent}`),
        emoji: '🥰'
      }
    ]
  }
}
