import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'
import { content, info, title, wordings } from './Consts'

export function getAge(t: TFunction): OnboardingStep {
  const age = 'age.'

  return {
    id: 'age',
    type: 'age',
    wordings: {
      title: t(`${age}${wordings}${title}`),
      placeholder: '',
      info: {
        title: t(`${age}${wordings}${info}.${title}`),
        content: t(`${age}${wordings}${info}.${content}`)
      }
    }
  }
}
