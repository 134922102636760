import StarRating from 'Components/StartsRating'
import styled from 'styled-components'

const UserRating = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding:  ${({ theme }) => theme.spacing.medium};
  gap: ${({ theme }) => theme.spacing.small};
`
const UserRatingName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: bold;
  color:  ${props => props.theme.colors.text};
  margin: 0;
`

const UserRatingText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${props => props.theme.colors.lightText};
  margin-left: ${({ theme }) => theme.spacing.small};

  ::before {
    content: '“';
    margin-left: -20px;
    font-size: ${({ theme }) => theme.fontSizes.xLarge};
    position: absolute;
    color: ${props => props.theme.colors.text};
  }
`

const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${props => props.theme.colors.text};
  margin: 10px 0;
`
const Spacer = styled.div<{ height?: string, width?: string }>`
  height: ${props => props.height ?? '40px'};
  width: ${props => props.width ?? '40px'};
`

interface UserRatingProps {
  ratings: Rating[]
}

interface Rating {
  userName: string
  rating: number
  text: string
}

const UsersRatings: React.FC<UserRatingProps> = ({ ratings }) => {
  return (
    <UserRating>
      {ratings.map((rating, index) => (
        <div key={index}>
          <UserRatingText>
            {rating.text}
          </UserRatingText>
          <Spacer height='5px' />
          <UserRatingName>
            <StarRating />
            <Spacer width='10px' />
            <Text> {rating.userName}</Text>
          </UserRatingName>
          <Spacer height='10px' />

        </div>
      ))}
    </UserRating>
  )
}

export default UsersRatings
