import { getUserLocaleString } from 'Lib'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import styled from 'styled-components'

const ChartWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 260px;
  background-color: white;
  padding:  ${({ theme }) => theme.spacing.small + ' ' + theme.spacing.small};
  border-radius: ${({ theme }) => theme.spacing.small};
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.06);
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
`

const Disclaimer = styled.p`
  padding: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  text-align: center;
  color: ${({ theme }) => theme.colors.lightText};
`

interface DataPoint {
  weight: number
  label?: string
  name?: string
}

function generateInclinedWave(goalDate: Date, currentLanguage: string, initialLabel: string, finalLabel: string) {
  const data: DataPoint[] = [
    { weight: 80 },
    { weight: 66 },
    { weight: 64 },
    { weight: 55 },
    { weight: 54 }
  ]

  const currentDate = new Date()
  const numberOfPeriods = monthDiff(currentDate, goalDate)

  data.forEach((point, index) => {
    const month = new Date()
    const newMonth = currentDate.getMonth() + (index / (data.length - 2) * numberOfPeriods)
    month.setMonth(newMonth)

    let monthName
    const newMonthName = month.toLocaleString(currentLanguage, { month: 'short' })

    if (index === 0) {
      monthName = newMonthName
    } else {
      const lastMonthName = data[index - 1]?.name
      if (newMonthName !== lastMonthName && lastMonthName !== undefined) {
        monthName = newMonthName
      }
    }

    if (index === 0) {
      point.label = initialLabel
      point.name = monthName
    } else if (index === data.length - 2) {
      point.label = finalLabel
      point.name = goalDate.toLocaleString(currentLanguage, { month: 'short' })
    } else {
      point.name = monthName
    }
  })

  return data
}

const monthDiff = (dateFrom: Date, dateTo: Date) => {
  return dateTo.getMonth() - dateFrom.getMonth() +
    (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

const Dot: React.FC<any> = ({ x, y }) => {
  return (
    <svg>
      <circle cx={x} cy={y} r={6} stroke="#10b294" strokeWidth={2} fill="white" filter="url(#whiteShadow)" />
    </svg>
  )
}

interface CustomLabelProps {
  x: number
  y: number
  text: string
}

const CustomLabel: React.FC<CustomLabelProps> = ({ x, y, text }) => {
  const textRef = useRef<SVGTextElement | null>(null)
  const [textWidth, setTextWidth] = useState(0)
  const [textHeight, setTextHeight] = useState(0)

  useEffect(() => {
    if (textRef.current) {
      const width = textRef.current.getBBox().width
      const height = textRef.current.getBBox().height
      setTextWidth(width)
      setTextHeight(height)
    }
  }, [textRef])

  const padding = 10
  const backgroundWidth = textWidth + padding * 2
  const backgroundHeight = textHeight + padding
  const lineHeight = 16
  const lines = text.split('\n')

  return (
    <g>
      <g>
        <circle cx={x} cy={y} r={8} stroke="none" fill="#252d48" />
        <path
          d="M0,0 L6,6 L12,0"
          transform={`translate(${x - 6},${y - 12})`}
          stroke="none"
          fill="#252d48"
        />
      </g>
      <circle cx={x} cy={y} r={4} stroke="#252d48" strokeWidth={2} fill="white" />
      <rect
        x={x - backgroundWidth / 2}
        y={y - backgroundHeight - 12}
        width={backgroundWidth}
        height={lines.length * lineHeight + padding}
        fill="#252d48"
        stroke="none"
        rx={6}
        ry={6}
      />
      <text
        ref={textRef}
        x={x}
        y={y - backgroundHeight + padding / 2}
        fill="white"
        fontSize={12}
        textAnchor="middle"
      >
        {lines.map((line: string, i: number) => (
          <tspan key={i} x={x} dy={i === 0 ? 0 : lineHeight}>
            {line}
          </tspan>
        ))}
      </text>
    </g>
  )
}

const CustomDot: React.FC<any> = (props) => {
  const { cx, cy, index, payload } = props

  if (index === 0 || payload.label) {
    return (
      <g>
        <Dot x={cx} y={cy} />
        {payload.label && (
          <CustomLabel key={index} x={cx} y={cy} text={payload.label} />
        )}
      </g>
    )
  }

  return null
}

interface WeightProgressionChartProps {
  goalDate: Date
  currentLabel: string
  goalLabel: string
  customDisclaimer?: string
}

const WeightProgressionChart: React.FC<WeightProgressionChartProps> = ({ goalDate, goalLabel, currentLabel, customDisclaimer }) => {
  const { t } = useTranslation()
  const currentLanguage = getUserLocaleString()
  const graphic = generateInclinedWave(goalDate, currentLanguage, currentLabel, goalLabel)
  const lastWeight = graphic[graphic.length - 1].weight
  const numberOfTicks = graphic.length
  const oddTickFormatter = (value: any, index: number) => {
    // Hiding months if goal date is not in the future.
    // It happens when the user wants to gain weight.
    if (goalDate <= new Date()) {
      return ''
    }
    if (index === 0 || index === numberOfTicks - 1) {
      return value
    }
    return value
  }

  return (
    <>
      <ChartWrapper>
        <ResponsiveContainer>
          <AreaChart
            data={graphic}
            margin={{
              top: 50,
              right: 30,
              left: 30,
              bottom: 0
            }}
          >
            <defs>
              <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#10aeb2" stopOpacity={0.4} />
                <stop offset="100%" stopColor="#10b294" stopOpacity={0.4} />
              </linearGradient>
              <linearGradient id="lineColorGradient" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#10aeb2" stopOpacity={1} />
                <stop offset="100%" stopColor="#10b294" stopOpacity={1} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeWidth={0.4} horizontal={false} />
            <YAxis hide domain={[lastWeight - 5, 'auto']} />
            <XAxis
              dataKey="name"
              tickFormatter={oddTickFormatter}
              fontSize={12}
              tickMargin={5}
              stroke="url(#colorGradient)"
            />
            <Area
              type="monotone"
              dataKey="weight"
              stroke="url(#lineColorGradient)"
              fill="url(#colorGradient)"
              strokeWidth={8}
              dot={<CustomDot />}
            />
          </AreaChart>
        </ResponsiveContainer>
      </ChartWrapper>
      <Disclaimer>{customDisclaimer ?? t('the-one-plan.disclaimer')}</Disclaimer>
    </>
  )
}

export default WeightProgressionChart
