import { options, title } from 'Containers/steps/Consts'
import { t } from 'i18next'
import { ManageSubscriptionStep, ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'

export enum CancellationReasonExpensiveOption {
  LearnMore = 'learnMore',
  CancelAnyway = 'cancelAnyway'
}

export function getCancellationReasonExpensiveStep(): ManageSubscriptionStep {
  return {
    id: ManageSubscriptionStepId.CancellationReasonExpensive,
    type: 'single-select',
    wordings: {
      title: t(`${ManageSubscriptionStepId.CancellationReasonExpensive}.${title}`)
    },
    options: [
      {
        id: CancellationReasonExpensiveOption.LearnMore,
        label: t(`${ManageSubscriptionStepId.CancellationReasonExpensive}.${options}.${CancellationReasonExpensiveOption.LearnMore}`)
      },
      {
        id: CancellationReasonExpensiveOption.CancelAnyway,
        label: t(`${ManageSubscriptionStepId.CancellationReasonExpensive}.${options}.${CancellationReasonExpensiveOption.CancelAnyway}`)
      }
    ]
  }
}
