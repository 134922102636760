import Logo from 'Components/Logo'
import MarkdownWrapper from 'Components/MarkdownWrapper'
import { getUserLanguage } from 'Lib'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Center = styled.div`
  display: flex;
  justify-content: center;
`
const MaxWidth = styled.div`
  padding: ${({ theme }) => theme.spacing.xLarge};
`

const PrivacyPolicyContainer: React.FC = () => {
  const language = getUserLanguage()
  const privacyFilePath = `assets/legal/Privacy.${language}.md`
  const { t } = useTranslation()

  return (
    <Center>
      <MaxWidth>
        <Logo/>
        <MarkdownWrapper filePath = {privacyFilePath} />
      </MaxWidth>
    </Center>
  )
}

export default PrivacyPolicyContainer
