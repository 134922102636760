import { SpecialOccasion } from 'Containers/steps'
import { getUserLocaleString, getGoalWeightDate } from 'Lib'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { theme } from 'styles/theme'
import StepSubtitle from './StepSubtitle.component'
import StepTitle from './StepTitle.component'
import WeightProgressionChart from './WeightProgressionChart'
import i18n from 'i18n'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const SummaryText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  text-align: center;
`

const SpecialOccasionText = styled.p`
  text-align: center;
`

interface TheOneStepProps {
  currentWeight: IUserWeight
  goalWeight: IUserWeight
  specialOccasion?: SpecialOccasion
}

const TheOneStep: React.FC<TheOneStepProps> = ({ goalWeight, currentWeight, specialOccasion }) => {
  const { t } = useTranslation()
  const currentLanguage = getUserLocaleString()
  const [specialOccasionText, setSpecialOccasionText] = useState('')
  const goalDate = getGoalWeightDate(goalWeight, currentWeight)
  const unit = currentWeight.original.unit === 'cm/kg' ? t('weight.kg') : t('weight.lb')
  const currentLabel = currentWeight.inKg.value > goalWeight.inKg.value ? `${currentWeight.original.value} ${unit}` : t('the-one-plan.currentWeightLabel')
  const goalLabel = t('the-one-plan.goal') + ' 🏆'
  const showGoalDate = goalWeight.inKg.value < currentWeight.inKg.value

  const infoStepConfig = {
    title: showGoalDate ? t('the-one-plan.titleLoseWeight') : t('the-one-plan.titleGainWeight'),
    subtitle: showGoalDate ? t('the-one-plan.subtitleLoseWeight') : t('the-one-plan.subtitleGainWeight')
  }

  useEffect(() => {
    if (specialOccasion && specialOccasion !== SpecialOccasion.noEvent && specialOccasion !== SpecialOccasion.other) {
      setSpecialOccasionText(t(`plan-is-ready.special-occasion.${specialOccasion}`) ?? '')
    }
  }, [specialOccasion])

  return (
    <>
      <StepTitle>{infoStepConfig.title}</StepTitle>
      <StepSubtitle>{infoStepConfig.subtitle}</StepSubtitle>
      <Wrapper>
        {showGoalDate && <SummaryText>
          <span style={{ color: theme.colors.primary }}>{`${goalWeight.original.value} ${unit} `}</span>
          {t('the-one-plan.by')}
          <span style={{ color: theme.colors.primary }}>{t('the-one-plan.date', { date: ` ${goalDate.toLocaleDateString(currentLanguage, { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' })}` })}</span>
        </SummaryText>}
        <WeightProgressionChart
          currentLabel={currentLabel} goalLabel={goalLabel} goalDate={goalDate}
        />
        {(specialOccasionText && <SpecialOccasionText>{specialOccasionText}</SpecialOccasionText>)}
      </Wrapper>
    </>
  )
}

export default TheOneStep
