import { CustomPackage, PaymentColorPackage } from 'types/onboardingTypes'
import PackageSelection from './PackageSelection'
import PackageSkeleton from './PackageSelectionSkeleton'
import { useAppState } from 'AppContextProvider'

interface PackagesProps {
  onOpenModal: () => void
  selectedPackageId: string | null
  packages?: CustomPackage[]
  onPackageChanged: (id: string) => void
  discountTimeLeft: string | null
  withDiscountTimer: boolean
  colorPackage: PaymentColorPackage
}

const Packages: React.FC<PackagesProps> = ({
  onOpenModal,
  selectedPackageId,
  onPackageChanged,
  discountTimeLeft,
  withDiscountTimer,
  colorPackage
}) => {
  const { appState } = useAppState()
  if (!appState.stripePrices || !selectedPackageId) {
    return <PackageSkeleton />
  }

  return <PackageSelection
    onOpenModal={onOpenModal}
    selectedPackageId={selectedPackageId}
    onPackageChanged={onPackageChanged}
    discountTimeLeft={discountTimeLeft}
    withDiscountTimer={withDiscountTimer}
    colorPackage={colorPackage}
  />
}

export default Packages
