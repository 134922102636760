/* eslint-disable @typescript-eslint/naming-convention */
import { loadStripe } from '@stripe/stripe-js/pure' // Don't import loadStripe from @stripe/stripe-js. It doesn't throw error when there is an error (tested with the network error)
import { Stripe, PaymentMethod } from '@stripe/stripe-js'
import { getSanitizedSearchParamsString } from 'Lib/AppUtils'
import * as Sentry from '@sentry/react'
import { Store } from 'redux'
import { ActionType } from 'Reducers'
import { axios } from 'Fetcher'
import i18n from 'i18n'
import { RouteNames } from 'RouteNames'
import { firebaseServicePromise } from 'store'
import { getPaymentUrl } from 'Lib/EventsUtils'
import { getPlatform } from 'Lib'

let stripe: Stripe | null = null

export async function initStripeService(store: Store): Promise<Stripe | null> {
  const STRIPE_KEY = __DEV__
    ? 'pk_test_51HqeBSKNaJOrhJwVsmX16nd9oBNWw23DDi4s17G6CK0uzb9yORAOgNrhFwm9wZIrmKX4Z3NZuvmsCxyaRag15Wte00hBL8dofF'
    : 'pk_live_51HqeBSKNaJOrhJwVxSAzrBmJIQJN7SZKvjD0q7dNZB7uNWQsNsyRpxBkiKMAzU7slJDRYO9nGTDRD5ynAWn5WiIj00cjndDKQj'

  if (!stripe) {
    try {
      stripe = await loadStripe(STRIPE_KEY)

      if (!stripe) {
        throw new Error('Stripe is not initialized')
      }
    } catch (error) {
      logEvent('web_stripeLoadScriptFail', error)
      Sentry.captureException(error)

      // eslint-disable-next-line
      await new Promise((r) => setTimeout(r, 1000));
      return await initStripeService(store)
    }
  }

  store.dispatch({ type: ActionType.STRIPE_INITIALIZED })

  return stripe
}

export interface StripePricesType {
  prices: IStripePrice[]
  isEU: boolean
  countryCode?: string
  region?: string
  currency: string
  paypalElementsEnabled: boolean
  paypalCheckoutEnabled: boolean
  paypalNativeFallbackEnabled: boolean
  isPurchaseAvailable: boolean
}
interface FetchStripePricesResponse {
  data: StripePricesType
}

export const fetchStripePrices = async (promo: SubscriptionGroupId, currency?: string): Promise<FetchStripePricesResponse> => {
  let url = `/api/payment/getProducts/${promo}`

  if (currency) {
    url += `?currency=${currency}`
  }

  const response = await axios({
    method: 'GET',
    url
  })

  return { data: response.data.data }
}

export const extractStripeMetadataFromPrice = (price: IStripePrice): { group: SubscriptionGroupId, monthDuration: number } => {
  const group = price.metadata.group
  const monthDuration = price.recurring.interval === 'year' ? price.recurring.interval_count * 12 : price.recurring.interval_count
  return { group, monthDuration }
}

// still required for external windows (e.g. paypal mobile) return URL. the return URL is appended with the success/error status and the user needs to be redirected from there
export const getStripeElementsReturnUrl = (subscriptionId: string, selecteedPackageId: string) => {
  const baseUrl = location.hostname === 'localhost' ? 'http://localhost:3000' : `https://${location.hostname}`
  const searchParamsString = getSanitizedSearchParamsString().replace('?', '&')

  const paymentPath = getPaymentUrl()
  return `${baseUrl}${paymentPath}?send_event=true&subscriptionId=${subscriptionId}&selectedPackageId=${selecteedPackageId}${searchParamsString}`
}

export const createStripeSubscriptionHandler = async (
  priceId: string,
  email: string,
  lang: string,
  trialId?: string,
  withDiscount?: boolean,
  gender?: IGender | null
): Promise<{ type: 'payment' | 'setup', clientSecret: string, customerId: string, subscriptionId: string }> => {
  const url = '/api/payment/createSubscription'
  const firebase = await firebaseServicePromise

  let searchParamsString = getSanitizedSearchParamsString().replace('?', '&')

  // Remove token param from search params so we don't send it twice in case we
  // already have it in the query params
  if (searchParamsString) {
    const paramsArray = searchParamsString.split('&')
    const filteredParamsArray = paramsArray.filter(param => !param.startsWith('token='))

    searchParamsString = filteredParamsArray.join('&')
  }

  const firstVisit = localStorage.getItem('firstVisit')
  const platform = getPlatform()

  const idToken = await firebase.getIdToken()

  const response = await axios({
    method: 'POST',
    url,
    data: {
      priceId,
      trialId,
      origin,
      urlParams: searchParamsString,
      lang,
      email,
      withDiscount,
      firstVisit,
      gender,
      platform
    },
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  })

  return response.data.data
}

export const updateSubscriptionWithSpecialOfferHandler = async (specialOfferDuration: SpecialOfferDuration): Promise<{}> => {
  const url = '/api/payment/updateSubscriptionWithSpecialOffer'

  const firebase = await firebaseServicePromise

  let idToken: string
  try {
    idToken = await firebase.getIdToken()
  } catch (e) {
    throw new Error('Error getting firebase token id: You are not logged in!')
  }

  try {
    const response = await axios({
      method: 'POST',
      url,
      data: {
        duration: specialOfferDuration
      },
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })

    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    throw new Error('Error creating payment session')
  }
}

export const cancelStripeSubscriptionHandler = async (): Promise<{}> => {
  const url = '/api/payment/cancelSubscription'
  const firebase = await firebaseServicePromise

  let idToken: string
  try {
    idToken = await firebase.getIdToken()
  } catch (e) {
    throw new Error('Error getting firebase token id: You are not logged in!')
  }

  try {
    const response = await axios({
      method: 'POST',
      url,
      data: {
      },
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })

    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    throw new Error('Error creating payment session')
  }
}

export const restoreStripeSubscriptionHandler = async (): Promise<{}> => {
  const url = '/api/payment/restoreSubscription'
  const firebase = await firebaseServicePromise

  let idToken: string
  try {
    idToken = await firebase.getIdToken()
  } catch (e) {
    throw new Error('Error getting firebase token id: You are not logged in!')
  }

  try {
    const response = await axios({
      method: 'POST',
      url,
      data: {
      },
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })

    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    throw new Error('Error creating payment session')
  }
}

export const pauseStripeSubscriptionHandler = async (months: number): Promise<{}> => {
  const url = '/api/payment/pauseSubscription'
  const firebase = await firebaseServicePromise

  let idToken: string
  try {
    idToken = await firebase.getIdToken()
  } catch (e) {
    throw new Error('Error getting firebase token id: You are not logged in!')
  }

  try {
    const response = await axios({
      method: 'POST',
      url,
      data: {
        months
      },
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })

    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    throw new Error('Error creating payment session')
  }
}

export const unpauseStripeSubscriptionHandler = async (): Promise<{}> => {
  const url = '/api/payment/unpauseSubscription'
  const firebase = await firebaseServicePromise

  let idToken: string
  try {
    idToken = await firebase.getIdToken()
  } catch (e) {
    throw new Error('Error getting firebase token id: You are not logged in!')
  }

  try {
    const response = await axios({
      method: 'POST',
      url,
      data: {
      },
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })

    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    throw new Error('Error creating payment session')
  }
}

export const createStripeCheckoutSession = async (priceId: string, lang: string, trialId?: string, withDiscount?: boolean, gender?: IGender | null, successEndpoint: string = RouteNames.PAYMENT_SUCCESS, cancelEndpoint: string = RouteNames.PAYMENT): Promise<{ sessionId: string }> => {
  const url = '/api/payment/createCheckoutSession'
  const baseUrl = location.hostname === 'localhost' ? 'http://localhost:3000' : `https://${location.hostname}`
  let cancelUrl = `${baseUrl}${cancelEndpoint}`
  let successUrl = `${baseUrl}${successEndpoint}`
  const firstVisit = localStorage.getItem('firstVisit')
  const platform = getPlatform()

  // Stripe automatically replaces this placeholder and we will use it to query
  // for a successful payment in the success page
  successUrl += '?session_id={CHECKOUT_SESSION_ID}&send_event=true'

  const firebase = await firebaseServicePromise

  let searchParams = localStorage.getItem('searchParams')?.replace('?', '&') ?? ''

  // Remove token param from search params so we don't send it twice in case we
  // already have it in the query params
  if (searchParams) {
    const paramsArray = searchParams.split('&')
    const filteredParamsArray = paramsArray.filter(param => !param.startsWith('token='))

    searchParams = filteredParamsArray.join('&')
  }

  let idToken: string
  try {
    idToken = await firebase.getIdToken()
    successUrl += `&token=${idToken}${searchParams}`
    cancelUrl += `/?token=${idToken}${searchParams}`
  } catch (e) {
    throw new Error('Error getting firebase token id: You are not logged in!')
  }

  const response = await axios({
    method: 'POST',
    url,
    data: {
      priceId,
      trialId,
      origin,
      successUrl,
      cancelUrl,
      lang,
      withDiscount,
      firstVisit,
      gender,
      platform
    },
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  })

  const sessionId = response.data.data.sessionId

  if (!sessionId) {
    throw new Error('Error creating Stripe checkout session. Session id is missing!')
  }
  return response.data.data
}

export const setUpStripeCardCustomer = async (
  paymentMethod: PaymentMethod,
  pricingId: string
): Promise<any> => {
  // Add Stripe Subscription type later? if necessary
  const firebaseService = await firebaseServicePromise
  const idToken = await firebaseService.getIdToken()
  try {
    const response = await axios({
      method: 'POST',
      url: '/api/payment/cardSubscribe',
      data: {
        pricingId,
        paymentMethodId: paymentMethod.id,
        locale: i18n.language
      },
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })

    return { subscription: response.data.data.subscription }
  } catch (e) {
    return { error: e.response.data.error.code }
  }
}

export async function getCustomerPortalUrl () {
  const firebaseService = await firebaseServicePromise
  const idToken = await firebaseService.getIdToken()

  const response: any = await axios({
    method: 'GET',
    url: '/api/payment/getCustomerPortalUrl',
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  })

  if (response.status !== 200) {
    const error = new Error('Could not fetch customer portal url')
    logEvent('web_stripePortalUrlError', error)
    throw error
  }

  if (!response.data.ok) {
    throw new Error('Could not fetch customer portal url')
  }

  return response.data.data
  // Linking.openURL(resJson.data)
}

export async function goToCustomerPortal () {
  const url = await getCustomerPortalUrl()
  window.open(url, '_blank')
}
