import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getWaterIntake(t: TFunction): OnboardingStep {
  const waterIntake = 'water_intake'
  const none = 'none'
  const twoGlasses = '2_glasses'
  const between2And6 = 'between_2_and_6'
  const aLot = 'a_lot'

  return {
    id: waterIntake,
    type: 'single-select',
    wordings: {
      title: t(`${waterIntake}.${title}`)
    },
    options: [
      {
        id: none,
        label: t(`${waterIntake}.${options}.${none}`),
        emoji: '🥤'
      },
      {
        id: twoGlasses,
        label: t(`${waterIntake}.${options}.${twoGlasses}`),
        emoji: '✌️'
      },
      {
        id: between2And6,
        label: t(`${waterIntake}.${options}.${between2And6}`),
        emoji: '👌'
      },
      {
        id: aLot,
        label: t(`${waterIntake}.${options}.${aLot}`),
        emoji: '🙌'
      }
    ]
  }
}
