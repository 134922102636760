import { options, title } from 'Containers/steps/Consts'
import { t } from 'i18next'
import { ManageSubscriptionStep, ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'

export enum CancellationReasonOption {
  Expensive = 'expensive',
  Undecided = 'undecided',
  AppComplicated = 'appComplicated',
  AppNotHelping = 'appNotHelping',
  GoalAchieved = 'goalAchieved',
  FoundBetterAlternatives = 'foundBetterAlternatives',
  NotSpecified = 'notSpecified',
}

export function getCancellationReasonStep(): ManageSubscriptionStep {
  return {
    id: ManageSubscriptionStepId.CancellationReason,
    type: 'single-select',
    wordings: {
      title: t(`${ManageSubscriptionStepId.CancellationReason}.${title}`)
    },
    options: [
      {
        id: CancellationReasonOption.Expensive,
        label: t(`${ManageSubscriptionStepId.CancellationReason}.${options}.${CancellationReasonOption.Expensive}`)
      },
      {
        id: CancellationReasonOption.Undecided,
        label: t(`${ManageSubscriptionStepId.CancellationReason}.${options}.${CancellationReasonOption.Undecided}`)
      },
      {
        id: CancellationReasonOption.AppComplicated,
        label: t(`${ManageSubscriptionStepId.CancellationReason}.${options}.${CancellationReasonOption.AppComplicated}`)
      },
      {
        id: CancellationReasonOption.AppNotHelping,
        label: t(`${ManageSubscriptionStepId.CancellationReason}.${options}.${CancellationReasonOption.AppNotHelping}`)
      },
      {
        id: CancellationReasonOption.GoalAchieved,
        label: t(`${ManageSubscriptionStepId.CancellationReason}.${options}.${CancellationReasonOption.GoalAchieved}`)
      },
      {
        id: CancellationReasonOption.FoundBetterAlternatives,
        label: t(`${ManageSubscriptionStepId.CancellationReason}.${options}.${CancellationReasonOption.FoundBetterAlternatives}`)
      },
      {
        id: CancellationReasonOption.NotSpecified,
        label: t(`${ManageSubscriptionStepId.CancellationReason}.${options}.${CancellationReasonOption.NotSpecified}`)
      },
    ]
  }
}
