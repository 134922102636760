import { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

// A markdown wrapper that fetches the markdown file from the given path and renders it.
// It also removes the $$AFoptout$$ string as well as the javascript links from the markdown content.
const MarkdownWrapper = ({ filePath }: {filePath: string}) => {
  const [markdownContent, setMarkdownContent] = useState('')

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch(filePath)
        const markdown = await response.text()
        const cleanedMarkdown = removeUnwantedTextFromMarkDown(markdown)
        setMarkdownContent(cleanedMarkdown)
      } catch (error) {
        logEvent('web_fetch_markdown_error', { error })
      }
    }

    void fetchMarkdown()
  }, [filePath])

  return (
    <ReactMarkdown>{markdownContent}</ReactMarkdown>
  )
}

const removeUnwantedTextFromMarkDown = (markdown: string) => {
  return markdown.replace('$$AFoptout$$', '')
}

export default MarkdownWrapper
