import { useAppState } from 'AppContextProvider'
import { MANAGE_SUBSCRIPTION_STEPS_BY_ID, RouteNames } from 'RouteNames'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { TRANSITION_ANIMATION_DURATION } from 'Lib/Constants'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { getSearchParams } from 'Lib'
import * as Sentry from '@sentry/react'
import { useDispatch } from 'store'
import { ActionType, Selectors } from 'Reducers'
import Loading from 'Components/Loading.component'
import SingleSelectStep from 'Components/SingleSelectStep'
import { ManageSubscriptionStepId, ManageSubscriptionStep } from 'types/manageSubscriptionTypes'
import { getManageSubscriptionSteps } from 'Containers/manageSubscriptionSteps/Steps'
import SpecialOfferStep from 'Components/SpecialOfferStep'
import SpecialOfferAcceptedStep from 'Components/SpecialOfferAcceptedStep'
import CancelSubscriptionSuccessStep from 'Components/CancelSubscriptionSuccessStep'
import SubscriptionStatusStep from 'Components/SubscriptionStatusStep'
import SubscriptionRestorationSuccessStep from 'Components/SubscriptionRestorationSuccessStep'
import LoginContainer from './Login.container'
import { useSelector } from 'react-redux'
import PauseSubscriptionStep from 'Components/PauseSubscriptionStep'
import PauseSubscriptionSuccessStep from 'Components/PauseSubscriptionSuccessStep'
import UnpauseSubscriptionSuccessStep from 'Components/UnpauseSubscriptionSuccessStep'
import PauseOfferStep from 'Components/PauseOfferStep'
import { SPECIAL_OFFER_GROUP_ID } from 'Lib/StripeUtils'

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between
  height: 100%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between
  height: 100%;
  max-width: ${props => props.theme.maxWidth};
  width: 100%;
`

const StepWrapper = styled.div`
  display: block;
  width: 100%;
`

const StepContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${props => props.theme.maxWidth};
  width: 100%;
  padding: 0 ${props => props.theme.spacing.small};
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
`

const ManageSubscriptionContainer: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const manageSubscriptionSteps = getManageSubscriptionSteps()
  const { appState } = useAppState()
  const isLoggedIn = useSelector(Selectors.isLoggedIn)

  const [currentStepId, setCurrentStepId] = useState<ManageSubscriptionStepId>(ManageSubscriptionStepId.Intro)
  const location = useLocation()

  useEffect(() => {
    dispatch({ type: ActionType.LOAD_PRODUCTS, groupId: SPECIAL_OFFER_GROUP_ID })
    dispatch({ type: ActionType.FETCH_SUBSCRIPTION_DETAILS })

    const searchParams = getSearchParams()
    const ctoken = searchParams.get('ctoken')

    if (isLoggedIn && !ctoken) {
      const pathNames = location.pathname.split('/')

      if (pathNames.length === 2) {
        history.push(MANAGE_SUBSCRIPTION_STEPS_BY_ID[ManageSubscriptionStepId.SubscriptionStatus])
      }

      return
    }

    if (!ctoken) {
      logEvent('web_customTokenInvalid')
      Sentry.captureException(new Error(`TokenLogin received an invalid custom token ${searchParams.toString()}`))
      history.push(MANAGE_SUBSCRIPTION_STEPS_BY_ID[ManageSubscriptionStepId.Login])
      return
    }

    dispatch({
      type: ActionType.SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST,
      ctoken,
      onSuccess: () => {
        const pathNames = location.pathname.split('/')

        if (pathNames.length === 2) {
          dispatch({ type: ActionType.COMMIT_MANAGE_SUBSCRIPTION_STEP, stepId: ManageSubscriptionStepId.Intro })
        }
      },
      onError: (e: Error) => {
        logEvent('web_customTokenError', { error: e.message })
        Sentry.captureException(new Error(`There was a problem logging in with custom token ${searchParams.toString()}, ${e.message}`))
        history.push(MANAGE_SUBSCRIPTION_STEPS_BY_ID[ManageSubscriptionStepId.Login])
      }
    })
  }, [])

  useEffect(() => {
    const pathNames = location.pathname.split('/')
    const stepId = pathNames.length === 3 ? (pathNames[2] as ManageSubscriptionStepId) : ManageSubscriptionStepId.Intro

    setCurrentStepId(stepId)
  }, [location])

  const getCurrentStepComponent = (stepId: ManageSubscriptionStepId) => {
    const step = (manageSubscriptionSteps[stepId] as ManageSubscriptionStep)

    if (step.type === 'single-select') {
      return <SingleSelectStep
        stepConfig={step}
        onSelectionChange={(selection) => {
          dispatch({ type: ActionType.COMMIT_MANAGE_SUBSCRIPTION_STEP, stepId, optionId: selection })
        }} />
    } else if (step.id === ManageSubscriptionStepId.PauseOffer) {
      return <PauseOfferStep />
    } else if (step.id === ManageSubscriptionStepId.PauseSubscription) {
      return <PauseSubscriptionStep />
    } else if (step.id === ManageSubscriptionStepId.PauseSubscriptionSuccess) {
      return <PauseSubscriptionSuccessStep />
    } else if (step.id === ManageSubscriptionStepId.UnpauseSubscriptionSuccess) {
      return <UnpauseSubscriptionSuccessStep />
    } else if (step.id === ManageSubscriptionStepId.SpecialOffer) {
      return <SpecialOfferStep />
    } else if (step.id === ManageSubscriptionStepId.SpecialOfferAccepted) {
      return <SpecialOfferAcceptedStep />
    } else if (step.id === ManageSubscriptionStepId.CancelSubscriptionSuccess) {
      return <CancelSubscriptionSuccessStep />
    } else if (step.id === ManageSubscriptionStepId.SubscriptionStatus) {
      return <SubscriptionStatusStep />
    } else if (step.id === ManageSubscriptionStepId.SubscriptionRestorationSuccess) {
      return <SubscriptionRestorationSuccessStep />
    } else if (step.id === ManageSubscriptionStepId.Login) {
      const email = appState.selections?.email
      return <LoginContainer
        email={email}
        onLoginSuccess={() => dispatch({ type: ActionType.HANDLE_LOGIN_SUCCESS })}
      />
    }
  }

  return (
    (currentStepId !== ManageSubscriptionStepId.Intro) ? (
      <Center>
        <Container>
          <StepWrapper>
            <TransitionGroup>
              <CSSTransition
                key={currentStepId}
                timeout={TRANSITION_ANIMATION_DURATION}
                classNames='step-transition'
                unmountOnExit
              >
                <StepContentWrapper>
                  {getCurrentStepComponent(currentStepId)}
                </StepContentWrapper >
              </CSSTransition>
            </TransitionGroup>
          </StepWrapper>
        </Container >
      </Center >
    ) : (
      <Loading />
    )
  )
}

export default ManageSubscriptionContainer
