import styled, { keyframes } from 'styled-components'

const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`

const Skeleton = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200px 100%;
  animation: ${loadingAnimation} 3s infinite;
`

const PackageSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
`

const PackageSkeleton = () => (
  <PackageSkeletonContainer>
    <Skeleton style={{ width: '100%', height: '80px', borderRadius: '16px', marginBottom: '10px' }} />
    <Skeleton style={{ width: '100%', height: '80px', borderRadius: '16px', marginBottom: '10px' }} />
    <Skeleton style={{ width: '100%', height: '80px', borderRadius: '16px', marginBottom: '10px' }} />
    <Skeleton style={{ width: '50%', height: '15px', marginBottom: '20px' }} />
    <Skeleton style={{ width: '100%', height: '70px', marginTop: '30px' }} />
  </PackageSkeletonContainer>
)

export default PackageSkeleton
