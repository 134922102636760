import { produce } from 'immer'
import { BodyFastAction } from '.'
import { ActionType } from './ActionTypes'
import { User as AuthUser } from 'firebase/auth'
import { FirebaseError } from 'firebase/app'

export interface LoginState {
  isLoggedIn: boolean
  user: IUser | null
  subscriptionDetails: IStripeSubscription | null
}

export interface ISignInWithCustomTokenAction { type: ActionType.SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST, ctoken: string, onSuccess: () => void, onError: (error: FirebaseError) => void }
export interface IAuthStateChangedAction { type: ActionType.AUTH_STATE_CHANGED, authData: AuthUser | null }
export interface ILoginSuccessAction { type: ActionType.LOGIN_SUCCESS }
export interface ILogoutSuccessAction { type: ActionType.LOGOUT_SUCCESS }
export interface IFetchSubscriptionDetails { type: ActionType.FETCH_SUBSCRIPTION_DETAILS }
export interface ISetSubscriptionDetails { type: ActionType.SET_SUBSCRIPTION_DETAILS, subscriptionDetails: IStripeSubscription }

export type LoginAction =
  | IAuthStateChangedAction
  | ILoginSuccessAction
  | ILogoutSuccessAction
  | IFetchSubscriptionDetails
  | ISetSubscriptionDetails
  | ISignInWithCustomTokenAction

const INITIAL_STATE: LoginState = {
  isLoggedIn: false,
  user: null,
  subscriptionDetails: null
}

export const LoginReducer = (state: LoginState = INITIAL_STATE, action: BodyFastAction): LoginState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionType.LOGOUT_SUCCESS:
        return INITIAL_STATE

      case ActionType.UPDATE_USER_SUCCESS: {
        draft.user = action.user
        draft.isLoggedIn = !!action.user
        break
      }

      case ActionType.SET_SUBSCRIPTION_DETAILS: {
        draft.subscriptionDetails = action.subscriptionDetails
        break
      }

      default:
        break
    }
  })
}
