import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'
import { options, title } from './Consts'

export enum FastingFeelings {
  steady = 'steady',
  unsteady = 'unsteady',
  low = 'low'
}

export function getFastingFeelings(t: TFunction): OnboardingStep {
  const fastingFeelings = 'fasting_feelings'

  return {
    id: fastingFeelings,
    type: 'single-select',
    wordings: {
      title: t(`${fastingFeelings}.${title}`)
    },
    options: [
      {
        id: FastingFeelings.steady,
        label: t(`${fastingFeelings}.${options}.${FastingFeelings.steady}`),
        emoji: '🙌'
      },
      {
        id: FastingFeelings.unsteady,
        label: t(`${fastingFeelings}.${options}.${FastingFeelings.unsteady}`),
        emoji: '🎢'
      },
      {
        id: FastingFeelings.low,
        label: t(`${fastingFeelings}.${options}.${FastingFeelings.low}`),
        emoji: '🥱'
      }
    ]
  }
}
