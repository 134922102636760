import Logo from 'Components/Logo'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Center = styled.div`
  display: flex;
  justify-content: center;
`
const MaxWidth = styled.div`
  padding: ${({ theme }) => theme.spacing.xLarge};
`

const Spacer = styled.div`
  height: ${({ theme }) => theme.spacing.xLarge};
`

const CookiePolicyContainer: React.FC = () => {
  useEffect(() => {
    window.OneTrust?.initializeCookiePolicyHtml()
  }, [])

  const { t } = useTranslation()

  const openCookieSettings = () => {
    window.OneTrust.ToggleInfoDisplay()
  }

  return (
    <Center>
      <MaxWidth>
        <Spacer />
        <Logo/>
        <div id="ot-sdk-cookie-policy"></div>
        <button style={{ textAlign: 'left' }} onClick={openCookieSettings}>{t('cookiesButton.showCookieSettings')}</button>
      </MaxWidth>
    </Center>
  )
}

export default CookiePolicyContainer
