import { options, title } from 'Containers/steps/Consts'
import { t } from 'i18next'
import { ManageSubscriptionStep, ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'

export function getSubscriptionRestorationSuccessStep(): ManageSubscriptionStep {
  return {
    id: ManageSubscriptionStepId.SubscriptionRestorationSuccess,
    type: 'custom',
    allowNext: true,
    wordings: {
      title: t(`${ManageSubscriptionStepId.SubscriptionRestorationSuccess}.${title}`)
    }
  }
}
