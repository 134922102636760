import ogAxios from 'axios'

export const axios = ogAxios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:5001/bodyfast-development/us-central1/api' : ''
})

export const fetcher = async (urlKey: string) => {
  const res = await axios({
    url: urlKey,
    method: 'GET'
  })

  return res.data
}
