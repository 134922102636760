import styled from 'styled-components'

const StarsWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`

const Star = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: #ffbb1b;
`

const StarRating: React.FC = () => {
  const filledStars = Math.round(5)

  return (
    <StarsWrapper>
      {[...Array(filledStars)].map((_, i) => (
        <Star key={`filled-${i}`} >
          ★
        </Star>
      ))}
    </StarsWrapper>
  )
}

export default StarRating
