import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Button from './Button'
import Modal from './Modal'

const NotNowButton = styled.button`
  background: transparent;
  color: ${props => props.theme.colors.primary};
  border: none;
  border-radius: 4px;
  padding: ${props => props.theme.spacing.small};
  width: 100%;
  cursor: pointer;
`

interface EmailAlreadySubscribedModalProps {
  onLoginTap: () => void
  onClose: () => void
}

const EmailAlreadyInUseModal: React.FC<EmailAlreadySubscribedModalProps> = ({ onLoginTap, onClose }) => {
  const { t } = useTranslation()
  return (
    <Modal onClose={onClose}>
      <h2>{t('emailAlreadyInUseModal.title')}</h2>
      <p>{t('emailAlreadyInUseModal.description')}</p>
      <Button
        margin='0 0 8px 0'
        onClick={onLoginTap}
      >
        {t('emailAlreadyInUseModal.signInButton')}
      </Button>
      <NotNowButton onClick={onClose}>{t('emailAlreadyInUseModal.cancelButton')}</NotNowButton>
    </Modal>
  )
}

export default EmailAlreadyInUseModal
