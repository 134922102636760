import * as Sentry from '@sentry/react'
import { onboarding } from 'Containers/steps/Consts'
import { useTranslation } from 'react-i18next'
import 'react-multi-date-picker/styles/colors/teal.css'
import styled from 'styled-components'
import BmiChart from './BMIChart'
import InfoCard from './InfoCard.component'
import Logo from './Logo'

const Container = styled.div`
  margin: auto;
  margin-top: ${({ theme }) => theme.spacing.medium};
  width: fit-content;
  align-items: center;
  text-align: center;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BmiWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.spacing.small};
`

const BmiCard = styled.div`
  margin: ${({ theme }) => theme.spacing.medium};
  width: 100%;
`

const CardTitle = styled.div`
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;

  h2 {
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: bold;
  margin: 0 0 0 ${({ theme }) => theme.spacing.medium};
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.colors.lightText};
    margin: 0 ${({ theme }) => theme.spacing.medium} 0 0;
  }
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.medium};
  `

const ListTile = styled.div`
  display: flex;
  align-items: start;
  text-align: start;
  padding: ${({ theme }) => theme.spacing.small} 0px;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.medium};
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: bold;
`

const Subtitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.lightText};
`

const InfoCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
  width: 100%;
`

interface BmiStepProps {
  weight: any
  height: any
  lifestyle: 'energetic' | 'active' | 'inactive'
  typeOfEater: 'gourmand' | 'meal_skipper' | 'healthy'
  fastingMotivation: 'average' | 'high' | 'low'
}

interface ListTileComponentProps {
  icon: string
  title: string
  subtitle: string
}

const ListTileComponent: React.FC<ListTileComponentProps> = ({ title, subtitle }) => {
  return (
    <ListTile>
      <IconWrapper>
      </IconWrapper>
      <TextWrapper>
        <Subtitle>{subtitle}</Subtitle>
        <Title>{title}</Title>
      </TextWrapper>
    </ListTile>
  )
}

const BmiStep: React.FC<BmiStepProps> = ({ weight, height, fastingMotivation, lifestyle, typeOfEater }) => {
  const bmi = weight / (height * height)

  const { t } = useTranslation(onboarding)

  let lifestyleText = ''
  switch (lifestyle) {
    case 'energetic':
      lifestyleText = t('bmi.lifestyle.energetic')
      break
    case 'active':
      lifestyleText = t('bmi.lifestyle.active')
      break
    case 'inactive':
      lifestyleText = t('bmi.lifestyle.inactive')
      break
    default:
      lifestyleText = t('bmi.lifestyle.lazy')
      Sentry.captureException(new Error('lifestyleText not found'))
  }

  let typeOfEaterText = ''
  switch (typeOfEater) {
    case 'gourmand':
      typeOfEaterText = t('bmi.typeOfEater.gourmand')
      break
    case 'meal_skipper':
      typeOfEaterText = t('bmi.typeOfEater.meal_skipper')
      break
    case 'healthy':
      typeOfEaterText = t('bmi.typeOfEater.healthy')
      break
    default:
      typeOfEaterText = t('bmi.typeOfEater.gourmand')
      Sentry.captureException(new Error('typeOfEaterText not found'))
  }

  let fastingMotivationText = ''
  switch (fastingMotivation) {
    case 'average':
      fastingMotivationText = t('bmi.fastingMotivation.average')
      break
    case 'high':
      fastingMotivationText = t('bmi.fastingMotivation.high')
      break
    case 'low':
      fastingMotivationText = t('bmi.fastingMotivation.low')
      break
    default:
      fastingMotivationText = t('bmi.fastingMotivation.average')
      Sentry.captureException(new Error('fastingMotivationText not found'))
  }

  return (
    <>
      <Logo />
      <Container>
        <Wrapper>
          <h1>{t('bmi.title')}</h1>
          <BmiWrapper>
            <BmiCard>
              <CardTitle>
                <h2>{t('bmi.cardTitle')}</h2>
                <p>{t('bmi.normal')}</p>
              </CardTitle>
              <BmiChart bmi={bmi} />
            </BmiCard>
          </BmiWrapper>
          <DescriptionWrapper>
            <ListTileComponent icon={'faCoffee'} title={lifestyleText} subtitle={t('bmi.lifestyleTitle')} />
            <ListTileComponent icon={'faCoffee'} title={typeOfEaterText} subtitle={t('bmi.typeOfEaterTitle')} />
            <ListTileComponent icon={'faCoffee'} title={fastingMotivationText} subtitle={t('bmi.fastingMotivationTitle')} />
          </DescriptionWrapper>
          <InfoCardWrapper>
            <InfoCard
              color='#fef9eb'
              title={t('bmi.risks_title')}
              content={t('bmi.risks_content')}
            />
          </InfoCardWrapper>

        </Wrapper>
      </Container >
    </>
  )
}

export default BmiStep
