import { isIos } from 'Lib'
import { feetInchToCentimeters, getImperialHeight, removeLeadingZeros } from 'Lib/SelectionsUtils'
import { t } from 'i18next'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'
import InputWrapper from './InputWrapper'
import { TRANSITION_ANIMATION_DURATION } from 'Lib/Constants'

const InputSection = styled.div`
  margin: auto;
  margin-top: ${({ theme }) => theme.spacing.medium};
  width: fit-content;
`

const Input = styled.input`
  display:flex;
  justify-content: center;
  border: none;
  background-color: transparent;
  border-radius: 0;
  outline: none;
  font-size: ${({ theme }) => theme.fontSizes.xLarge};
  width: 100px;
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: calc(30%);
  }
`

const SelectedUnit = styled.span`
  margin-left:auto;
  width: ${({ theme }) => theme.spacing.xLarge};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0;
`
export interface HeightInputRef {
  clearInputs: () => void
}
interface HeightInputProps {
  placeholder: string
  onHeightChange: (height: IUserHeight | undefined) => void
  setErrorMessage: (message: string) => void
  unit: IUnitSystem
  initialHeight?: IUserHeight
}

const FT_MIN_HEIGHT_IN_CM = feetInchToCentimeters({ feet: 3, inch: 3 })
const FT_MAX_HEIGHT_IN_CM = feetInchToCentimeters({ feet: 8, inch: 2 })
const MIN_HEIGHT_IN_CM = 100
const MAX_HEIGHT_IN_CM = 250

const HeightInput: React.ForwardRefExoticComponent<HeightInputProps & React.RefAttributes<any>> = forwardRef(({ placeholder, unit, setErrorMessage, onHeightChange, initialHeight }, ref) => {
  const [cmInputValue, setInputValue] = useState<number>()
  const [inInputValue, setInInputValue] = useState<number>()
  const [ftInputValue, setFtInputValue] = useState<number>()
  // without this `!!initialWeight?.original?.value` the error message will not show up
  // when the user navigates back to this step and changes the input to an invalid value
  const [dirty, setDirty] = useState(!!initialHeight?.original?.value)
  const [allowShowError, setAllowShowError] = useState(!!initialHeight?.original?.value)

  const cmInputRef = useRef<HTMLInputElement>(null)
  const ftInputRef = useRef<HTMLInputElement>(null)
  const inInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (unit === 'ft/lbs') {
      if (!initialHeight?.inCm?.value) return

      const imperialHeight = getImperialHeight(initialHeight?.inCm?.value)
      setFtInputValue(imperialHeight.feet)
      setInInputValue(imperialHeight.inch)
    } else {
      setInputValue(initialHeight?.inCm?.value)
    }
  }, [])

  // Exposing clearInputs function to parent component
  // so I can clear the inputs when the user changes the unit
  useImperativeHandle(ref, () => ({
    clearInputs
  }))

  const handleCmInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = removeLeadingZeros(e.target.value.trim())
    if (newValue.length === 3) setAllowShowError(true)
    if (newValue.length > 3) return

    const numericValue = parseInt(newValue)

    setInputValue(numericValue)
    const { error, height } = validateCmInput(numericValue)

    if (error) {
      onHeightChange(undefined)
    } else {
      onHeightChange(height)
    }
  }

  const handleInInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = removeLeadingZeros(e.target.value.trim())
    if (newValue.length === 2) setAllowShowError(true)
    if (newValue.length > 2) return

    const numericValue = parseInt(newValue)

    setInInputValue(numericValue)
    const { error, height } = validateFtInInput(ftInputValue, numericValue)

    if (error) {
      onHeightChange(undefined)
    } else {
      onHeightChange(height)
    }
  }

  const handleFtInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = removeLeadingZeros(e.target.value.trim())
    if (newValue.length === 1) setAllowShowError(true)
    if (newValue.length > 1) return

    const numericValue = parseInt(newValue)

    setFtInputValue(numericValue)
    const { error, height } = validateFtInInput(numericValue, inInputValue)

    if (error) {
      onHeightChange(undefined)
    } else {
      onHeightChange(height)
    }
  }

  const handleShowError = (error?: string) => {
    if (allowShowError && error) {
      setErrorMessage(error)
    }
  }

  const validateFtInInput = (ft?: number, inches?: number): {
    error?: string
    height?: IUserHeight
  } => {
    if (!dirty) {
      return { error: undefined }
    }

    ft = ft || 0
    inches = inches || 0

    let error = ''
    const totalHeightInCm = feetInchToCentimeters({ feet: ft, inch: inches })

    if (totalHeightInCm < FT_MIN_HEIGHT_IN_CM || totalHeightInCm > FT_MAX_HEIGHT_IN_CM) {
      error = t('height.error.ft')
    }

    if (error) {
      handleShowError(error)
      return { error }
    }
    handleShowError('')

    return {
      height: {
        original: { value: totalHeightInCm, unit: 'ft/lbs' },
        inCm: { value: totalHeightInCm, unit: 'cm/kg' }
      }
    }
  }

  const validateCmInput = (cm?: number): {
    error?: string
    height?: IUserHeight
  } => {
    if (!dirty || !cm) {
      return { error: undefined }
    }

    let error = ''

    if (cm < MIN_HEIGHT_IN_CM || cm > MAX_HEIGHT_IN_CM) {
      error = t('height.error.cm')
    }

    if (error) {
      handleShowError(error)
      return { error }
    }
    handleShowError('')

    return {
      height: {
        original: { value: cm, unit: 'cm/kg' },
        inCm: { value: cm, unit: 'cm/kg' }
      }
    }
  }

  const clearInputs = () => {
    setDirty(false)
    setAllowShowError(false)
    setErrorMessage('')
    setInputValue(undefined)
    setInInputValue(undefined)
    setFtInputValue(undefined)
    onHeightChange(undefined)
  }

  useEffect(() => {
    if (unit === 'cm/kg') {
      validateCmInput(cmInputValue)
    } else {
      validateFtInInput(ftInputValue, inInputValue)
    }
  }, [allowShowError])

  useEffect(() => {
    // Focus on input on non-iOS devices
    // because on iOS devices the keyboard won't show up
    if (isIos()) return
    if (unit === 'cm/kg' && cmInputRef.current) {
      setTimeout(() => {
        // Don't focus before transition animation is finished.
        // Otherwise, it causes this bug on Android: https://bodyfastworkspace.slack.com/archives/C04J5V1PN8G/p1703756108300509?thread_ts=1703755034.066339&cid=C04J5V1PN8G
        cmInputRef.current?.focus()
      }, TRANSITION_ANIMATION_DURATION)
    } else if (unit === 'ft/lbs' && ftInputRef.current) {
      setTimeout(() => {
        // Don't focus before transition animation is finished.
        // Otherwise, it causes this bug on Android: https://bodyfastworkspace.slack.com/archives/C04J5V1PN8G/p1703756108300509?thread_ts=1703755034.066339&cid=C04J5V1PN8G
        ftInputRef.current?.focus()
      }, TRANSITION_ANIMATION_DURATION)
    }
  }, [unit])

  const onFtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFtInputChange(e)

    const value = e.target.value
    const valueAsNumber = Number(value)
    if (valueAsNumber > 2 && valueAsNumber < 9 && inInputRef.current) {
      inInputRef.current.focus()
    }
  }

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.placeholder = ''
  }

  const cmOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.placeholder = placeholder
    setAllowShowError(true)
  }

  const inOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.placeholder = placeholder
    setAllowShowError(true)
  }

  const ftOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.placeholder = placeholder
    setAllowShowError(true)
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setDirty(true)
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault()
    }
  }

  return (unit === 'cm/kg'
    ? <InputSection>
      <InputWrapper>
        <Input
          key={'cm'}
          ref={cmInputRef}
          type="number"
          value={cmInputValue}
          maxLength={3}
          onChange={handleCmInputChange}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
          onFocus={onFocus}
          onBlur={cmOnBlur}
        />
        <SelectedUnit>{t('height.cm')}</SelectedUnit>
      </InputWrapper>
    </InputSection>
    : <InputSection>
      <InputWrapper>
        <Input
          key={'ft'}
          ref={ftInputRef}
          type="number"
          value={ftInputValue}
          onChange={onFtChange}
          placeholder={placeholder}
          maxLength={1}
          onKeyPress={onKeyPress}
          onFocus={onFocus}
          onBlur={ftOnBlur}
        />
        <SelectedUnit>{t('height.ft')}</SelectedUnit>
        <Input
          key={'in'}
          type="number"
          ref={inInputRef}
          value={inInputValue}
          maxLength={2}
          onChange={handleInInputChange}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
          onFocus={onFocus}
          onBlur={inOnBlur}
        />
        <SelectedUnit>{t('height.in')}</SelectedUnit>
      </InputWrapper>
    </InputSection>
  )
})

export default HeightInput
