import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getWorkloadFrequency(t: TFunction): OnboardingStep {
  const workloadFrequency = 'workload_frequency'
  const nineToFive = '9_to_5'
  const shifts = 'shifts'
  const flexible = 'flexible'
  const dontWork = 'dont_work'

  return {
    id: workloadFrequency,
    type: 'single-select',
    wordings: {
      title: t(`${workloadFrequency}.${title}`)
    },
    options: [
      {
        id: nineToFive,
        label: t(`${workloadFrequency}.${options}.${nineToFive}`),
        emoji: '👨‍💻'
      },
      {
        id: shifts,
        label: t(`${workloadFrequency}.${options}.${shifts}`),
        emoji: '🔃'
      },
      {
        id: flexible,
        label: t(`${workloadFrequency}.${options}.${flexible}`),
        emoji: '📝'
      },
      {
        id: dontWork,
        label: t(`${workloadFrequency}.${options}.${dontWork}`),
        emoji: '🏡'
      }
    ]
  }
}
