import { t } from 'i18next'
import { getAge, getBMI, getBadHabits, getBreakfastTime, getCurrentBodyType, getDesiredBodyType, getDinnerTime, getEmail, getFastingFeelings, getFastingKnowledge, getFastingMotivation, getGoal, getHeight, getHomeOrOutside, getLastHappyWeight, getLunchTime, getOnePlan, getOutOfBreath, getPerfectWeight, getPlanIsReady, getProgressStep, getSleepQuality, getSpecialOccasion, getSpecialOccasionDate, getTargetZones, getTrackedHours, getWalkingTime, getWaterIntake, getWeight, getWorkActivityLevel, getWorkloadFrequency } from './steps'
import { getGenderSelection } from './steps/GenderSelection'
import { getReceiveEmail } from './steps/ReceiveEmail'
import { getReinforcementBeforeAfter } from './steps/ReinforcementBeforeAfter'
import { getReinforcementScientificMethod } from './steps/ReinforcementScientificMethod'
import { existingEmailLogin } from './steps/ExistingEmailLogin'
import { getAgeRange } from './steps/AgeRange'

interface IGetStepConfigsArgs {
  gender?: IGender
  onboardingStepsVariant?: string
}

function getStepConfigs({ gender, onboardingStepsVariant }: IGetStepConfigsArgs) {
  // First step is always considered to be the gender selection screen. Should this change, appropriate changes should be made in the getFirstStep function as well.
  const genderSelection = getGenderSelection(t)
  const goal = getGoal(t, gender)
  const ageRange = getAgeRange(t)
  const trackedHours = getTrackedHours(t)
  const desiredBodyType = getDesiredBodyType(t, gender)
  const currentBodyType = getCurrentBodyType(t, gender)
  const targetZones = getTargetZones(t, gender)
  const lastHappyWeight = getLastHappyWeight(t)
  const breakfastTime = getBreakfastTime(t)
  const lunchTime = getLunchTime(t)
  const dinnerTime = getDinnerTime(t)
  const homeOrOutside = getHomeOrOutside(t)
  const reinforcementScientificMethod = getReinforcementScientificMethod(t)
  const fastingFeelings = getFastingFeelings(t)
  const workloadFrequency = getWorkloadFrequency(t)
  const workActivityLevel = getWorkActivityLevel(t)
  const outOfBreath = getOutOfBreath(t)
  const walkingTime = getWalkingTime(t)
  const waterIntake = getWaterIntake(t)
  const sleepQuality = getSleepQuality(t)
  const badHabits = getBadHabits(t)
  const reinforcementBeforeAfter = getReinforcementBeforeAfter(t, gender)
  const fastingKnowledge = getFastingKnowledge(t)
  const fastingMotivation = getFastingMotivation(t)
  const height = getHeight(t)
  const weight = getWeight(t)
  const perfectWeight = getPerfectWeight(t)
  const age = getAge(t)
  const bmi = getBMI(t)
  const specialOccasion = getSpecialOccasion(t)
  const specialOccasionDate = getSpecialOccasionDate(t)
  const theOnePlan = getOnePlan(t)
  const progressStep = getProgressStep(t)
  const email = getEmail(t)
  const existingEmail = existingEmailLogin(t)
  const receiveEmail = getReceiveEmail(t)
  const planIsReady = getPlanIsReady(t)

  const stepListV1 = [
    genderSelection,
    trackedHours,
    goal,
    desiredBodyType,
    currentBodyType,
    targetZones,
    lastHappyWeight,
    breakfastTime,
    lunchTime,
    dinnerTime,
    homeOrOutside,
    reinforcementScientificMethod,
    fastingFeelings,
    workloadFrequency,
    workActivityLevel,
    outOfBreath,
    walkingTime,
    waterIntake,
    sleepQuality,
    badHabits,
    reinforcementBeforeAfter,
    fastingKnowledge,
    fastingMotivation,
    height,
    weight,
    perfectWeight,
    age,
    bmi,
    specialOccasion,
    specialOccasionDate,
    theOnePlan,
    progressStep,
    email,
    existingEmail,
    receiveEmail,
    planIsReady
  ]

  const stepListV2 = [
    genderSelection,
    trackedHours,
    goal,
    ageRange,
    desiredBodyType,
    currentBodyType,
    targetZones,
    lastHappyWeight,
    breakfastTime,
    lunchTime,
    dinnerTime,
    homeOrOutside,
    reinforcementScientificMethod,
    fastingFeelings,
    workloadFrequency,
    workActivityLevel,
    outOfBreath,
    walkingTime,
    waterIntake,
    sleepQuality,
    badHabits,
    reinforcementBeforeAfter,
    fastingKnowledge,
    fastingMotivation,
    height,
    weight,
    perfectWeight,
    bmi,
    specialOccasion,
    specialOccasionDate,
    theOnePlan,
    progressStep,
    email,
    existingEmail,
    receiveEmail,
    planIsReady
  ]

  return onboardingStepsVariant === 'v2' ? stepListV2 : stepListV1
}

export function getFirstStep() {
  return getGenderSelection(t)
}

export default getStepConfigs
