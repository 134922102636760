import React from 'react'
import styled from 'styled-components'
import { OnboardingStep } from '../types/onboardingTypes'
import StepItemImage from './StepItemImage'
import StepSubtitle from './StepSubtitle.component'
import StepTitle from './StepTitle.component'
import OptionWrapper from './OptionWrapper'
import { ManageSubscriptionStep } from 'types/manageSubscriptionTypes'
import Button from 'Components/Button'
import SkipComponent from './SkipComponent'

interface SingleSelectStepProps {
  stepConfig: OnboardingStep | ManageSubscriptionStep
  onSelectionChange: (selectedOptionId: string) => void
  onSkip?: () => void
}

const Emoji = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin-left: ${({ theme }) => theme.spacing.small};
`

const OptionsSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing.medium};
`

const SingleSelectStep: React.FC<SingleSelectStepProps> = ({ stepConfig, onSelectionChange, onSkip }) => {
  return (
    <div>
      <StepTitle>{stepConfig.wordings.title}</StepTitle>
      <StepSubtitle>{stepConfig.wordings.subtitle}</StepSubtitle>
      <OptionsSection>
        {stepConfig.options?.map((option) => {
          return option?.buttonType === 'clear'
            ? <Button key={option.id} theme='text' onClick={() => onSelectionChange(option.id)}>{option.label}</Button>
            : (
              <OptionWrapper key={option.id} onClick={() => onSelectionChange(option.id)}>
                <span>{option.label}</span>
                {(option.maleImage ?? option.femaleImage) && <StepItemImage src={option.maleImage ?? option.femaleImage} alt={option.label} />}
                {option.emoji && <Emoji>{option.emoji}</Emoji>}
              </OptionWrapper>
            )
        })}
      </OptionsSection>
      {(stepConfig.allowSkip && onSkip) &&
          <SkipComponent onClick={onSkip} />
      }
    </div>
  )
}

export default SingleSelectStep
