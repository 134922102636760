import GooglePayButton from '@google-pay/button-react'
import { useStripe } from '@stripe/react-stripe-js'
import { useAppState } from 'AppContextProvider'
import { ActionType } from 'Reducers'
import invariant from 'invariant'
import React from 'react'
import { useDispatch } from 'store'
import { CustomPackage } from 'types/onboardingTypes'

interface CreditCardButtonProps {
  onError: (err: any) => void
  selectedPackage: CustomPackage
}

const GooglePay: React.FC<CreditCardButtonProps> = ({ selectedPackage, onError }) => {
  const stripe = useStripe()
  const dispatch = useDispatch()
  const { appState } = useAppState()

  const handleSubmit = async (event: Event) => {
    invariant(appState.stripePrices, 'Stripe prices must exist')

    event.preventDefault()
    dispatch({
      type: ActionType.CREATE_STRIPE_SESSION_REQUEST,
      packages: appState.stripePrices,
      selectedPackage,
      onSuccess: (sessionId: string) => {
        void stripe?.redirectToCheckout({ sessionId: sessionId })
      },
      onError: (error: string) => {
        onError(error)
      }
    })
  }

  // The data here is not real, since we use stipe for payment.
  // This is just a placeholder for the Google Pay button library.
  return (
    <GooglePayButton
      environment='PRODUCTION'
      onClick={handleSubmit}
      buttonColor="default"
      buttonType="plain"
      buttonSizeMode="fill"
      style={{ width: '100%', height: 50 }}
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['MASTERCARD', 'VISA']
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: 'example',
                gatewayMerchantId: 'exampleGatewayMerchantId'
              }
            }
          }
        ],
        merchantInfo: {
          merchantId: '12345678901234567890',
          merchantName: 'Demo Merchant'
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: '100.00',
          currencyCode: 'USD',
          countryCode: 'US'
        }
      }}

    />
  )
}

export default GooglePay
