import { formatPriceString, getDiscountPercentage } from 'Lib'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CustomPackage, PackageTagId } from 'types/onboardingTypes'

const TotalPriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-radius: ${props => props.theme.spacing.small};
  padding: ${props => props.theme.spacing.zero};
  hr {
    height: 1px;
    border: 0;
    background-color: ${props => props.theme.colors.lightGray}
  }
`

const TotalPriceDescription = styled.div`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: bold;
  color: ${props => props.theme.colors.text};
`

const TotalPriceValue = styled.div`
  font-size:  ${props => props.theme.fontSizes.medium};
`

const OriginalPriceLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`

const OriginalSubscriptionPriceContainer = styled.div`
hr {
  background: ${props => props.theme.colors.lightGray};
  border: 0;
  height: 1px;
}
`

const LineItemDescription = styled.span`
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.fontSizes.small};
`

const LineItemPrice = styled.strong`
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.fontSizes.small};
`

const LineItemDiscount = styled.strong`
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.small};
`

const AmountSavedText = styled.div`
  color: ${props => props.theme.colors.primary};
  margin-top: ${props => props.theme.spacing.xsmall};
  font-size: ${props => props.theme.fontSizes.small};
  text-align: right;
`

const TotalPriceContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing.medium};
`

interface TotalPriceProps {
  priceString: string
  discountedPriceString: string | null
  selectedPackage: CustomPackage
  recurringPackage?: CustomPackage
}

const TotalPrice: React.FC<TotalPriceProps> = ({ priceString, discountedPriceString, selectedPackage, recurringPackage }) => {
  const { t } = useTranslation()

  const discountAmount = selectedPackage.discountedPrice
    ? formatPriceString({ price: selectedPackage.price - selectedPackage.discountedPrice, currency: selectedPackage.currency, withFractionDigits: true })
    : null

  const discountPercentage = getDiscountPercentage(selectedPackage)

  const mainPlanPrice = recurringPackage ? formatPriceString({ price: recurringPackage.price, currency: recurringPackage.currency, withFractionDigits: true }) : null

  const renderLineItems = () => {
    if (discountAmount) {
      return (
        <OriginalSubscriptionPriceContainer>
          <hr />
          <OriginalPriceLine>
            <LineItemDescription>
              {t(selectedPackage.tagId === PackageTagId.Trial ? 'modal.trialPlan' : selectedPackage.numberOfPeriods === 1 ? 'modal.packageDuration' : 'modal.packageDurationPlural', { numberOfPeriods: selectedPackage.numberOfPeriods })}
            </LineItemDescription>
            <LineItemPrice>{priceString}</LineItemPrice>
          </OriginalPriceLine>
          <OriginalPriceLine>
            <LineItemDescription>
              {t('modal.introductoryDiscount', { percentage: discountPercentage })}
            </LineItemDescription>
            <LineItemDiscount>- {discountAmount}</LineItemDiscount>
          </OriginalPriceLine>
          <hr />
        </OriginalSubscriptionPriceContainer>
      )
    } else if (selectedPackage.tagId === PackageTagId.Trial) {
      return (
        <OriginalSubscriptionPriceContainer>
          <hr />
          <OriginalPriceLine>
            <LineItemDescription>
              {t('modal.trialPlan', { numberOfPeriods: selectedPackage.numberOfPeriods })}
            </LineItemDescription>
            <LineItemPrice>{priceString}</LineItemPrice>
          </OriginalPriceLine>
          {mainPlanPrice && (
            <OriginalPriceLine>
              <LineItemDescription>
                {t(selectedPackage.numberOfPeriods === 1 ? 'modal.packageDuration' : 'modal.packageDurationPlural', { numberOfPeriods: selectedPackage.numberOfPeriods })}
              </LineItemDescription>
              <LineItemPrice>{mainPlanPrice}</LineItemPrice>
            </OriginalPriceLine>
          )}
          <hr />
        </OriginalSubscriptionPriceContainer>
      )
    }

    return null
  }

  const renderTotalPriceString = () => {
    if (selectedPackage.tagId === PackageTagId.Trial) {
      return t('modal.periodWeek', { interval: selectedPackage.interval, intervalCount: selectedPackage.numberOfPeriods })
    }
    return t(selectedPackage.discountedPrice ? (selectedPackage.numberOfPeriods === 1 ? 'modal.periodIntroductoryOneMonth' : 'modal.periodIntroductory') : 'modal.period', { interval: selectedPackage.interval, intervalCount: selectedPackage.numberOfPeriods })
  }

  return (
    <TotalPriceContainer>
      {renderLineItems()}
      <TotalPriceWrapper>
        <TotalPriceDescription>{t('modal.total')}</TotalPriceDescription>
        <TotalPriceValue>
          <strong>{discountedPriceString ?? priceString}</strong>
          <span> {renderTotalPriceString()}</span>
        </TotalPriceValue>
      </TotalPriceWrapper>
      {discountAmount && (
        <AmountSavedText>{t('modal.amountSaved', { discountAmount, discountPercentage })}</AmountSavedText>
      )}
    </TotalPriceContainer>
  )
}

export default TotalPrice
