import { Elements } from '@stripe/react-stripe-js'
import PaymentWrapper from 'Components/Payment/PaymentWrapper'
import React, { useEffect } from 'react'
import { stripeService } from 'store'

const PaymentContainer: React.FC = () => {
  useEffect(() => {
    // Users can reach to the payment screen from different places. We want to use this as an umbrella event.
    // E.g, as an activation event in growthbook
    logEvent('web_paymentMounted')
  }, [])

  return (
    <Elements stripe={stripeService}>
      <PaymentWrapper />
    </Elements>
  )
}

export default PaymentContainer
