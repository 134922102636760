import { images } from 'images'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PaymentColorPackage, PaymentColorScheme } from 'types/onboardingTypes'

const SummaryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: ${({ theme }) => theme.spacing.medium};

  p {
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.colors.text};
    margin: 5px 0;
  }
`

const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  @media(min-width: 768px) {
    width: 50%
  }
`

const SummaryTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  fill: ${({ theme }) => theme.colors.primary};

  img {
    width: ${({ theme }) => theme.spacing.medium};
    height: ${({ theme }) => theme.spacing.medium};
  }

  h3 {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    font-weight: bold;
    text-align: center;
    margin: 5px 10px;
    color: ${({ theme }) => theme.colors.text};
  }
`

const VerticalDivider = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.text};
  padding: 5px 0px;
  height: 50px;
`

interface Props {
  targetWeight: number
  unit: string
  fastingLevel: string
  colorPackage: PaymentColorPackage
}

const getStatusImage = (colorPackage: PaymentColorPackage, fastingLevel: string) => {
  return fastingLevel === 'beginner' ? colorPackage.statusLow : colorPackage.statusMedium
}

const Summary: React.FC<Props> = ({ targetWeight, fastingLevel, unit, colorPackage }) => {
  const { t } = useTranslation()

  const level = fastingLevel === 'beginner' ? 'beginner' : 'intermediate'

  const statusImage = getStatusImage(colorPackage, fastingLevel)

  const scaleImage = colorPackage.scale
  return (
    <SummaryWrapper>
      <SummaryItem>
        <p>{t('summary.targetWeight')}</p>
        <SummaryTitle>
          <img src={scaleImage} alt="weight" />
          <h3>{targetWeight} {unit}</h3>
        </SummaryTitle>
      </SummaryItem>
      <VerticalDivider />
      <SummaryItem>
        <p>{t('summary.fastingLevel.title')}</p>
        <SummaryTitle>
          <img src={statusImage} alt="status" />
          <h3>{t(`summary.fastingLevel.${level}`)}</h3>
        </SummaryTitle>
      </SummaryItem>

    </SummaryWrapper>
  )
}

export default Summary
