import Button from 'Components/Button'
import IconInput from 'Components/IconInput'
import Modal from 'Components/Modal'
import { isValidEmail, validateEmail } from 'Lib/FormsUtils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaUser } from 'react-icons/fa'
import styled from 'styled-components'

const Error = styled.span`
  color: red;
  margin-left: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSizes.small};
`

interface ResetPasswordModalProps {
  closeModal: () => void
  sendResetPasswordEmail: (email: string) => void
  email: string
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({ closeModal, email, sendResetPasswordEmail }) => {
  const { t } = useTranslation()

  const [emailInput, setEmailInput] = useState(email)
  const [emailError, setEmailError] = useState<string | undefined>(undefined)
  const [emailBlurred, setEmailBlurred] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const onChange = (e: any) => {
    setSubmitted(false)

    const value = e.target.value.trim()
    setEmailInput(value)

    const emailError = validateEmail(value)
    setEmailError(emailError ?? '')
  }

  const shouldShowEmailError = emailBlurred || submitted

  return (
    <Modal onClose={closeModal}>
      <h2>{t('resetPasswordModal.title')}</h2>
      <p>{t('resetPasswordModal.description')}</p>
      <IconInput
        icon={<FaUser />}
        name='email'
        placeholder={t('resetPasswordModal.email')!}
        onChange={onChange}
        value={emailInput}
        onBlur={() => setEmailBlurred(true)}
        onFocus={() => setEmailBlurred(false)}
        hasError={shouldShowEmailError && !!emailError}
        isInputValid={isValidEmail(emailInput)}

      />
      {shouldShowEmailError && emailError && <Error>{emailError}</Error>}

      <Button
        margin='20px 0 8px 0'
        onClick={() => {
          setSubmitted(true)
          const emailError = validateEmail(emailInput)
          if (emailError) {
            setEmailError(emailError)
          } else {
            sendResetPasswordEmail(emailInput)
            closeModal()
          }
        }}
      >
        {t('resetPasswordModal.sendButton')}
      </Button>
    </Modal >
  )
}

export default ResetPasswordModal
