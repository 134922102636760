import FacebookPixel from 'react-facebook-pixel'
import { appsFlyerService, gTagService } from 'store'
import { initTwitterService, revokeTwitterConsent } from './Twitter.service'

export enum CookieCategory {
  // https://my.onetrust.com/articles/en_US/Knowledge/UUID-8102e851-d860-d465-d8d6-b1d636d68eb9
  STRICTLY_NECESSARY = 'C0001',
  PERFORMANCE = 'C0002',
  FUNCTIONAL = 'C0003',
  TARGETING = 'C0004',
  SOCIAL_MEDIA = 'C0005',
  VIDEO = 'VID1'
}

let cookieConsentGranted = false

const handleConsentChange = (cookies: string | string[]) => {
  if (typeof cookies === 'string') {
    cookies = cookies.split(',')
  }

  logEvent('web_cookie_consent_update', {
    performance: cookies.includes(CookieCategory.PERFORMANCE) ? 'granted' : 'denied',
    targeting: cookies.includes(CookieCategory.TARGETING) ? 'granted' : 'denied'
  })
  if (!cookieConsentGranted &&
    cookies.includes(CookieCategory.PERFORMANCE) &&
    cookies.includes(CookieCategory.TARGETING)) {
    logEvent('web_cookie_consent_granted')
    cookieConsentGranted = true
  }

  /* ------------------ Handle Performance Cookies -------------------- */

  // GTag services are handled separately below, even though they are performance cookies
  if (cookies.includes(CookieCategory.PERFORMANCE)) {
    appsFlyerService?.grantConsent()
    ttq?.grantConsent()
  } else {
    appsFlyerService?.revokeConsent()
    ttq?.revokeConsent()
  }

  /* ------------------ Handle Targeting Cookies -------------------- */

  if (cookies.includes(CookieCategory.TARGETING)) {
    FacebookPixel.grantConsent()
    initTwitterService()
    // initPinterestService()
  } else {
    FacebookPixel.revokeConsent()
    revokeTwitterConsent()
    // revokePinterestService()
  }

  gTagService.updateConsent(
    cookies.includes(CookieCategory.PERFORMANCE),
    cookies.includes(CookieCategory.TARGETING)
  )
}

let isOnetrustActiveGroupsInitiated = false
let isOneTrustInitiated = false

export const initCookieService = () => {
  const interval = setInterval(() => {
    // Sometimes, window.OneTrust and window.OnetrustActiveGroups is initiated a bit late. This is why, we need this setInterval

    if (window.OneTrust && !isOneTrustInitiated) {
      // Set listener to track user's choice on cookie banner
      window.OneTrust.OnConsentChanged((data: any) => {
        handleConsentChange(data.detail)
      })

      isOneTrustInitiated = true
      window.OneTrust.initializeCookiePolicyHtml() // This call is needed to be able to show the cookie list in privacy policy
    }

    if (window.OnetrustActiveGroups && !isOnetrustActiveGroupsInitiated) {
      // Check the cookie state when webapp started
      handleConsentChange(window.OnetrustActiveGroups)
      isOnetrustActiveGroupsInitiated = true
    }

    if (isOneTrustInitiated && isOnetrustActiveGroupsInitiated) {
      // stop the interval when we initiate both
      clearInterval(interval)
    }
  }, 100)
}
