import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'
import { title, wordings } from './Consts'

export function getWeight(t: TFunction): OnboardingStep {
  const weight = 'weight.'

  return {
    id: 'weight',
    type: 'weight',
    wordings: {
      title: t(`${weight}${wordings}${title}`),
      placeholder: ''
    }
  }
}
