import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import styled from 'styled-components'

// the fade out animation is hard to inject using only styled-components / inline-css
import './BottomSliderModal.css'

interface Props {
  children: React.ReactNode
  onClose: (...args: any) => void
}

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.4s ease forwards;
  bottom: 0;

  @keyframes fadeIn {
    from {
      opacity: 0.5;
    }
  
    to {
      opacity: 1;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    @keyframes fadeIn {
      from {
        bottom: -100vh;
        opacity: 0.5;
      }
      
      50% {
        background-color: rgba(0, 0, 0, 0);
      }

      to {
        bottom: 0;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
`

// should stop the user from accidentally clicking outside the modal near the edges
const UnintendClickCatcher = styled.div`
  position: absolute;
  z-index: 10;
  max-width: calc(400px + 100px);
  max-height: calc(80vh + 100px);
  background-color: transparent;
  width: 100%;
  height: 100%;
`

const ModalContentContainer = styled.div`
  background-color: white;
  width: 80%;
  max-width: 400px;
  max-height: 80vh;
  overflow: auto;
  padding: ${props => props.theme.spacing.medium} ${props => props.theme.spacing.small};
  position: relative;
  border-radius: 8px;
  z-index: 20;

  @media (max-width: 768px) {
    width: 100%;
    max-width: fit-content;
    position: absolute;
    flex-direction: row;
    justify-content: center;
    animation: animateTop 0.4s ease forwards;
    max-height: unset;
    height: 100vh;

    @keyframes animateTop {
      from {
        top: 0;
      }
      to {
        top: 0px;
        }
      }
    }
  }
`

const CloseButton = styled.button`
  position: absolute;
  @media(max-width: 768px) {
    position: fixed;
  }
  font-size: 35px;
  color: ${({ theme }) => theme.colors.text};
  background: transparent;
  margin-bottom: ${props => props.theme.spacing.small};
  margin-top: -10px;
  right: ${props => props.theme.spacing.medium};
  border: none;
  cursor: pointer;
  padding: 0;
`

const ModalContent = styled.div`
  margin-top:  ${props => props.theme.spacing.large};
  @media(max-width: 768px) {
    margin-bottom: 96px;
  }
`

const BottomSliderModal = forwardRef(({ children, onClose }: Props, ref) => {
  const [isAnimating, setAnimating] = useState(false)
  const contentRef = useRef<HTMLDivElement | null>(null) // Add type to the ref

  useImperativeHandle(ref, () => ({
    scrollToTop: () => {
      contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' }) // Use scrollTo instead of scrollToBottom
    },
    scrollToBottom: () => {
      contentRef.current?.scrollTo({ top: contentRef.current.scrollHeight, behavior: 'smooth' }) // Use scrollTo instead of scrollToBottom
    }
  }))

  const handleClose = () => {
    setAnimating(true)
    // Reset animation after 0.4 seconds (duration of the animation)
    setTimeout(() => {
      setAnimating(false)
      onClose()
    }, 400)
  }

  return (
    <ModalWrapper onClick={handleClose} className={isAnimating ? 'closeModal' : ''}>
      <UnintendClickCatcher onClick={e => e.stopPropagation()} />
      <ModalContentContainer ref={contentRef} onClick={e => e.stopPropagation()}>
        <CloseButton onClick={handleClose}>&times;</CloseButton>
        <ModalContent>
          {children}
        </ModalContent>
      </ModalContentContainer>
    </ModalWrapper>
  )
})

export default BottomSliderModal
