import { onboarding } from 'Containers/steps/Consts'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Avatar from './AvatarWrapper'
import StarRating from './StartsRating'

const SCarouselWrapper = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;
  width: 100%;
`

const SCarouselSlides = styled.div<ICarouselProps>`
  width: 100%;
  display: flex;
  transform: translateX(calc(-${props => props.currentSlide * 100}% - ${props => props.currentSlide * 40}px));
  transition: all 0.5s ease;
`

const SCarouselSlide = styled.div<ICarouselSlide>`
  flex: 0 0 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  padding: ${({ theme }) => theme.spacing.medium};
  justify-content: center;
  align-items: center;
  transition: transform ${props => props.duration}ms ease;
`

const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius:  ${({ theme }) => theme.spacing.small};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-right:  ${({ theme }) => theme.spacing.xLarge};
  
  p {
    margin: 0;
    font-size:  ${({ theme }) => theme.fontSizes.medium};
    padding: ${({ theme }) => theme.spacing.zero};
    margin-top: ${({ theme }) => theme.spacing.zero};
  }
`

const ReviewPadding = styled.div`
  padding: ${({ theme }) => theme.spacing.medium};
`

const ReviewHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`

const ReviewTitle = styled.h1`
  margin-bottom: 0; 
  margin-top: 0;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`

interface ICarouselProps {
  currentSlide: number
}

interface ICarouselSlideProps {
  duration: number
}

interface ICarouselSlide {
  active?: boolean
  duration: number
}

const ReviewCarousel: React.FC<ICarouselSlideProps> = ({ duration }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const { t } = useTranslation(onboarding)

  const reviews = [
    {
      name: t('progress_step.reviews.first.name'),
      text: t('progress_step.reviews.first.text'),
      rating: 5
    },
    {
      name: t('progress_step.reviews.second.name'),
      text: t('progress_step.reviews.second.text'),
      rating: 5
    },
    {
      name: t('progress_step.reviews.third.name'),
      text: t('progress_step.reviews.third.text'),
      rating: 5
    }
  ]

  const activeSlide = reviews.map((slide, index) => (
    <SCarouselSlide duration={duration / reviews.length} active={currentSlide === index} key={index}>
      <ReviewWrapper>
        <ReviewPadding>
          <ReviewHeader>
            <Avatar fullName={slide.name} />
            <ReviewTitle>
              {slide.name}</ReviewTitle>
          </ReviewHeader>
          <StarRating />
          <p>{slide.text}</p>
        </ReviewPadding>
      </ReviewWrapper>
    </SCarouselSlide >
  ))

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % activeSlide.length)
    }, duration / (reviews.length - 1))

    return () => clearInterval(slideInterval)
  }, [duration, activeSlide.length])

  return (
    <SCarouselWrapper>
      <SCarouselSlides currentSlide={currentSlide}>{activeSlide}</SCarouselSlides>
    </SCarouselWrapper>
  )
}

export default ReviewCarousel
