import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'
import { options, title } from './Consts'

export function getWalkingTime(t: TFunction): OnboardingStep {
  const walkingTime = 'walking_time'
  const lessThan20 = 'less_than_20'
  const from20to60 = '20_to_60'
  const moreThan60 = 'more_than_60'

  return {
    id: walkingTime,
    type: 'single-select',
    wordings: {
      title: t(`${walkingTime}.${title}`)
    },
    options: [
      {
        id: lessThan20,
        label: t(`${walkingTime}.${options}.${lessThan20}`),
        emoji: '👌'
      },
      {
        id: from20to60,
        label: t(`${walkingTime}.${options}.${from20to60}`),
        emoji: '👍'
      },
      {
        id: moreThan60,
        label: t(`${walkingTime}.${options}.${moreThan60}`),
        emoji: '💪'
      }
    ]
  }
}
