import { isPurchaseAvailable } from 'Lib/StripeUtils'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const NextStepCard = styled.div`
  background-color: #f2f4f6;
  border-radius: ${props => props.theme.spacing.small};
  width: 100%;
  padding: ${props => props.theme.spacing.medium};
  margin-bottom: ${props => props.theme.spacing.medium};
`

const NextStepCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${props => props.theme.spacing.medium};
`

const NextStepCardTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: bold;
  margin-bottom: ${props => props.theme.spacing.small};
  margin-top: 0;
  text-align: center;
`

const NumberWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  width: ${({ theme }) => theme.spacing.medium};
  height: ${({ theme }) => theme.spacing.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: white;
  flex-shrink: 0; 
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  p {
    margin: ${({ theme }) => theme.spacing.xsmall};
    font-size: ${({ theme }) => theme.fontSizes.medium};
    color: ${({ theme }) => theme.colors.lightText};
  }
`

const NextStep = () => {
  const { t } = useTranslation()
  const stepNumber = isPurchaseAvailable() ? 3 : 2

  return (
    <NextStepCardWrapper>
      <NextStepCard>
        <NextStepCardTitle>{t('nextSteps.title')}</NextStepCardTitle>
        <ContentWrapper>
          <NumberWrapper>{stepNumber}</NumberWrapper>
          <p>{t('nextSteps.downloadApp')}</p>
        </ContentWrapper>
        <ContentWrapper>
          <NumberWrapper>{stepNumber + 1}</NumberWrapper>
          <p>{t('nextSteps.login')}</p>
        </ContentWrapper>

      </NextStepCard>
    </NextStepCardWrapper>
  )
}

export default NextStep
