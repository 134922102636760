import { isIos } from 'Lib'
import { removeLeadingZeros } from 'Lib/SelectionsUtils'
import { t } from 'i18next'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'
import InputWrapper from './InputWrapper'
import { TRANSITION_ANIMATION_DURATION } from 'Lib/Constants'

const InputSection = styled.div`
  margin: auto;
  margin-top:  ${({ theme }) => theme.spacing.medium};
  width: fit-content;
`

const Input = styled.input`
  display:flex;
  justify-content: center;
  border: none;
  background-color: transparent;
  border-radius: 0;
  outline: none;
  font-size: ${({ theme }) => theme.fontSizes.xLarge};
  width: 100px;
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: calc(30%);
  }
`

const SelectedUnit = styled.span`
  margin-left:auto;
  width: ${({ theme }) => theme.spacing.xLarge};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0;
`

interface WeightInputProps {
  placeholder: string
  setErrorMessage: (message: string) => void
  onWeightChange: (weight: IUserWeight | undefined) => void
  unit: IUnitSystem
  initialWeight?: IUserWeight
}

export interface WeightInputRef {
  clearInputs: () => void
}

const WeightInput: React.ForwardRefExoticComponent<WeightInputProps & React.RefAttributes<any>> = forwardRef(({ placeholder, unit, setErrorMessage, onWeightChange, initialWeight }, ref) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [kgInputValue, setKgInputValue] = useState(unit === 'cm/kg' ? initialWeight?.original?.value?.toString() : '')
  const [lbInputValue, setLbInputValue] = useState(unit === 'ft/lbs' ? initialWeight?.original?.value?.toString() : '')
  // without this `!!initialWeight?.original?.value` the error message will not show up
  // when the user navigates back to this step and changes the input to an invalid value
  const [allowShowError, setAllowShowError] = useState(!!initialWeight?.original?.value)
  const [dirty, setDirty] = useState(!!initialWeight?.original?.value)

  // Exposing clearInputs function to parent component
  // so I can clear the inputs when the user changes the unit
  useImperativeHandle(ref, () => ({
    clearInputs
  }))

  const clearInputs = () => {
    setKgInputValue('')
    setLbInputValue('')
    setErrorMessage('')
    setAllowShowError(false)
    onWeightChange(undefined)
    setDirty(false)
  }

  useEffect(() => {
    // Focus on input on non-iOS devices
    // because on iOS devices the keyboard won't show up
    if (!isIos() && inputRef.current) {
      setTimeout(() => {
        // Don't focus before transition animation is finished.
        // Otherwise, it causes this bug on Android: https://bodyfastworkspace.slack.com/archives/C04J5V1PN8G/p1703756108300509?thread_ts=1703755034.066339&cid=C04J5V1PN8G
        inputRef.current?.focus()
      }, TRANSITION_ANIMATION_DURATION)
    }
  }, [unit])

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setDirty(true)
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault()
    }
  }

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.placeholder = ''
  }

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.placeholder = placeholder
    setAllowShowError(true)
  }

  useEffect(() => {
    if (unit === 'cm/kg') {
      validateKgInput(kgInputValue)
    } else {
      validateLbInput(lbInputValue)
    }
  }, [allowShowError])

  const handleShowError = (error?: string) => {
    if (allowShowError && error) {
      setErrorMessage(error)
    }
  }

  const handleKgInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = removeLeadingZeros(e.target.value.trim())
    if (newValue.length === 3) setAllowShowError(true)

    if (newValue.length > 3) {
      return { error: t('weight.error.kg') }
    }

    setKgInputValue(newValue)
    const { error, weight } = validateKgInput(newValue)

    if (error) {
      onWeightChange(undefined)
    } else if (weight) {
      onWeightChange(weight)
    }
  }

  const handleLbInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = removeLeadingZeros(e.target.value.trim())
    if (newValue.length === 3) setAllowShowError(true)

    if (newValue.length > 3) {
      return { error: t('weight.error.lb') }
    }

    setLbInputValue(newValue)
    const { error, weight } = validateLbInput(newValue)

    if (error) {
      onWeightChange(undefined)
    } else if (weight) {
      onWeightChange(weight)
    }
  }

  const validateKgInput = (value?: string): {
    error?: string
    weight?: IUserWeight
  } => {
    if (!dirty || !value) {
      return { error: t('weight.error.kg')! }
    }
    const numericValue = parseFloat(value)
    let errorMessage = ''
    if (numericValue < 30 || numericValue > 250) {
      errorMessage = t('weight.error.kg')
    }

    if (errorMessage) {
      handleShowError(errorMessage)
      return { error: errorMessage }
    }
    handleShowError('')

    return {
      weight: {
        original: { value: numericValue, unit: 'cm/kg' },
        inKg: { value: numericValue, unit: 'cm/kg' }
      }
    }
  }

  const validateLbInput = (value?: string): {
    error?: string
    weight?: IUserWeight
  } => {
    if (!dirty || !value) {
      return { error: t('weight.error.lb')! }
    }
    const numericValue = parseFloat(value)
    let errorMessage = ''
    if (isNaN(numericValue) || numericValue < 66 || numericValue > 552) {
      errorMessage = t('weight.error.lb')
    }

    if (errorMessage) {
      handleShowError(errorMessage)
      return { error: errorMessage }
    }
    handleShowError('')

    const totalWeightInKg = numericValue * 0.45359237
    return {
      weight: {
        original: { value: numericValue, unit: 'ft/lbs' },
        inKg: { value: totalWeightInKg, unit: 'cm/kg' }
      }
    }
  }

  return (<InputSection>
    <InputWrapper>
      <Input
        ref={inputRef}
        type="number"
        value={unit === 'cm/kg' ? kgInputValue : lbInputValue}
        onChange={unit === 'cm/kg' ? handleKgInputChange : handleLbInputChange}
        placeholder={placeholder}
        maxLength={3}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <SelectedUnit>{unit === 'cm/kg' ? t('weight.kg') : t('weight.lb')} </SelectedUnit>
    </InputWrapper>
  </InputSection>
  )
})

export default WeightInput
