import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// Loads translation files from the static files in public folder
import i18nextHttpBackend from 'i18next-http-backend'
import * as Sentry from '@sentry/react'
import { Settings } from 'luxon'

const currentLanguage = localStorage.getItem('lang') || navigator.language || navigator.language

const LANGS_OBJ: { [key: string]: boolean } = {
  en: true,
  de: true,
  es: true,
  fr: true,
  it: true,
  pt: true
}

const LANGS = Object.keys(LANGS_OBJ)

void (async () => {
  await i18n
    .use(i18nextHttpBackend)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      lng: currentLanguage,
      debug: false,
      load: 'languageOnly', // Fixing this: https://bodyfastworkspace.slack.com/archives/C060ULE5SNB/p1698059307073029
      backend: {
        loadPath: '/onboarding/locales/{{lng}}/translation.json'
      }
    })

  Settings.defaultLocale = i18n.language
})()

i18n.on('failedLoading', (lng, ns, msg) => {
  if (LANGS_OBJ[lng]) {
    logEvent('web_i18n_error', { lng, ns, msg })
    Sentry.captureException(new Error(`Failed to load translation file ${ns}/${lng}: ${msg}`))
  }
})

export default i18n
