import * as H from 'history'
import { RouteNames } from 'RouteNames'
import { useHistory } from 'react-router-dom'
import * as qs from 'querystring'
import { v4 as uuidv4 } from 'uuid'
import { getPaymentSuccessUrl } from 'Lib/EventsUtils'
import { isAndroid } from 'Lib/UIUtils'

/** the next line are executed in global scope at startup */
const INITIAL_SEARCH_PATH = location.search
// strict null check -> empty string is also valid original search params
if (localStorage.getItem('originalSearchParams') === null) {
  localStorage.setItem('originalSearchParams', INITIAL_SEARCH_PATH)
  // This is where the localStorage is populated for the first time. (Even if there is not search parameters)
  // We need to save the time of this first visit.
  localStorage.setItem('firstVisit', new Date().toISOString())
}

/**
 * Gets the current app environment based on which host is the app running
 * Unfortunately there is no other way to determine this, since firebase hosting does not provide environment variables
 */
export function getEnvironment() {
  if (location.hostname === 'my.bodyfast.app') {
    return 'production'
  }

  if (location.hostname === 'staging.bodyfast.de') {
    return 'staging'
  }

  return 'development'
}

export async function sleep(ms: number): Promise<void> {
  return await new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

export const getSearchParams = (): URLSearchParams => {
  if (window.location.search !== '') {
    return new URLSearchParams(window.location.search)
  }
  const searchParams = new URLSearchParams(localStorage.getItem('searchParams') ?? '')
  return searchParams
}

export const getSearchParamsString = (): string => {
  const searchParams = getSearchParams()
  return searchParams.toString() ? `?${searchParams.toString()}` : ''
}

export const hasInAppBrowserAsUtmSource = () => {
  const searchParams = getSearchParams()
  return searchParams.get('utm_source') === 'in_app_browser' // Ensure that the utm_source value is matching with the value in ManageSubscriptionScreen.js
}

export const shouldAttemptToCloseInAppBrowser = () =>
  hasInAppBrowserAsUtmSource() && // Close feature is only working on the in app browsers
  !isAndroid() // Close feature is not working on Android for some reason. I couldn't check the root cause because of time restrictions. It must be something related to the deeplinks. See: https://bodyfastworkspace.slack.com/archives/C05E05DK2SY/p1726748368880109

const containsAdParams = (urlSearchParams: URLSearchParams): boolean => {
  return urlSearchParams.has('fbclid') ||
    urlSearchParams.has('gclid') ||
    urlSearchParams.has('twclid') ||
    urlSearchParams.has('utm_source') ||
    urlSearchParams.has('utm_medium') ||
    urlSearchParams.has('utm_campaign') ||
    urlSearchParams.has('utm_term') ||
    urlSearchParams.has('utm_content') ||
    urlSearchParams.has('ttclid')
}

const sanitizeSearchParams = (urlSearchParams: URLSearchParams): URLSearchParams => {
  urlSearchParams.delete('payment_intent')
  urlSearchParams.delete('payment_intent_client_secret')
  urlSearchParams.delete('selectedPackageId')
  urlSearchParams.delete('subscriptionId')
  urlSearchParams.delete('send_event')
  urlSearchParams.delete('redirect_status')

  return urlSearchParams
}

export const getSanitizedSearchParams = (): URLSearchParams => {
  const urlSearchParams1 = new URLSearchParams(window.location.search)
  if (containsAdParams(urlSearchParams1)) {
    logEvent('web_use_search_params_1', {
      searchParam: window.location.search || '<empty>',
    })
    return sanitizeSearchParams(urlSearchParams1)
  }

  const urlSearchParams2 = new URLSearchParams(INITIAL_SEARCH_PATH ?? '')
  if (containsAdParams(urlSearchParams2)) {
    logEvent('web_use_search_params_2', {
      searchParam: INITIAL_SEARCH_PATH || '<empty>',
    })
    return sanitizeSearchParams(urlSearchParams2)
  }

  const urlSearchParams3 = new URLSearchParams(localStorage.getItem('searchParams') ?? '')
  if (containsAdParams(urlSearchParams3)) {
    logEvent('web_use_search_params_3', {
      searchParam: localStorage.getItem('searchParams') || '<empty>',
    })

    return sanitizeSearchParams(urlSearchParams3)
  }

  const urlSearchParams4 = new URLSearchParams(localStorage.getItem('originalSearchParams') ?? '')
  if (containsAdParams(urlSearchParams4)) {
    logEvent('web_use_search_params_4', {
      searchParam: localStorage.getItem('originalSearchParams') || '<empty>',
    })
    return sanitizeSearchParams(urlSearchParams4)
  }

  logEvent('web_no_params_found')

  return new URLSearchParams(localStorage.getItem('searchParams') ?? '') // most likely fallback
}

export const getSanitizedSearchParamsString = () => {
  const sanitizedSearchParams = getSanitizedSearchParams()
  return sanitizedSearchParams.toString() ? `?${sanitizedSearchParams.toString()}` : ''
}

const saveSearchParams = () => {
  const searchParams = window.location.search
  if (searchParams === '') {
    return
  }

  const searchParamsString = searchParams.toString()
  localStorage.setItem('searchParams', searchParamsString)
}

export const sanitizeUrl = (history: H.History) => {
  saveSearchParams()

  if (location.pathname === RouteNames.HOME) {
    return
  }

  const url = new URL(location.pathname, window.location.origin)
  url.search = '' // remove search params

  let modifiedUrlString = url.pathname
  if (modifiedUrlString.endsWith('/')) {
    modifiedUrlString = modifiedUrlString.slice(0, -1) // remove trailing slash
  }

  // Check if the URL is already cleared, if so, do nothing
  const originalUrl = location.pathname + location.search
  if (modifiedUrlString !== originalUrl) {
    history.replace(modifiedUrlString)
  }
}

export const getAnalyticsUrl = () => `${location.origin}${location.pathname}${getSearchParamsString()}`

export const getAnalyticsPath = () => `${location.pathname}${getSearchParamsString()}`

export const navigateToPaymentSuccess = async (history: ReturnType<typeof useHistory>, userTokenId: string, subscriptionId?: string, source?: 'stripe' | 'elements' | 'paypal') => {
  const searchParams = getSearchParamsString().replace('?', '')

  const params = qs.parse(searchParams)

  const search = qs.stringify({
    ...params,
    subscriptionId,
    token: userTokenId,
    source,
    send_event: true
  })

  const paymentSuccessPath = getPaymentSuccessUrl()

  history.push(`${paymentSuccessPath}?${search}`)
}

export const removeSearchParams = (params: string[]) => {
  const urlSearchParams = getSearchParams()

  params.forEach(param => {
    localStorage.removeItem(param)
    urlSearchParams.delete(param)
  })

  const searchParamsString = urlSearchParams.toString()
  localStorage.setItem('searchParams', searchParamsString)
}

/**
 * We use this mainly for growthbook in order to run A/B test for the anonymous users since we can not get the pseudo id from firebase.
 * Also, we are adding this to Sentry and Analytics events too. So that, we can match the users in those platforms to detect the bugs/issues.
 */
export const getLocalId = (): string => {
  let localId = localStorage.getItem('localId')

  if (!localId) {
    localId = uuidv4()
    localStorage.setItem('localId', localId)
  }

  return localId
}

export const openUrlInNewTab = (url: string) => {
  window.open(url, '_blank')
}

export const openEmailApp = (to: string, subject: string = '', body: string = '') => {
  window.open(`mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`)
}

export const getCurrency = () => {
  const searchParams = getSearchParams()
  if (searchParams) {
    const currency = searchParams.get('currency')
    const curr = searchParams.get('curr')

    return currency ?? curr ?? undefined
  }
}
