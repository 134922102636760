import styled from 'styled-components'

const StepSubtitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  width: 100%;
  text-align: center;
`

export default StepSubtitle
