import Button from 'Components/Button'
import IconInput from 'Components/IconInput'
import { MAX_NAME_LENGTH, isValidEmail, validateEmail, validateName, validatePassword } from 'Lib'
import React, { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaLock, FaUser } from 'react-icons/fa'
import styled from 'styled-components'

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-bottom:${({ theme }) => theme.spacing.medium};
  margin-top: ${({ theme }) => theme.spacing.medium};
  font-weight: 500;
`

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.medium};
  flex-direction: column;
  width: 100%;
`

const FormLabel = styled.label`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  font-weight: 400;
`

const Error = styled.span`
  color: red;
  margin-left: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSizes.small};
`

const LoadingWrapper = styled.div`
  height: 32px;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex: 1;
`

interface Props {
  onRegisterTap: (formData: {
    email: string
    password: string
    firstName: string
  }) => Promise<void>
  email?: string
}

const RegistrationForm: React.FC<Props> = ({ onRegisterTap: handleNextStep, email = '' }) => {
  const { t } = useTranslation()
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('')
  const [nameErrorMessage, setNameErrorMessage] = useState('')
  const [emailBlurred, setEmailBlurred] = useState(false)
  const [passwordBlurred, setPasswordBlurred] = useState(false)
  const [nameBlurred, setNameBlurred] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [formData, setFormData] = useState({
    email,
    password: '',
    firstName: ''
  })

  const hasError = () => {
    const isEmailValid = validateEmail(formData.email) === undefined
    const isPasswordValid = validatePassword(formData.password) === undefined
    const isNameValid = validateName(formData.firstName) === undefined

    return !isEmailValid || !isPasswordValid || !isNameValid
  }

  const handleChange = (event: any) => {
    setSubmitted(false)

    const value = event.target.value.trim()
    const name = event.target.name

    setFormData({ ...formData, [name]: value })

    if (name === 'email') {
      const emailError = validateEmail(value)
      setEmailErrorMessage(emailError ?? '')
    } else if (name === 'password') {
      const passwordError = validatePassword(value)
      setPasswordErrorMessage(passwordError ?? '')
    } else if (name === 'firstName') {
      const nameError = validateName(value)
      setNameErrorMessage(nameError ?? '')
    }
  }

  const formHasError = () => {
    return emailErrorMessage || passwordErrorMessage || nameErrorMessage
  }

  const handleSubmit = async (event: FormEvent<Element>) => {
    setSubmitted(true)
    // delay the form submission so that the error messages can be shown
    // if there are any

    event.preventDefault()
    let hasError = false
    if (!formData.email || formData.email === '') {
      const error = t('errors.emailRequired')
      setEmailErrorMessage(error)
      hasError = true
    }
    if (!formData.password || formData.password === '') {
      const error = t('errors.passwordRequired')
      setPasswordErrorMessage(error)
      hasError = true
    }
    if (!formData.firstName || formData.firstName === '') {
      const error = t('errors.nameRequired')
      setNameErrorMessage(error)
      hasError = true
    }

    if (!hasError && !formHasError()) {
      await handleNextStep(formData)
    } else {
      return false
    }
  }

  // We want to have instant feedback on the length of the name
  // but we don't want to show the other error message until the user
  // has blurred the field or submitted the form
  const shouldShowNameError = nameBlurred || submitted
  const shouldShowEmailError = emailBlurred || submitted
  const shouldShowPasswordError = passwordBlurred || submitted

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <FormLabel>{t('forms.name')}</FormLabel>
        <IconInput
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          autoCapitalize={true}
          onBlur={() => setNameBlurred(true)}
          onFocus={() => setNameBlurred(false)}
          hasError={shouldShowNameError && !!nameErrorMessage}
          isInputValid={!validateName(formData.firstName)}
          maxLength={MAX_NAME_LENGTH}
        />
        {shouldShowNameError && nameErrorMessage && <Error>{nameErrorMessage}</Error>}
      </FormRow>
      <FormRow>
        <FormLabel>{t('forms.email')}</FormLabel>
        <IconInput
          icon={<FaUser />}
          name='email'
          value={formData.email}
          onChange={handleChange}
          onBlur={() => setEmailBlurred(true)}
          onFocus={() => setEmailBlurred(false)}
          hasError={shouldShowEmailError && !!emailErrorMessage}
          isInputValid={isValidEmail(formData.email)}
        />
        {shouldShowEmailError && emailErrorMessage && <Error>{emailErrorMessage}</Error>}
      </FormRow>
      <FormRow>
        <FormLabel>{t('forms.password')}</FormLabel>
        <IconInput
          icon={<FaLock />}
          type="password"
          name='password'
          value={formData.password}
          onChange={handleChange}
          onBlur={() => setPasswordBlurred(true)}
          onFocus={() => setPasswordBlurred(false)}
          hasError={shouldShowPasswordError && !!passwordErrorMessage}
          isInputValid={!validatePassword(formData.password)}
        />
        {shouldShowPasswordError && passwordErrorMessage && <Error>{passwordErrorMessage}</Error>}
      </FormRow>
      <FormRow>
        <Button
          margin='0'
          onClick={handleSubmit}
          disabled={hasError()}
          type="submit">
          {t('nextButtonLabel')}
        </Button>
      </FormRow>
    </form>
  )
}

export default RegistrationForm
