import * as Sentry from '@sentry/react'
import { getSearchParams } from 'Lib/AppUtils'
import {
  Selectors
} from 'Reducers'
import { AUTH_REQUIRING_ROUTES, RouteNames } from 'RouteNames'
import { waitForStartup } from 'Sagas/HelperSagas'
import { all, call, fork, put, select, take } from 'typed-redux-saga'
import { push } from 'connected-react-router'
import { getFeatureValue } from 'Services/GrowthBook.service'

export function * checkAuthStateSaga () {
  while (true) {
    yield take('@@router/LOCATION_CHANGE')
    yield call(waitForStartup)

    if (AUTH_REQUIRING_ROUTES.includes((location.pathname as any))) {
      const user: ReturnType<typeof Selectors.getUser> = yield select(Selectors.getUser)

      const searchParams = getSearchParams()

      const urlToken = searchParams.get('token')
      const localStorageToken = localStorage.getItem('tokenId')

      const token = urlToken ?? localStorageToken ?? null

      if (!user) {
        const directTo: ReturnType<typeof getFeatureValue> = yield call(getFeatureValue, 'unauth-route-directs-to', 'loading')

        if (token) {
          // We don't know if this case ever happens. We want to log it first before taking any action.
          yield call(logError, 'web_tokenExistsWithoutUser', new Error('Token exists without user'), { path: location.pathname, directTo }, true)
        }

        yield call(logEvent, 'web_error_unauthenticatedRoute', { path: location.pathname, directTo })
        Sentry.captureException(new Error('Unauthenticated route'))

        yield put(push(directTo === 'loading' ? RouteNames.LOADING : RouteNames.LOGIN))
      }
    }
  }
}

export function * NavigationSagas() {
  yield all([
    fork(checkAuthStateSaga)
  ])
}
