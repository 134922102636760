import Logo from './Assets/Images/Logo.png'
import LogoWebp from './Assets/Images/Logo.webp'
import LogoWhite from './Assets/Images/LogoWhite.webp'
import LogoBlack from './Assets/Images/LogoBlack.webp'
import AppleBadge_DE from './Assets/Images/badges/apple_de.svg'
import AppleBadge_EN from './Assets/Images/badges/apple_en.svg'
import AppleBadge_ES from './Assets/Images/badges/apple_es.svg'
import AppleBadge_FR from './Assets/Images/badges/apple_fr.svg'
import AppleBadge_IT from './Assets/Images/badges/apple_it.svg'
import AppleBadge_PT from './Assets/Images/badges/apple_pt.svg'
import GoogleBadge_DE from './Assets/Images/badges/google_de.webp'
import GoogleBadge_EN from './Assets/Images/badges/google_en.webp'
import GoogleBadge_ES from './Assets/Images/badges/google_es.webp'
import GoogleBadge_FR from './Assets/Images/badges/google_fr.webp'
import GoogleBadge_IT from './Assets/Images/badges/google_it.webp'
import GoogleBadge_PT from './Assets/Images/badges/google_pt.webp'
import HuaweiBadge_EN from './Assets/Images/badges/huawei_en.webp'

import LeftRoundedArrow from './Assets/Images/LeftRoundedArrow.svg'
import SelectedLeftRoundedArrow from './Assets/Images/SelectedLeftRoundedArrow.svg'
import SelectedLeftRoundedArrowBlue from './Assets/Images/SelectedLeftRoundedArrow_blue.svg'
import SelectedLeftRoundedArrowBlueLight from './Assets/Images/SelectedLeftRoundedArrow_blue_light.svg'
import SelectedLeftRoundedArrowGreen from './Assets/Images/SelectedLeftRoundedArrow_green.svg'
import SelectedLeftRoundedArrowGreenLight from './Assets/Images/SelectedLeftRoundedArrow_green_light.svg'
import SelectedLeftRoundedArrowOrange from './Assets/Images/SelectedLeftRoundedArrow_orange.svg'
import SelectedLeftRoundedArrowOrangeLight from './Assets/Images/SelectedLeftRoundedArrow_orange_light.svg'
import SelectedLeftRoundedArrowClassic from './Assets/Images/SelectedLeftRoundedArrow_classic.svg'
import SelectedLeftRoundedArrowClassicLight from './Assets/Images/SelectedLeftRoundedArrow_classic_light.svg'

import onboardingFinished_DE from './Assets/Images/onboarding_finished_de.webp'
import onboardingFinished_EN from './Assets/Images/onboarding_finished_en.webp'
import onboardingFinished_ES from './Assets/Images/onboarding_finished_es.webp'
import onboardingFinished_FR from './Assets/Images/onboarding_finished_fr.webp'
import onboardingFinished_IT from './Assets/Images/onboarding_finished_it.webp'
import onboardingFinished_PT from './Assets/Images/onboarding_finished_pt.webp'

import award from './Assets/Images/award.webp'
import calendar from './Assets/Images/calendar.svg'
import email from './Assets/Images/email.svg'
import lock from './Assets/Images/lock.svg'
import hoursTracked from './Assets/Images/onboarding/hours-tracked.webp'

import moneyBack_DE from './Assets/Images/onboarding/money_back_DE.webp'
import moneyBack_EN from './Assets/Images/onboarding/money_back_EN.webp'
import moneyBack_ES from './Assets/Images/onboarding/money_back_ES.webp'
import moneyBack_FR from './Assets/Images/onboarding/money_back_FR.webp'
import moneyBack_IT from './Assets/Images/onboarding/money_back_IT.webp'
import moneyBack_PT from './Assets/Images/onboarding/money_back_PT.webp'

import progressionFemale from './Assets/Images/onboarding/progression-female.webp'
import progressionMale from './Assets/Images/onboarding/progression-male.webp'
import paypal from './Assets/Images/paypal.webp'
import scale from './Assets/Images/scale.svg'
import scaleBlue from './Assets/Images/scale_blue.svg'
import scaleClassic from './Assets/Images/scale_classic.svg'
import scaleGreen from './Assets/Images/scale_green.svg'
import statusLow from './Assets/Images/status_low.svg'
import statusLowBlue from './Assets/Images/status_low_blue.svg'
import statusLowClassic from './Assets/Images/status_low_classic.svg'
import statusLowGreen from './Assets/Images/status_low_green.svg'
import statusMedium from './Assets/Images/status_medium.svg'
import statusMediumBlue from './Assets/Images/status_medium_blue.svg'
import statusMediumClassic from './Assets/Images/status_medium_classic.svg'
import statusMediumGreen from './Assets/Images/status_medium_green.svg'

import screenshot01_EN from './Assets/Images/onboarding/screenshot-01_EN.webp'
import screenshot02_EN from './Assets/Images/onboarding/screenshot-02_EN.webp'
import screenshot03_EN from './Assets/Images/onboarding/screenshot-03_EN.webp'
import screenshot04_EN from './Assets/Images/onboarding/screenshot-04_EN.webp'
import screenshot05_EN from './Assets/Images/onboarding/screenshot-05_EN.webp'
import screenshot06_EN from './Assets/Images/onboarding/screenshot-06_EN.webp'

import screenshot01_DE from './Assets/Images/onboarding/screenshot-01_DE.webp'
import screenshot02_DE from './Assets/Images/onboarding/screenshot-02_DE.webp'
import screenshot03_DE from './Assets/Images/onboarding/screenshot-03_DE.webp'
import screenshot04_DE from './Assets/Images/onboarding/screenshot-04_DE.webp'
import screenshot05_DE from './Assets/Images/onboarding/screenshot-05_DE.webp'
import screenshot06_DE from './Assets/Images/onboarding/screenshot-06_DE.webp'

import screenshot01_PT from './Assets/Images/onboarding/screenshot-01_PT.webp'
import screenshot02_PT from './Assets/Images/onboarding/screenshot-02_PT.webp'
import screenshot03_PT from './Assets/Images/onboarding/screenshot-03_PT.webp'
import screenshot04_PT from './Assets/Images/onboarding/screenshot-04_PT.webp'
import screenshot05_PT from './Assets/Images/onboarding/screenshot-05_PT.webp'
import screenshot06_PT from './Assets/Images/onboarding/screenshot-06_PT.webp'

import screenshot01_FR from './Assets/Images/onboarding/screenshot-01_FR.webp'
import screenshot02_FR from './Assets/Images/onboarding/screenshot-02_FR.webp'
import screenshot03_FR from './Assets/Images/onboarding/screenshot-03_FR.webp'
import screenshot04_FR from './Assets/Images/onboarding/screenshot-04_FR.webp'
import screenshot05_FR from './Assets/Images/onboarding/screenshot-05_FR.webp'
import screenshot06_FR from './Assets/Images/onboarding/screenshot-06_FR.webp'

import screenshot01_IT from './Assets/Images/onboarding/screenshot-01_IT.webp'
import screenshot02_IT from './Assets/Images/onboarding/screenshot-02_IT.webp'
import screenshot03_IT from './Assets/Images/onboarding/screenshot-03_IT.webp'
import screenshot04_IT from './Assets/Images/onboarding/screenshot-04_IT.webp'
import screenshot05_IT from './Assets/Images/onboarding/screenshot-05_IT.webp'
import screenshot06_IT from './Assets/Images/onboarding/screenshot-06_IT.webp'

import screenshot01_ES from './Assets/Images/onboarding/screenshot-01_ES.webp'
import screenshot02_ES from './Assets/Images/onboarding/screenshot-02_ES.webp'
import screenshot03_ES from './Assets/Images/onboarding/screenshot-03_ES.webp'
import screenshot04_ES from './Assets/Images/onboarding/screenshot-04_ES.webp'
import screenshot05_ES from './Assets/Images/onboarding/screenshot-05_ES.webp'
import screenshot06_ES from './Assets/Images/onboarding/screenshot-06_ES.webp'

import genderOptionFemale from './Assets/Images/onboarding/female.webp'
import genderOptionMale from './Assets/Images/onboarding/male.webp'

import googleLogo from './Assets/Images/google-logo.webp'

import beforeAfterFemale from './Assets/Images/onboarding/before-after-female.webp'
import beforeAfterMale from './Assets/Images/onboarding/before-after-male.webp'

import scientificMethod_DE from './Assets/Images/scientific-method-chart/de.webp'
import scientificMethod_EN from './Assets/Images/scientific-method-chart/en.webp'
import scientificMethod_ES from './Assets/Images/scientific-method-chart/es.webp'
import scientificMethod_FR from './Assets/Images/scientific-method-chart/fr.webp'
import scientificMethod_IT from './Assets/Images/scientific-method-chart/it.webp'
import scientificMethod_PT from './Assets/Images/scientific-method-chart/pt.webp'

import appleLogo from './Assets/Images/onboarding/apple-logo.webp'
import googlePayLogo from './Assets/Images/onboarding/google_pay.webp'

import onelink from './Assets/Images/onelink.webp'

import targeZoneFemaleBelly from './Assets/Images/target-zones/female-belly.webp'
import targeZoneFemaleChest from './Assets/Images/target-zones/female-chest.webp'
import targeZoneFemaleFullbody from './Assets/Images/target-zones/female-fullbody.webp'
import targeZoneFemaleGlutes from './Assets/Images/target-zones/female-glutes.webp'
import targeZoneFemaleLegs from './Assets/Images/target-zones/female-legs.webp'

import couple from './Assets/Images/openings/Couple.webp'
import ladies from './Assets/Images/openings/Ladies.webp'

import bodyTypesFemaleAverage from './Assets/Images/body-types/female-average.webp'
import bodyTypesFemaleCurvy from './Assets/Images/body-types/female-curvy.webp'
import bodyTypesFemaleHeavierSide from './Assets/Images/body-types/female-heavierSide.webp'
import bodyTypesFemaleLean from './Assets/Images/body-types/female-lean.webp'
import bodyTypesFemaleMidsized from './Assets/Images/body-types/female-midsized.webp'
import bodyTypesFemaleOverweight from './Assets/Images/body-types/female-overweight.webp'
import bodyTypesFemaleThin from './Assets/Images/body-types/female-thin.webp'
import bodyTypesFemaleToned from './Assets/Images/body-types/female-toned.webp'

import laurel_DE from './Assets/Images/onboarding/laurel_DE.webp'
import laurel_EN from './Assets/Images/onboarding/laurel_EN.webp'
import laurel_ES from './Assets/Images/onboarding/laurel_ES.webp'
import laurel_FR from './Assets/Images/onboarding/laurel_FR.webp'
import laurel_IT from './Assets/Images/onboarding/laurel_IT.webp'
import laurel_PT from './Assets/Images/onboarding/laurel_PT.webp'

import number1App_DE from './Assets/Images/onboarding/n_1_app_DE.webp'
import number1App_EN from './Assets/Images/onboarding/n_1_app_EN.webp'
import number1App_ES from './Assets/Images/onboarding/n_1_app_ES.webp'
import number1App_FR from './Assets/Images/onboarding/n_1_app_FR.webp'
import number1App_IT from './Assets/Images/onboarding/n_1_app_IT.webp'
import number1App_PT from './Assets/Images/onboarding/n_1_app_PT.webp'

import burger from './Assets/Images/onboarding/burger.webp'
import happyNewYearBalloons from './Assets/Images/onboarding/happy_new_year_balloons.webp'
import ic_bg_sale from './Assets/Images/ic_bg_sale.webp'

export const images = {
  Logo,
  LogoWebp,
  LogoWhite,
  LogoBlack,
  LeftRoundedArrow,
  SelectedLeftRoundedArrow,
  SelectedLeftRoundedArrowBlue,
  SelectedLeftRoundedArrowBlueLight,
  SelectedLeftRoundedArrowGreen,
  SelectedLeftRoundedArrowGreenLight,
  SelectedLeftRoundedArrowOrange,
  SelectedLeftRoundedArrowOrangeLight,
  SelectedLeftRoundedArrowClassic,
  SelectedLeftRoundedArrowClassicLight,
  AppleBadge_EN,
  AppleBadge_DE,
  AppleBadge_IT,
  AppleBadge_FR,
  AppleBadge_ES,
  AppleBadge_PT,
  GoogleBadge_EN,
  GoogleBadge_DE,
  GoogleBadge_IT,
  GoogleBadge_FR,
  GoogleBadge_ES,
  GoogleBadge_PT,
  HuaweiBadge_EN,
  onboardingFinished_EN,
  onboardingFinished_FR,
  onboardingFinished_DE,
  onboardingFinished_IT,
  onboardingFinished_ES,
  onboardingFinished_PT,
  calendar,
  email,
  lock,
  progressionMale,
  progressionFemale,
  statusLow,
  statusLowBlue,
  statusLowClassic,
  statusLowGreen,
  statusMedium,
  statusMediumBlue,
  statusMediumClassic,
  statusMediumGreen,
  scale,
  scaleBlue,
  scaleClassic,
  scaleGreen,
  screenshot01_EN,
  screenshot02_EN,
  screenshot03_EN,
  screenshot04_EN,
  screenshot05_EN,
  screenshot06_EN,
  screenshot01_DE,
  screenshot02_DE,
  screenshot03_DE,
  screenshot04_DE,
  screenshot05_DE,
  screenshot06_DE,
  screenshot01_FR,
  screenshot02_FR,
  screenshot03_FR,
  screenshot04_FR,
  screenshot05_FR,
  screenshot06_FR,
  screenshot01_IT,
  screenshot02_IT,
  screenshot03_IT,
  screenshot04_IT,
  screenshot05_IT,
  screenshot06_IT,
  screenshot01_ES,
  screenshot02_ES,
  screenshot03_ES,
  screenshot04_ES,
  screenshot05_ES,
  screenshot06_ES,
  screenshot01_PT,
  screenshot02_PT,
  screenshot03_PT,
  screenshot04_PT,
  screenshot05_PT,
  screenshot06_PT,
  award,
  paypal,
  moneyBack_EN,
  moneyBack_DE,
  moneyBack_FR,
  moneyBack_ES,
  moneyBack_IT,
  moneyBack_PT,
  hoursTracked,
  googleLogo,
  beforeAfterMale,
  beforeAfterFemale,
  scientificMethod_EN,
  scientificMethod_DE,
  scientificMethod_ES,
  scientificMethod_FR,
  scientificMethod_IT,
  scientificMethod_PT,
  appleLogo,
  googlePayLogo,
  onelink,
  targeZoneFemaleBelly,
  targeZoneFemaleGlutes,
  targeZoneFemaleChest,
  targeZoneFemaleFullbody,
  targeZoneFemaleLegs,
  couple,
  ladies,
  bodyTypesFemaleAverage,
  bodyTypesFemaleCurvy,
  bodyTypesFemaleHeavierSide,
  bodyTypesFemaleLean,
  bodyTypesFemaleMidsized,
  bodyTypesFemaleOverweight,
  bodyTypesFemaleThin,
  bodyTypesFemaleToned,
  laurel_EN,
  laurel_DE,
  laurel_FR,
  laurel_PT,
  laurel_IT,
  laurel_ES,
  number1App_EN,
  number1App_DE,
  number1App_FR,
  number1App_PT,
  number1App_IT,
  number1App_ES,
  genderOptionMale,
  genderOptionFemale,
  burger,
  happyNewYearBalloons,
  ic_bg_sale
}
