import styled from 'styled-components'

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 12px 16px;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: border-color 0.3s;
  color: #000000;
  text-align: center;
  size: 1px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  } 
`

export default InputWrapper
