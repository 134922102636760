import styled from 'styled-components'

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContent = styled.div`
  background-color: white;
  width: 100%;
  max-width: 400px;
  padding: ${props => props.theme.spacing.medium};
  position: relative;
  border-radius: 8px;
`

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.spacing.small};
  right: ${props => props.theme.spacing.medium};
  font-size: ${props => props.theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`

interface ModalProps {
  onClose: () => void
}

const Modal: React.FC<ModalProps> = ({ onClose, children }) => {
  return <ModalWrapper onClick={onClose}>
    <ModalContent onClick={(e) => e.stopPropagation()}>
      <CloseButton onClick={onClose}>&times;</CloseButton>
      {children}
    </ModalContent>
  </ModalWrapper>
}

export default Modal
