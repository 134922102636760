import Introduction from 'Components/Introduction'
import CreateAccountContainer from 'Containers/CreateAccount.container'
import Download from 'Containers/Download.container'
import LoginContainer from 'Containers/Login.container'
import OnboardingContainer from 'Containers/Onboarding.container'
import PaymentContainer from 'Containers/Payment.container'
import PaymentSuccessContainer from 'Containers/PaymentSuccess.container'
import TokenLoginContainer from 'Containers/TokenLogin.container'
import UpgradeAccountContainer from 'Containers/UpgradeAccount.container'
import { fallbackToKnownLang, getAnalyticsPath, getAnalyticsUrl, getSearchParams, getSearchParamsString, sanitizeUrl } from 'Lib'
import { getCommitEventsMap, getCurrentFlow, getGoBackToEventsMap, getGoToEventsMap } from 'Lib/EventsUtils'
import { getLastSavedStep, saveLastSavedStep } from 'Lib/SelectionsUtils'
import { RouteNames, STATIC_ROUTE_NAMES } from 'RouteNames'
import { initCookieService } from 'Services'
import { setGrowthbookAttributes } from 'Services/GrowthBook.service'
import * as H from 'history'
import i18n from 'i18n'
import { useEffect } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { facebookService, firebaseServicePromise, gTagService } from 'store'
import { ONBOARDING_STEPS } from 'types/onboardingTypes'
import { useSelector } from 'react-redux'
import { Selectors } from 'Reducers'
import SplashScreen from 'Components/SplashScreen'
import ManageSubscriptionContainer from 'Containers/ManageSubscription.container'
import PrivacyPolicyContainer from 'Components/PrivacyPolicyContainer'
import CookiePolicyContainer from 'Components/CookiePolicyContainer'
import TermsOfUseContainer from 'Components/TermsOfUseContainer'

const pageViewAnalytics = () => {
  const analyticsPath = getAnalyticsPath()
  const analyticsLocation = getAnalyticsUrl()

  void firebaseServicePromise.then((firebase) => firebase.setCurrentScreen(analyticsPath))
  gTagService.setPageData(analyticsPath, analyticsLocation)
  gTagService.pageView(analyticsPath, analyticsLocation)
}

const isGoingBack = (history: H.History<H.LocationState>) => {
  const lastSavedHistoryLength = localStorage.getItem('lastSavedHistoryLength') ?? ''
  return history.action === 'POP' && lastSavedHistoryLength === history.length.toString()
}

const handleStepLog = (history: H.History<H.LocationState>) => {
  const lastSavedStep = getLastSavedStep()
  const lastSavedHistoryLength = localStorage.getItem('lastSavedHistoryLength') ?? ''
  const pathNames = location.pathname.split('/')
  const currentStep = pathNames[pathNames.length - 1] as ONBOARDING_STEPS

  if ((location.pathname.includes(RouteNames.ONBOARDING) && pathNames.length === 2) || STATIC_ROUTE_NAMES.includes(location.pathname) || location.pathname.includes(RouteNames.MANAGE_SUBSCRIPTION)) {
    // it's a transition phase. We are opening /onboarding-steps before starting onboarding. We shouldn't log this phase.
    // additionally we shouldn't also log when the route name is one of the static names (privacy, cookie policy, terms of use)
    return
  }
  const flow = getCurrentFlow()
  const goingBack = isGoingBack(history)

  if (goingBack) {
    logEvent(getGoBackToEventsMap(flow, currentStep) ?? `web_go_back_to_unk_${currentStep}`)
    saveLastSavedStep(currentStep)
    return
  }

  if (lastSavedStep !== currentStep) {
    if (lastSavedStep) {
      logEvent(getCommitEventsMap(flow, lastSavedStep) ?? `web_commit_unk_${lastSavedStep}`)
      // closing the "umbrella" event of the authenticateUser step
      if (lastSavedStep === 'existing_email_login' || lastSavedStep === 'receive_email') {
        logEvent(`${getCommitEventsMap(flow, 'authenticateUser')}`)
      }

      facebookService.handlePixelTrack(lastSavedStep)
    }

    // opening the "umbrella" event of the authenticateUser step
    logEvent(getGoToEventsMap(flow, currentStep) ?? `web_go_to_unk_${currentStep}`)

    saveLastSavedStep(currentStep)
    localStorage.setItem('lastSavedHistoryLength', history.length.toString())
  }
}

const RootRouter = () => {
  const history = useHistory()
  const location = useLocation()
  const loadingUrlWithParams = RouteNames.LOADING + getSearchParamsString()
  const appStarted = useSelector(Selectors.isAppStarted)

  useEffect(() => {
    initCookieService()

    const setUserLanguage = async () => {
      const searchParams = getSearchParams()
      const langCode = searchParams.get('lang')
      const urlLangCode = langCode?.split('-')[0].toLowerCase()

      const shortLangCode = urlLangCode ?? localStorage.getItem('lang') ?? navigator.language
      const knownLanguage = fallbackToKnownLang(shortLangCode)
      if (urlLangCode) {
        setGrowthbookAttributes({
          lang: urlLangCode
        })

        searchParams.delete('lang')
        localStorage.setItem('lang', knownLanguage)
        history.replace({
          search: searchParams.toString()
        })
      }
      await i18n.changeLanguage(shortLangCode)
    }
    void setUserLanguage()
  }, [])

  useEffect(() => {
    sanitizeUrl(history)

    if (!__DEV__) {
      pageViewAnalytics()
      facebookService.pageView()
    }

    const goingBack = isGoingBack(history)

    document.documentElement.style.setProperty('--enter-translate', goingBack ? '-100%' : '100%')
    document.documentElement.style.setProperty('--exit-translate', goingBack ? '100%' : '-100%')

    handleStepLog(history)
  }, [location])

  if (
    !appStarted &&
    location.pathname !== RouteNames.HOME &&
    location.pathname !== RouteNames.LOADING &&
    location.pathname !== RouteNames.ONBOARDING
  ) {
    return <SplashScreen />
  }

  return (
    <Switch>
      /* INTRO should stay above the ONBOARDING */
      <Route path={RouteNames.LOADING} component={Introduction} />
      <Route path={RouteNames.ONBOARDING} component={OnboardingContainer} />
      <Route path={RouteNames.DOWNLOAD} component={Download} />
      <Route path={RouteNames.PAYMENT} component={PaymentContainer} />
      <Route path={RouteNames.UPGRADE_ACCOUNT} component={UpgradeAccountContainer} />
      <Route path={RouteNames.PAYMENT_SUCCESS} component={PaymentSuccessContainer} />

      <Route path={RouteNames.LOGIN_TOKEN} component={TokenLoginContainer} />
      <Route path={RouteNames.LOGIN_PAYMENT} component={PaymentContainer} />
      <Route path={RouteNames.LOGIN_PAYMENT_SUCCESS} component={PaymentSuccessContainer} />
      <Route path={RouteNames.LOGIN_UPGRADE_ACCOUNT} component={UpgradeAccountContainer} />
      <Route path={RouteNames.LOGIN_DOWNLOAD} component={Download} />
      <Route path={RouteNames.LOGIN_CREATE_ACCOUNT} component={CreateAccountContainer} />
      <Route path={RouteNames.LOGIN} component={LoginContainer} />
      <Route path={RouteNames.PRIVACY} component={PrivacyPolicyContainer} />
      <Route path={RouteNames.COOKIE_POLICY} component={CookiePolicyContainer} />
      <Route path={RouteNames.TERMS_OF_USE} component={TermsOfUseContainer} />

      <Route path={RouteNames.MANAGE_SUBSCRIPTION_PAYMENT} component={PaymentContainer} />
      <Route path={RouteNames.MANAGE_SUBSCRIPTION_PAYMENT_SUCCESS} component={PaymentSuccessContainer} />
      <Route path={RouteNames.MANAGE_SUBSCRIPTION_DOWNLOAD} component={Download} />
      <Route path={RouteNames.MANAGE_SUBSCRIPTION} component={ManageSubscriptionContainer} />

      /* HOME should stay on the bottom */
      <Redirect from={RouteNames.HOME} to={loadingUrlWithParams} />
    </Switch>
  )
}

export default RootRouter
