import styled from 'styled-components'

export const Title = styled.h1<{ color?: string }>`
  font-size: ${props => props.theme.fontSizes.large};
  width: 100%;
  font-weight: bold;
  color: ${props => props.color ?? props.theme.colors.text};
  text-align: center;
  margin: 0;
`
