import { getSearchParams, getAnalyticsPath, getAnalyticsUrl, getLocalId } from 'Lib/AppUtils'

const GA_MEASUREMENT_ID_PRODUCTION = 'G-ME1FB0LY2H'
const GA_MEASUREMENT_ID_STAGING = 'G-MLGSX96V36'

const FIREBASE_MEASUREMENT_ID_PRODUCTION = 'G-V7QJ5SLB6R'
const FIREBASE_MEASUREMENT_ID_STAGING = 'G-1MY70PWYPT'

const AW_CONVERSION_ID = 'AW-786460955'
const GT_MANAGER_ID = 'GTM-NCVMLGG'

export enum AW_CONVERSION_LABELS {
  PURCHASE = '8DXRCLDR74wCEJvigfcC'
}

interface IPurchaseInfo {
  affiliation: 'sepa' | 'card' | 'paypal'
  currency: string
  value: number
  items: string[]
  transaction_id: string
}

export interface IGTagService {
  updateConsent: (performance: boolean, targeting: boolean) => void
  logPurchase: (info: IPurchaseInfo) => void
  logEvent: (eventName: string, params?: Record<string, any>) => void
  setPageData: (path: string, location: string) => void
  pageView: (path: string, location: string) => void
  setParams: (params: Object) => void
}

export function createGTagService (): IGTagService {
  window.dataLayer = window.dataLayer || []

  function gtag (...args: any) {
    window.dataLayer.push(arguments)
  }

  gtag('js', new Date())

  const analyticsPath = getAnalyticsPath()
  const analyticsUrl = getAnalyticsUrl()
  const searchParams = getSearchParams()

  const doNotTrack = window.navigator.doNotTrack

  const CONFIG = {
    anonymize_ip: true,
    page_path: analyticsPath,
    page_location: analyticsUrl,
    referrer: analyticsUrl,
    gclid: searchParams.get('gclid'),
    dc_custom_params: {
      // See dc_lat under "Custom Fields" section: https://support.google.com/campaignmanager/answer/7554821?hl=en#zippy=%2Cstep-add-the-global-snippet-to-every-page-of-your-site%2Cstep-add-the-event-snippet-to-pages-with-events-youre-tracking%2Cexample-event-snippet-for-sales---transaction-activities%2Ccustom-fields
      dc_lat: (doNotTrack === '1' || doNotTrack === 'yes') ? 1 : 0 // 1 means that the user has enabled the “Limit Ad Tracking
    }
  }

  if (!__DEV__) {
    gtag('config', AW_CONVERSION_ID, CONFIG)
    gtag('config', GT_MANAGER_ID, CONFIG)
  }

  const GA_MEASUREMENT_ID = __DEV__ ? GA_MEASUREMENT_ID_STAGING : GA_MEASUREMENT_ID_PRODUCTION
  const FIREBASE_MEASUREMENT_ID = __DEV__ ? FIREBASE_MEASUREMENT_ID_STAGING : FIREBASE_MEASUREMENT_ID_PRODUCTION

  gtag('config', GA_MEASUREMENT_ID, CONFIG)
  gtag('config', FIREBASE_MEASUREMENT_ID, CONFIG)

  // From: https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced
  // "Consent mode users need to send two new parameters in addition to ad_storage and analytics_storage"
  // (text goes on telling the other two consents are ad_personalization and ad_user_data)
  gtag('consent', 'default', {
    analytics_storage: 'denied',
    ad_storage: 'denied',
    ad_personalization: 'denied',
    ad_user_data: 'denied',
    wait_for_update: 500
  })

  gtag('set', 'linker', {
    domains: [
      'localhost:3000',
      'my.bodyfast.app',
      'lp.bodyfast.de',
      'checkout.stripe.com',
      'paypal.com'
    ]
  })

  const GTag = {
    updateConsent: (performance: boolean, targeting: boolean) => {
      gtag('consent', 'update', {
        ad_storage: targeting ? 'granted' : 'denied',
        ad_personalization: targeting ? 'granted' : 'denied',
        ad_user_data: targeting ? 'granted' : 'denied',
        analytics_storage: performance ? 'granted' : 'denied'
      })
    },
    logPurchase: (info: IPurchaseInfo) => {
      gtag('event', 'purchase', {
        localId: getLocalId(),
        ...info,
        ...CONFIG
      })

      gtag('event', 'conversion', {
        send_to: `${AW_CONVERSION_ID}/${AW_CONVERSION_LABELS.PURCHASE}`,
        ...info,
        ...CONFIG
      })
    },
    logEvent: (eventName: string, params?: Record<string, any>) => {
      gtag('event', eventName, {
        ...params,
        ...CONFIG
      })
    },
    pageView: (path: string, location: string) => {
      gtag('event', 'page_view', {
        ...CONFIG,
        page_path: path,
        page_location: location
      })
    },
    setPageData: (path: string, location: string) => {
      gtag('set', {
        ...CONFIG, // don't change the order. otherwise, page_path will be override by the data in CONFIG
        page_path: path,
        page_location: location
      })
    },
    setParams: (params: Object) => {
      gtag('set', {
        ...params
      })
    }
  }

  return GTag
}
