import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'

export function getSpecialOccasionDate(t: TFunction): OnboardingStep {
  return {
    id: 'special_occasion_date',
    type: 'custom',
    wordings: { title: t('') }
  }
}
