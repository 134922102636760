import { options, title } from 'Containers/steps/Consts'
import { t } from 'i18next'
import { ManageSubscriptionStep, ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'

export enum SpecialOfferOption {
  KeepCoach = 'keepCoach',
  CancelCoach = 'cancelCoach'
}

export function getSpecialOfferStep(): ManageSubscriptionStep {
  return {
    id: ManageSubscriptionStepId.SpecialOffer,
    type: 'custom',
    allowNext: true,
    wordings: {
      title: t(`${ManageSubscriptionStepId.SpecialOffer}.${title}`)
    }
  }
}
