import React from 'react'
import styled from 'styled-components'

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.lightText};
  border-radius: 50px;
`

const ProgressBarFill = styled.div<{ progress: number }>`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: inherit;
  text-align: right;
`

interface ProgressBarProps {
  progress: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <ProgressBarContainer>
      <ProgressBarFill progress={progress} />
    </ProgressBarContainer>
  )
}

export default ProgressBar
