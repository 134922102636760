export function isPurchaseAvailable(): boolean {
  const localStorageAppState = localStorage.getItem('appState') ?? ''
  const isPurchaseAvailable = JSON.parse(localStorageAppState).isPurchaseAvailable ?? false
  return isPurchaseAvailable
}

export const getSubscriptionStatus = (subscription: IStripeSubscription): ISubscriptionStatus => {
  if (subscription.status === 'canceled') {
    return 'expired'
  } else if (subscription.pause_collection != null) {
    return 'onHold'
  } else if (subscription.canceled_at == null) {
    return 'active'
  } else if (subscription.canceled_at != null) {
    return 'canceled'
  } else {
    throw Error('Unknown subscription status')
  }
}

export const SPECIAL_OFFER_GROUP_ID: SubscriptionGroupId = 'v13'
