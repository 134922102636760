import React from 'react'
import styled from 'styled-components'
import { useAppState } from 'AppContextProvider'
import { theme } from 'styles/theme'
import { useDispatch } from 'store'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import { FaCheckCircle } from 'react-icons/fa'
import { ActionType } from 'Reducers'
import { ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'
import { PauseOfferOption } from 'Containers/manageSubscriptionSteps/PauseOffer'
import { Spacer } from 'Components/Spacer'

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xLarge};
  align-items: center;
  text-align: center;
  margin: auto;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
`

const TextBold = styled.h1<{ color?: string }>`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  color: ${(props) => props.color ?? theme.colors.text};
`

const Paragraph = styled.p`
  text-align: left;
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 ${({ theme }) => theme.spacing.small};
`

const Item = styled.div`
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: start;
  gap: ${({ theme }) => theme.spacing.small};
  width: 100%;
`

const CheckIcon = styled(FaCheckCircle)`
  width: 25px;
  height: 25px;
`

const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.text};
  margin: ${({ theme }) => theme.spacing.small + ' ' + theme.spacing.zero};
  width: 100%;
  text-align: left;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const PauseOfferStep: React.FC = () => {
  const { t } = useTranslation()
  const { appState } = useAppState()

  const dispatch = useDispatch()

  const onPause = () => {
    dispatch({ type: ActionType.COMMIT_MANAGE_SUBSCRIPTION_STEP, stepId: ManageSubscriptionStepId.PauseOffer, optionId: PauseOfferOption.Pause })
  }

  const onDiscard = () => {
    dispatch({ type: ActionType.COMMIT_MANAGE_SUBSCRIPTION_STEP, stepId: ManageSubscriptionStepId.PauseOffer, optionId: PauseOfferOption.NoThanks })
  }

  return (
    <Container>
      <TextBold>
        {t(`${ManageSubscriptionStepId.PauseOffer}.title`)}
      </TextBold>

      <ItemsContainer>
        <Item>
          <CheckIcon color={theme.colors.primary} />
          <Text>{t(`${ManageSubscriptionStepId.PauseOffer}.firstItem`)}</Text>
        </Item>
        <Item>
          <CheckIcon color={theme.colors.primary} />
          <Text>{t(`${ManageSubscriptionStepId.PauseOffer}.secondItem`)}</Text>
        </Item>
        <Item>
          <CheckIcon color={theme.colors.primary} />
          <Text>{t(`${ManageSubscriptionStepId.PauseOffer}.thirdItem`)}</Text>
        </Item>
      </ItemsContainer>

      <Spacer />
      <ButtonContainer>
        <Button margin='0 10px 0 0' theme={'outline'} onClick={onDiscard}>
          {t('noThanks')}
        </Button>
        <Button margin='0 0 0 10px' onClick={onPause}>
          {t('pause')}
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default PauseOfferStep
