import { options, title } from 'Containers/steps/Consts'
import { t } from 'i18next'
import { ManageSubscriptionStep, ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'

export enum ContactUsAboutExperienceOption {
  Yes = 'yes',
  No = 'no',
}

export function getContactUsAboutExperienceStep(): ManageSubscriptionStep {
  return {
    id: ManageSubscriptionStepId.ContactUsAboutExperience,
    type: 'single-select',
    wordings: {
      title: t(`${ManageSubscriptionStepId.ContactUsAboutExperience}.${title}`)
    },
    options: [
      {
        id: ContactUsAboutExperienceOption.Yes,
        label: t(`${ManageSubscriptionStepId.ContactUsAboutExperience}.${options}.${ContactUsAboutExperienceOption.Yes}`)
      },
      {
        id: ContactUsAboutExperienceOption.No,
        label: t(`${ManageSubscriptionStepId.ContactUsAboutExperience}.${options}.${ContactUsAboutExperienceOption.No}`)
      },
    ]
  }
}
