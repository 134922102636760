import { removeLeadingZeros } from 'Lib/SelectionsUtils'
import { isIos } from 'Lib/UIUtils'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import InputWrapper from './InputWrapper'
import { TRANSITION_ANIMATION_DURATION } from 'Lib/Constants'

const InputSection = styled.div`
  margin: auto;
  margin-top: ${({ theme }) => theme.spacing.medium};
  width: fit-content;
`

const Input = styled.input`
  display:flex;
  justify-content: center;
  border: none;
  background-color: transparent;
  border-radius: 0;
  outline: none;
  font-size: ${({ theme }) => theme.fontSizes.xLarge};
  width: 100px;
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: calc(30%);
  }
`

interface AgeInputProps {
  onAgeChange: (age: number | undefined) => void
  placeholder: string
  initialValue?: number
  setErrorMessage: (message: string) => void

}

const AgeInput: React.FC<AgeInputProps> = ({ onAgeChange, placeholder, setErrorMessage, initialValue }) => {
  const [age, setAge] = useState(initialValue?.toString() ?? '')
  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const [allowShowError, setAllowShowError] = useState(false)

  const handleShowError = (error?: string) => {
    if (allowShowError && error) {
      setErrorMessage(error)
    }
  }

  useEffect(() => {
    // Focus on input on non-iOS devices
    // because on iOS devices the keyboard won't show up
    if (!isIos() && inputRef.current) {
      setTimeout(() => {
        // Don't focus before transition animation is finished.
        // Otherwise, it causes this bug on Android: https://bodyfastworkspace.slack.com/archives/C04J5V1PN8G/p1703756108300509?thread_ts=1703755034.066339&cid=C04J5V1PN8G
        inputRef.current?.focus()
      }, TRANSITION_ANIMATION_DURATION)
    }
  }, [])

  useEffect(() => {
    validateAgeInput(age)
  }, [allowShowError])

  const handleAgeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = removeLeadingZeros(e.target.value.trim())
    if (newValue.length === 2) setAllowShowError(true)
    if (newValue.length > 2) {
      return { error: t('age.error') ?? '' }
    }

    setAge(newValue)

    const { error, age } = validateAgeInput(newValue)

    if (error) {
      onAgeChange(undefined)
    } else if (age) {
      onAgeChange(age)
    }
  }

  const validateAgeInput = (value: string): {
    error?: string
    age?: number
  } => {
    const numericValue = parseFloat(value)
    let errorMessage = ''
    if (isNaN(numericValue) || numericValue < 18 || numericValue > 99) {
      errorMessage = t('age.error')
    }

    if (errorMessage) {
      handleShowError(errorMessage)
      return { error: errorMessage }
    }
    handleShowError('')

    if (!isNaN(numericValue)) {
      return { age: numericValue }
    } else {
      return { error: t('age.error') ?? '' }
    }
  }

  return (<InputSection>
    <InputWrapper>
      <Input
        ref={inputRef}
        type="number"
        value={age}
        maxLength={2}
        onChange={handleAgeInputChange}
        onKeyPress={(e) => {
          if (!/[0-9]/.test(e.key)) {
            e.preventDefault()
          }
        }}
        placeholder={placeholder}
        onFocus={(e) => {
          e.target.placeholder = ''
        }}
        onBlur={(e) => {
          e.target.placeholder = placeholder
          setAllowShowError(true)
        }}
      />
    </InputWrapper>
  </InputSection>
  )
}

export default AgeInput
