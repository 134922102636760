import { onboarding } from 'Containers/steps/Consts'
import { getGoalWeightDate } from 'Lib'
import { useTranslation } from 'react-i18next'
import 'react-multi-date-picker/styles/colors/teal.css'
import styled from 'styled-components'
import Logo from './Logo'
import WeightProgressionChart from './WeightProgressionChart'

const Container = styled.div`
  margin: auto;
  margin-top: ${({ theme }) => theme.spacing.xLarge};
  align-items: center;
  text-align: center;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.large};

  h1 {
    margin-top: ${({ theme }) => theme.spacing.small};
    margin-bottom: ${({ theme }) => theme.spacing.zero};
  }
`

const AdvantagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: start;
  margin-top: ${({ theme }) => theme.spacing.large};
`

const Advantage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  width: 100%;
  max-width: 400px;
  gap: ${({ theme }) => theme.spacing.small};
`
interface PlanIsReadyStepProps {
  currentWeight: IUserWeight
  goalWeight: IUserWeight
}

const PlanIsReadyStep: React.FC<PlanIsReadyStepProps> = ({ currentWeight, goalWeight }) => {
  const { t } = useTranslation(onboarding)
  const unit = currentWeight.original.unit === 'cm/kg' ? t('weight.kg') : t('weight.lb')
  const currentLabel = currentWeight.inKg.value > goalWeight.inKg.value ? `${currentWeight.original.value} ${unit}` : t('the-one-plan.currentWeightLabel')
  const goalLabel = t('the-one-plan.goal') + ' 🏆'
  const goalDate = getGoalWeightDate(goalWeight, currentWeight)
  const customDisclaimer = t('plan-is-ready.chart.disclaimer')

  return (
    <div>
      <Logo />
      <Container>
        <Wrapper>
          <WeightProgressionChart
            key={'plan-is-ready'}
            currentLabel={currentLabel} goalLabel={goalLabel} goalDate={goalDate}
            customDisclaimer={customDisclaimer}
          />
          <h1>{t('plan-is-ready.title')}</h1>
          <AdvantagesWrapper>
            <Advantage>
              <span>⏰</span>
              <span>{t('plan-is-ready.advantages.1')}</span>
            </Advantage>
            <Advantage>
              <span>🎯</span>
              <span>{t('plan-is-ready.advantages.2')}</span>
            </Advantage>
            <Advantage>
              <span>🔄</span>
              <span>{t('plan-is-ready.advantages.3')}</span>
            </Advantage>
            <Advantage>
              <span>❤️</span>
              <span>{t('plan-is-ready.advantages.4')}</span>
            </Advantage>
            <Advantage>
              <span>🗓️</span>
              <span>{t('plan-is-ready.advantages.5')}</span>
            </Advantage>
          </AdvantagesWrapper>
        </Wrapper>
      </Container>
    </div>
  )
}

export default PlanIsReadyStep
