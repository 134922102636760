// Introduction.tsx
import { useAppState } from 'AppContextProvider'
import { removeSearchParams } from 'Lib'
import { DefaultConfig } from 'Lib/Constants'
import { ActionType } from 'Reducers'
import { ONBOARDING_ROUTES_BY_STEP_ID } from 'RouteNames'
import { images } from 'images'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { useDispatch } from 'store'
import styled from 'styled-components'
import LoadingBar from './LoadingBar'
import Logo from './Logo'
import { theme } from 'styles/theme'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { getFirstStep } from 'Containers/OnboardingSteps'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: top;
`

const IntroductionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${props => props.theme.maxWidth};
  padding: 0 ${({ theme }) => theme.spacing.small};
`
const Title = styled.h1`
  color: #fff;
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: ${({ theme }) => theme.spacing.large} 0;
  min-height: 60px;
  @media (max-width: 768px) {
    margin: ${({ theme }) => theme.spacing.small} 0;
  }
`

const LoadingText = styled.p`
  color: #fff;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin: ${({ theme }) => theme.spacing.small} 0;
`

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.medium};
`

const BurgerImage = styled.img`
  @media (max-height: 760px) {
    max-width: 80%;
  }
  max-height: 175px;
  margin: ${({ theme }) => theme.spacing.large} 0;
  @media (max-width: 768px) {
    margin: ${({ theme }) => theme.spacing.medium} 0;
  }
`

const LogoWrapper = styled.div`
  position: relative;
  width: 100%;
  align-self: start;
  margin-bottom: ${({ theme }) => theme.spacing.xLarge};
  margin-left: ${({ theme }) => theme.spacing.small};
`

const PARAMS_TO_REMOVE = [
  'appState',
  'tokenId',
  'token',
  'dc',
  'previousStep',
  'lastSavedStep',
  'lastSavedHistoryLength',
  'payment_intent',
  'payment_intent_client_secret',
  'selectedPackageId',
  'subscriptionId',
  'send_event',
  'redirect_status',
  'source'
]

const Introduction: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = React.useState(true)
  const { t } = useTranslation()
  const { setAppState } = useAppState()
  const pictureConfig = useFeatureValue('loadingscreen_picture', 'fallback')
  const textConfig = useFeatureValue('loadingscreen_text', 'fallback')

  useEffect(() => {
    clearAppData()
  }, [])

  const clearAppData = () => {
    logEvent('web_clearAppDataStart')
    // do not call localStorage.clear() as it affects adminarea and other endpoints under same host

    removeSearchParams(PARAMS_TO_REMOVE)

    setAppState((prevState) => {
      const newState = { ...prevState }
      delete newState.currentStep
      delete newState.prevStep
      delete newState.steps

      return newState
    })

    logEvent('web_clearAppDataFinished')
    // logout any previous user in case of multiple consecutive purchases, so by the time the email is provided a new anonymous account is created
    dispatch({ type: ActionType.LOG_OUT_REQUEST })

    const countdownExpiration = localStorage.getItem('dscExp')
    if (!countdownExpiration || !DefaultConfig.WITH_DISCOUNT_COOLDOWN_RESET) {
      return
    }

    const countdownExpirationTimestamp = parseInt(countdownExpiration)
    const currentTime = Date.now()
    const shouldResetCooldown = currentTime - countdownExpirationTimestamp > DefaultConfig.DISCOUNT_COOLDOWN_RESET_TIMEOUT

    if (shouldResetCooldown) {
      localStorage.removeItem('dscExp')
    }
  }

  const onComplete = () => {
    setLoading(false)
    dispatch({ type: ActionType.LOADING_FINISHED })
    const firstStep = getFirstStep()
    history.push(ONBOARDING_ROUTES_BY_STEP_ID[firstStep.id])
  }

  let title

  if (textConfig === 'no1') {
    title = t('introduction.title')
  } else if (textConfig === 'newYearNewYou') {
    title = t('introduction.titleNewYearNewYou')
  } else if (textConfig === 'yourPath') {
    title = t('introduction.titleYourPath')
  } else if (textConfig === 'fallback') {
    title = ''
  } else {
    throw new Error(`Unexpected loading text config: ${textConfig}`)
  }

  let imageSrc

  if (pictureConfig === 'burger') {
    imageSrc = images.burger
  } else if (pictureConfig === 'happyNewYear') {
    imageSrc = images.happyNewYearBalloons
  } else if (pictureConfig === 'fallback') {
    imageSrc = ''
  } else {
    throw new Error(`Unexpected loading image config: ${pictureConfig}`)
  }

  return (
    <Container>
      <IntroductionWrapper>
        <LogoWrapper>
          <Logo useWhite />
        </LogoWrapper>
        <BurgerImage src={imageSrc} />
        <Title>
          {title}
        </Title>
        <LoadingWrapper>
          <LoadingText>
            {t('introduction.loading')}
          </LoadingText>
          {loading &&
            <ClipLoader
              color={theme.colors.white}
              size={20}
              speedMultiplier={1}
            />
          }
        </LoadingWrapper>
        <LoadingBar onCompleted={onComplete} backgroundColor='#0eb2946b' />
      </IntroductionWrapper>
    </Container>
  )
}

export default Introduction
