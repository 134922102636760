import { IRootState } from './RootRedux'

export const Selectors = {
  getUser: (state: IRootState) => state.login.user,
  getEmail: (state: IRootState): string | null => state.login.user?.providerData.email ?? state.login.user?.newsletterEmail ?? null,
  // We have to check whether the user is registered or not by checking if he has an email
  // because the user can be logged in with a token, and in that case, even if he doesn't have
  // an account (is anonymous), the property [anonymous] will be false
  isRegisteredUser: (state: IRootState) => !!state.login.user?.providerData?.providerId,
  isLoggedIn: (state: IRootState) => !!state.login.isLoggedIn,
  getSubscription: (state: IRootState) => state.login.user?.subscription,
  getGender: (state: IRootState) => state.login.user?.personal?.gender,
  isAppStarted: (state: IRootState) => state.startup.isAppStarted,
  isFirebaseInitialized: (state: IRootState) => state.startup.isFirebaseInitialized,
  isSentryInitialized: (state: IRootState) => state.startup.isSentryInitialized,
  isAuthInitialized: (state: IRootState) => state.startup.isAuthInitialized,
  isStripeInitialized: (state: IRootState) => state.startup.isStripeInitialized,
  getAppBlocked: (state: IRootState) => state.App.appBlocked,
  getProducts: (state: IRootState) => state.payment.products,
  getSubscriptionDetails: (state: IRootState) => state.login.subscriptionDetails,
}
