declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      primary: string
      secondary: string
      secondaryLight: string
      primaryDark: string
      lightText: string
      text: string
      lightGray: string
      lighterGray: string
      packageLightBlue: string
      packageDarkBlue: string
      packageLightGreen: string
      packageDarkGreen: string
      white: string
      transparent: string
    }
    spacing: {
      zero: string
      xsmall: string
      small: string
      medium: string
      large: string
      xLarge: string
    }
    fontSizes: {
      xsmall: string
      small: string
      medium: string
      large: string
      xLarge: string
    }
    breakpoints: {
      mobile: string
      tablet: string
      desktop: string
      largeDesktop: string
    }
    maxWidth: string
  }
}

export const theme = {
  colors: {
    primary: '#10b294',
    secondary: '#fb910e',
    secondaryLight: '#F1AE31',
    primaryDark: '#0d9077',
    text: '#787369',
    lightText: '#787369',
    lightGray: '#d6d8dd',
    lighterGray: '#f0f0f0',
    packageLightBlue: '#66C2F2',
    packageDarkBlue: '#3498DB',
    packageLightGreen: '#66D4A8',
    packageDarkGreen: '#2ECC71',
    white: '#fdfdfd',
    transparent: 'transparent'
  },
  spacing: {
    zero: '0px',
    xsmall: '5px',
    small: '10px',
    medium: '20px',
    large: '30px',
    xLarge: '40px'
  },
  fontSizes: {
    xsmall: '0.7em',
    small: '0.85em',
    medium: '1em',
    large: '1.5em',
    xLarge: '2.5em'
  },
  breakpoints: {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px',
    largeDesktop: '1200px'
  },

  maxWidth: '576px'
}
