import React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import styled from 'styled-components'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation])

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
`

const SlideImage = styled.img`
  max-width: 100%;
`
const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const NextButton = styled(Button)`
  right: 10px;
`

const PrevButton = styled(Button)`
  left: 10px;
`

const ImageSlider: React.FC<{ images: string[] }> = ({ images }) => {
  const nextRef = React.useRef(null)
  const prevRef = React.useRef(null)
  const slidesPerView = window.innerWidth <= 768 ? 1.3 : 2.5

  return (
    <>
      <SliderWrapper>
        <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={10}
          navigation={{
            nextEl: nextRef.current,
            prevEl: prevRef.current
          }}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <SlideImage src={image} alt={`Slide ${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
        <PrevButton ref={prevRef}>
          <FaChevronLeft />
        </PrevButton>
        <NextButton ref={nextRef}>
          <FaChevronRight />
        </NextButton>

      </SliderWrapper>
    </>

  )
}

export default ImageSlider
