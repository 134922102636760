import { TFunction } from 'i18next'
import { OnboardingStep } from 'types/onboardingTypes'
import { options, subtitle, title } from './Consts'

export function getBadHabits(t: TFunction): OnboardingStep {
  const badHabits = 'bad_habits'

  const sleepTooLittle = 'sleep_too_little'
  const eatProcessedFoods = 'eat_processed_foods'
  const eatChocolateAndCandy = 'eat_chocolate_and_candy'
  const drinkSoda = 'drink_soda'
  const hateWorkingOut = 'hate_working_out'
  const smoking = 'smoking'

  return {
    id: badHabits,
    type: 'multiple-select',
    allowSkip: true,
    wordings: {
      title: t(`${badHabits}.${title}`),
      subtitle: t(`${badHabits}.${subtitle}`)
    },
    options: [
      {
        id: sleepTooLittle,
        label: t(`${badHabits}.${options}.${sleepTooLittle}`),
        emoji: '🛌'
      },
      {
        id: eatProcessedFoods,
        label: t(`${badHabits}.${options}.${eatProcessedFoods}`),
        emoji: '🍔'
      },
      {
        id: eatChocolateAndCandy,
        label: t(`${badHabits}.${options}.${eatChocolateAndCandy}`),
        emoji: '🍬'
      },
      {
        id: drinkSoda,
        label: t(`${badHabits}.${options}.${drinkSoda}`),
        emoji: '🥤'
      },
      {
        id: hateWorkingOut,
        label: t(`${badHabits}.${options}.${hateWorkingOut}`),
        emoji: '👟'
      },
      {
        id: smoking,

        label: t(`${badHabits}.${options}.${smoking}`),
        emoji: '🚬'
      }
    ]
  }
}
