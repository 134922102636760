import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const UserNotFoundModal = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContent = styled.div`
  background-color: white;
  text-align: center;
  width: 80%;
  max-width: 400px;
  padding: ${props => props.theme.spacing.medium};
  position: relative;
  border-radius: 8px;
`

const CloseButton = styled.p`
  text-align: end;
  font-size: ${props => props.theme.fontSizes.large};
  margin: 0;
  cursor: pointer;
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`

const CreateAccountButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  height: ${({ theme }) => `calc(${theme.spacing.medium} * 2 + ${theme.fontSizes.medium})`};
  margin-top: ${({ theme }) => theme.spacing.medium};
  width: 100%;
  border: none;
  border-radius: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const NotNowText = styled.p`
  margin-top: ${({ theme }) => theme.spacing.medium};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.text};
`

interface CreateAccountModalProps {
  closeModal: () => void
  createAccount: () => void
}

const CreateAccountModal: React.FC<CreateAccountModalProps> = ({ closeModal, createAccount }) => {
  const { t } = useTranslation()

  return (
    <UserNotFoundModal onClick={closeModal}>
      <ModalContent>
        <CloseButton onClick={closeModal}>&times;</CloseButton>
        <Title>{t('createAccountModal.title')}</Title>
        <p>{t('createAccountModal.description')}</p>
        <CreateAccountButton onClick={createAccount}>{t('createAccountModal.createAccountButton')}</CreateAccountButton>
        <NotNowText onClick={closeModal}>{t('createAccountModal.cancelButton')}</NotNowText>
      </ModalContent>
    </UserNotFoundModal>
  )
}

export default CreateAccountModal
